<template>
    <div class="field-step">
        <el-table v-if="form.length" class="field-step-table" :data="form">
            <el-table-column align="center" prop="excelHead" label="表内字段">
            </el-table-column>
            <el-table-column align="center" prop="indicatorId" label="对应字段">
                <template scope="prop">
                    <el-select
                        v-model="prop.row.indicatorId"
                        :filterable="true"
                        clearable
                        style="width: 240px"
                        placeholder="输入搜索，不选择表示不导入"
                    >
                        <el-option
                            v-for="item in fields"
                            :key="item.indicatorId"
                            :label="item.title"
                            :value="item.indicatorId"
                        >
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="addFlg" label="加入指标同义词">
                <template scope="prop">
                    <el-checkbox v-model="prop.row.addFlg"></el-checkbox>
                </template>
            </el-table-column>
            <!-- <el-table-column align="center" prop="" label="表内数据举例">

            </el-table-column> -->
        </el-table>
        <data-default v-if="!form.length"></data-default>
        <el-row class="field-step-footer">
            <el-col>
                <el-button
                    type="primary"
                    @click="handlePrev"
                >
                    上一步
                </el-button>
                <el-button
                    type="primary"
                    @click="handleConfirm"
                >
                    下一步
                </el-button>
                <el-button @click="handleCancel">取消</el-button>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import dataDefault from './DataDefault.vue'

export default {
    name: 'FieldStep',
    components: {
        dataDefault
    },
    props: {
        formData: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            form: []
        }
    },
    computed: {
        ...mapState({
            fields: state => state.operation.fields
        })
    },
    watch: {
        formData: {
            immediate: true,
            handler (newVal) {
                console.log(newVal)
                this.form = _.cloneDeep(newVal)
            }
        }
    },
    methods: {
        handlePrev () {
            this.$confirm('此操作将删除掉您刚才填写的内容, 是否继续返回上一步?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$emit('handle-prev', 0, 'uploadStep')
            }).catch(() => {

            })
        },
        handleNext () {
            this.$emit('handle-next', 2, _.cloneDeep(this.form))
        },
        handleCancel () {
            this.$emit('handle-cancel')
        },
        handleConfirm () {
            this.handleNext()
        }
    }
}
</script>
<style lang="scss" scoped>
.field-step {
    width: 1000px;
    &-table {
        margin-top: 30px;
        ::v-deep th.is-leaf {
            background: #F7F9FC;
            font-weight: 500;
            color: #606266;
            font-size: 14px;
        }
    }
    &-footer {
        padding-top: 20px;
        .el-button {
            border-radius: 8px;
        }
    }
}
</style>
