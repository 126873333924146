<template>
    <div class="gb-category">
        <!-- todo查询 -->
        <query @handle-search="getList"></query>
        <!-- todo:列表 -->
        <list :list-data="listData" :platform-id="platformId" @handle-refresh="handleRefresh"></list>
        <!-- todo分页 -->
        <!-- v-if="total > 0" -->
        <!-- <el-pagination
            :current-page="currentPage"
            :page-sizes="[100, 200, 300, 400]"
            platform
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination> -->
    </div>
</template>
<script>
import { mapMutations } from 'vuex'
import customerSvc from 'services/customer'
import BreadSlotMixin from 'mixins/breadSlot'
import {
    list,
    query,
    breadSlot
} from './components/index'

// 插入自定义面包屑
const breadSlotMixin = new BreadSlotMixin({ breadSlot })

export default {
    name: 'Category',
    components: {
        list,
        query
    },
    mixins: [breadSlotMixin],
    beforeRouteLeave (from, to, next) {
        this.addPlatformCategory(null)
        next()
    },
    data () {
        return {
            listData: [],
            platformId: null,
            currentPage: 1,
            total: 0,
            form: {}
        }
    },
    created () {
        this.getAllSvcData()
    },
    methods: {
        async getList (query = { platformId: '', categoryName: '', parentId: null, level: null }) {
            // 获取列表
            this.platformId = query.platformId
            this.form = query
            const { data = [] } = await customerSvc.parentCategory(query)
            this.listData = data
        },
        handleRefresh () {
            this.getList(this.form)
        },
        // handleSizeChange () {
        //     this.getList()
        // },
        // handleCurrentChange () {
        //     this.getList()
        // },
        async getCategory () {
            // todo: 获取平台品类 提取成store
            const platformCategory = await customerSvc.platformCategory()
            // 子组件需要的平台品类
            const { data } = platformCategory || {}
            this.addPlatformCategory(data)
        },
        async getAllSvcData () {
            try {
                this.getList()
                this.getCategory()
            } catch (err) {
                console.log(err)
            }
        },
        ...mapMutations('customer', {
            addPlatformCategory: 'UPDATE_PLATFORM_CATEGORY'
        })
    }
}
</script>
<style lang="less" scoped>
.gb-category {
    /deep/.el-pagination {
        text-align: right;
    }
}
</style>
