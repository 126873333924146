<template>
    <div class="record-list">
        <div class="options">
            <div class="item" @click="handleEdit('add')">
                <i class="iconfont icon-benzhouxinzengfenxiaoshang" />新增
            </div>
            <!-- <div class="item" @click="handleRemove">
                <i class="iconfont icon-benzhouxinzengfenxiaoshang" />删除
            </div> -->
        </div>
        <div v-if="pageVisible" class="item-box">
            <div
                v-for="item in list"
                :key="item.id"
                class="item"
                :class="{'item-selected': item.id === selected}"
                @click="handleSelect(item.id)"
            >
                <el-row>
                    <el-col
                        :span="24"
                        class="custom-col custom-rate"
                    >
                        <el-rate
                            v-model="item.resultEvaluation"
                            :colors="colors"
                            disabled
                        >
                        </el-rate>
                    </el-col>
                    <el-col
                        :span="12"
                        :xs="24"
                        :sm="24"
                        :md="24"
                        :lg="12"

                        class="custom-col"
                    >
                        <span>编码：</span>
                        <div>{{ item.actionRecordNo || '-' }}</div>
                    </el-col>
                    <el-col
                        :span="12"
                        :xs="24"
                        :sm="24"
                        :md="24"
                        :lg="12"

                        class="custom-col"
                    >
                        <span>调整时间：</span>
                        <div>{{ item.strActionTime || '-' }}</div>
                    </el-col>
                    <el-col
                        :span="12"
                        :xs="24"
                        :sm="24"
                        :md="24"
                        :lg="12"

                        class="custom-col"
                    >
                        <span>渠道：</span>
                        <div>{{ item.channelName || '-' }}</div>
                    </el-col>
                    <el-col
                        :span="12"
                        :xs="24"
                        :sm="24"
                        :md="24"
                        :lg="12"

                        class="custom-col"
                    >
                        <span>运营动作：</span>
                        <div>{{ item.actionName || '-' }}</div>
                    </el-col>
                    <el-col
                        :span="12"
                        :xs="24"
                        :sm="24"
                        :md="24"
                        :lg="12"

                        class="custom-col"
                    >
                        <span>商品：</span>
                        <div>{{ item.spuAlias || '-' }}</div>
                    </el-col>
                    <el-col
                        :span="12"
                        :xs="24"
                        :sm="24"
                        :md="24"
                        :lg="12"

                        class="custom-col"
                    >
                        <span>动作原因：</span>
                        <div>{{ item.actionReason || '-' }}</div>
                    </el-col>
                    <el-col
                        :span="12"
                        :xs="24"
                        :sm="24"
                        :md="24"
                        :lg="12"

                        class="custom-col"
                    >
                        <span>调整方向：</span>
                        <div>{{ item.adjustmentDirection || '-' }}</div>
                    </el-col>
                    <el-col
                        :span="12"
                        :xs="24"
                        :sm="24"
                        :md="24"
                        :lg="12"

                        class="custom-col"
                    >
                        <span>结果说明：</span>
                        <div>{{ item.resultDescription || '-' }}</div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div v-else class="item-box">
            <img class="default-img" src="../../../../../assets/images/default.png" alt="数据缺省">
        </div>
        <el-pagination
            v-if="total > 0"
            class="custom-page"
            :current-page="form.current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="form.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
        <add-dialog ref="addDialog" v-on="$listeners"></add-dialog>
    </div>
</template>
<script>
import AddDialog from './addDialog'

export default {
    name: 'RecordList',
    components: {
        AddDialog
    },
    props: {
        query: {
            type: Object,
            default: () => {}
        },
        total: {
            type: Number,
            default: 0
        },
        list: {
            type: Array,
            default: () => []
        },
        selected: {
            type: String,
            default: ''
        },
        pageVisible: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            form: {
                size: 10,
                current: 1
            },
            colors: ['#99A9BF', '#F7BA2A', '#FF9900']
        }
    },
    watch: {
        query: {
            immediate: true,
            handler ({ size, current }) {
                this.form.size = size
                this.form.current = current
            }
        }
    },
    methods: {
        handleEdit (dialogType) {
            this.$refs.addDialog.handleDialog('', dialogType)
        },
        handleRemove () {},
        handleSelect (id) {
            this.$emit('handle-get-info', id)
        },
        handleSizeChange (size) {
            this.form.size = size
            this.$emit('handle-page-change', this.form)
        },
        handleCurrentChange (current) {
            this.form.current = current
            this.$emit('handle-page-change', this.form)
        }
        // async getList() {
        //     cosnt { data } = await ''
        //     this.selected = 1
        // }
    }
}
</script>
<style lang="scss" scoped>
.record-list {
    display: flex;
    flex-direction: column;
    height: 100%;

    .options {
        display: flex;
        padding: 15px 0 25px;
    }

    .options .item {
        margin-right: 19px;
        font-weight: 400;
        color: #3595e1;
        cursor: pointer;
    }

    .options .item i {
        margin-right: 9px;
        font-size: 12px;
    }

    .item-box {
        position: relative;
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        align-content: flex-start;
        overflow-y: auto;

        .default-img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 400px;
            transform: translate(-50%, -50%);
        }

        .item {
            position: relative;
            height: auto;
            min-height: 120px;
            padding: 12px 15px 3px 1px;
            margin-bottom: 10px;
            overflow: hidden;
            border: 2px solid #f0f0f0;
            border-radius: 10px;
            transition: all 0.3s;
        }

        .item-selected {
            border: 2px solid #69acf1;

            &::before {
                position: absolute;
                top: -2px;
                left: -2px;
                width: 40px;
                height: 40px;
                content: "";
                background-image: url('../../../../../assets/images/corner_mark.png');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }
        }

        .custom-col {
            display: flex;
            padding-left: 23px;
            margin-bottom: 12px;
            font-size: 14px;
            word-break: break-all;

            & > span {
                color: #909399;
            }

            & > div {
                flex: 1;
                font-weight: 500;
                color: #606266;
            }
        }

        .custom-rate {
            margin-bottom: 2px;
            text-align: right;

            ::v-deep i {
                font-size: 23px !important;
            }
        }
    }

    .custom-page {
        padding: 15px 0 10px;
        text-align: right;
    }
}
</style>
