<template>
    <div class="data-default">
        无未匹配字段，点击{{text}}
    </div>
</template>
<script>
export default {
    props: {
        text: {
            type: String,
            default: '下一步进入字段匹配'
        }
    }
}
</script>
<style lang="scss" scoped>
.data-default {
    padding: 200px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    color: #C0C4CC
}
</style>
