<template>
    <el-cascader
        class="w240"
        ref="cascaderHandle"
        v-model="id"
        :options="source"
        :props="{
            checkStrictly: true,
            value: 'id',
            label: 'sourceName',
            emitPath: false,
        }"
        clearable
        :filterable="true"
        placeholder="输入搜索，不选择表示不导入"
        @change="handleClose"
    ></el-cascader>
</template>
<script>
export default {
    name: 'cascader-hhandle',
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {},
        source: {
            type: [Object, Array]
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(newVal) {
                this.id = newVal
            }
        }
    },
    methods: {
        handleClose(val) {
            this.$emit('change', val)
            const cascader = this.$refs[`cascaderHandle`]
            console.log(this.$refs[`cascaderHandle`])
            cascader.dropDownVisible = false
            if (!val) {
                // 清空已选择项
                cascader.$refs.panel.checkedValue = [] //也可以是指定的值
                cascader.$refs.panel.calculateCheckedNodePaths()
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.w240 {
    width: 240px;
}
</style>
