<template>
    <el-popover
        v-model="dialogVisible"
        placement="bottom"
        width="360"
        @hide="handleHide"
    >
        <div style="padding: 15px 0 0; font-size: 14px; font-weight: 400; color: #df5a68; text-align: center;">注意！当前新增渠道为竞争渠道，非自销渠道</div>
        <el-form
            ref="channelForm"
            class="custom-form"
            :model="form"
            :rules="rules"
        >
            <el-form-item
                label="渠道名称"
                label-width="100px"
                prop="channelName"
            >
                <el-input
                    v-model.trim="form.channelName"
                    clearable
                    class="w240"
                ></el-input>
            </el-form-item>
            <el-form-item
                label="关联品牌"
                label-width="100px"
                prop="brandIds"
            >
                <el-select
                    v-model="form.brandIds"
                    class="w240"
                    :filterable="true"
                    clearable
                    multiple
                >
                    <el-option
                        v-for="item in productBrands"
                        :key="item.id"
                        :label="item.brandName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                label="平台店铺ID"
                label-width="100px"
                prop="platformShopNo"
            >
                <el-input
                    v-model.trim="form.platformShopNo"
                    clearable
                    class="w240"
                ></el-input>
            </el-form-item>
            <el-form-item
                label="平台"
                label-width="100px"
                prop="platformId"
            >
                <el-select
                    v-model="form.platformId"
                    class="w240"
                    :filterable="true"
                    clearable
                >
                    <el-option
                        v-for="item in platformCategory"
                        :key="item.id"
                        :label="item.platformName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div style="padding-right: 20px; padding-bottom: 10px; margin: 0; text-align: right;">
            <el-button
                class="custom-button"
                @click="handleCancel"
            >
                取消
            </el-button>
            <el-button
                class="custom-button"
                type="primary"
                @click="handleConfirm"
            >
                确定
            </el-button>
        </div>
        <span
            slot="reference"
            class="add-tip"
        ><i class="iconfont icon-benzhouxinzengfenxiaoshang"></i>新增</span>
    </el-popover>
</template>
<script>
import _ from 'lodash'
import customerSvc from 'services/customer'
import { mapState } from 'vuex'

export default {
    name: 'VerifyCategoryPopover',
    data () {
        return {
            dialogVisible: false,
            form: {
                channelNo: 0, // 渠道编码
                channelName: null, // 渠道名称
                channelType: 0, // 渠道类型
                brandIds: null, // 关联品牌
                platformShopNo: null, // 平台店铺ID
                channelCompanyId: 0, // 渠道主体
                operationCompanyId: 0, // 运营公司ID
                taxCompanyId: 0, //  开票主体公司ID
                platformId: null, // 平台id
                groupId: 0, // 管理组织
                loginName: '', // 登录名
                loginPassword: '', // 登录密码
                ownerId: 0, // 联系人id
                ownerName: '', // 联系人名称
                ownerPhone: '', // 联系人电话
                foeFlag: true
            },
            parentCategory: [],
            rules: {
                brandIds: [
                    {
                        required: false,
                        message: '请选择关联品牌',
                        trigger: 'change'
                    }
                ],
                channelName: [
                    {
                        required: true,
                        message: '请输入渠道名称',
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        max: 50,
                        message: '长度在 2 到 50 个字符，且不能为空格',
                        trigger: 'blur'
                    }
                ],
                platformShopNo: [
                    {
                        required: true,
                        message: '请输入平台店铺ID',
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        max: 50,
                        message: '长度在 2 到 50 个字符，且不能为空格',
                        trigger: 'blur'
                    }
                ],
                platformId: [
                    {
                        required: true,
                        message: '请选择平台',
                        trigger: 'change'
                    }
                ]
            }
        }
    },
    computed: {
        ...mapState({
            productBrands: state => state.customer.productBrands,
            platformCategory: state => state.customer.platformCategory
        })
    },
    methods: {
        handleVisible (boo) {
            this.dialogVisible = boo
        },
        resetForm () {
            this.$refs.channelForm.resetFields()
        },
        handleHide () {
            this.resetForm()
        },
        handleCancel () {
            this.resetForm()
            this.handleVisible(false)
        },
        async handleConfirm (step) {
            this.$refs.channelForm.validate(async valid => {
                if (valid) {
                    try {
                        const form = _.cloneDeep(this.form)
                        form.parentId = form.parentId || 0
                        form.platformId = form.platformId || 0
                        form.foeFlag = true
                        const res = await customerSvc.createCompetingChannel(this.form)
                        this.$message({
                            message: '添加成功',
                            type: 'success'
                        })
                        this.handleVisible(false)
                        this.$emit('handle-refresh', 'getChannels')
                    } catch (err) {
                        console.log('err', err)
                    }
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.custom-form {
    margin-top: 20px;

    .w240 {
        width: 240px;
    }
}

.custom-button {
    border-radius: 8px;
}
</style>
