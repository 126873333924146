<template>
    <div v-loading="loading" class="gb-chart-comments">
        <template v-if="!editable">
            <div class="gb-chart-comments-title">
                分析说明
                <i class="iconfont icon-bianji" @click="handleEditable"></i>
            </div>
            <div class="gb-chart-comments-view">
                <div class="gb-chart-comments-visible">
                    <el-checkbox v-model="visible">显示关联</el-checkbox>
                </div>
                <div class="gb-chart-comments-current" v-html="content"></div>
                <div v-if="visible && associate && associate.length" class="gb-chart-comments-relevance">
                    <div v-for="item in associate" :key="item.id" class="gb-chart-comments-relevance-item">
                        <div class="gb-chart-comments-relevance-item-date">
                            {{ item.startDay }}至{{ item.endDay }}
                        </div>
                        <div
                            class="gb-chart-comments-relevance-item-content"
                            v-html="item.comment"
                        ></div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <editor v-model="content" class="edit-area"></editor>
            <div class="btns">
                <el-button type="primary" :disabled="!this.content" @click="handleSave">确定</el-button>
                <el-button @click="handleCancel">取消</el-button>
            </div>
        </template>
    </div>
</template>
<script>
import editor from 'components/Editor'
import sellSvc from 'services/sell'

export default {
    name: 'GbChartComments',
    components: {
        editor
    },
    props: {
        form: {
            type: Object,
            default: () => {}
        },
        data: {
            type: String,
            default: ''
        },
        associate: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            content: '',
            contentClone: '',
            editable: false,
            visible: true,
            loading: false
        }
    },
    watch: {
        data: {
            immediate: true,
            handler (newVal) {
                this.content = newVal
                this.contentClone = newVal
            }
        }
    },
    methods: {
        handleEditable () {
            if (!this.form.spuId) {
                this.$message.warning('请选择商品，如未关注商品，点击右上角快捷入口进入商品页面添加关注')
                return
            }
            this.editable = !this.editable
        },
        handleCancel () {
            this.content = this.contentClone
            this.handleEditable()
        },
        async handleSave () {
            this.loading = true
            const query = {}
            ;['targetType', 'startDay', 'endDay'].forEach(item => {
                query[item] = this.form[item]
            })
            query.targetId = this.form.spuId
            query.targetType = '1'
            query.comment = this.content
            try {
                const data = await sellSvc.setComment(query)
                this.$emit('handle-refresh')
            } finally {
                this.handleEditable()
                this.loading = false
            }
        }
    }
}
</script>
<style lang="scss">
.edit-area {
    flex: 1;
    overflow: hidden;

    & > div {
        border-width: 0 !important;
        border-bottom: 1px solid #dcdfe6 !important;

        &:last-of-type {
            height: calc(100% - 121px) !important;
            max-height: calc(100% - 121px) !important;
        }
    }
}
</style>
<style lang="scss" scoped>
.gb-chart-comments {
    box-sizing: border-box;
    display: flex;
    // flex-wrap: wrap;
    flex-direction: column;
    // min-height: 720px;
    height: 100%;
    border: 2px solid #dcdfe6;

    &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 54px;
        padding: 0 13px;
        font-size: 14px;
        font-weight: 500;
        line-height: 54px;
        color: #606266;
        background: #f7f9fc;

        i {
            display: inline-block;
            font-size: 12px;
            color: #909399;
            cursor: pointer;
        }
    }

    &-view {
        box-sizing: border-box;
        padding: 24px;
        overflow-y: auto;
    }

    &-editable {
        flex: 1;
        height: 300px;
    }

    &-current {
        padding: 10px 0 20px;
        word-break: break-all;
        white-space: pre-wrap;
    }

    &-relevance {
        max-height: calc(100% - 54px);
        padding: 20px;
        background: #f7f9fc;
        border-radius: 8px;

        &-item {
            /* padding-top: 20px; */

            /* border-top: 1px solid #f0f0f0; */
            &:not(:first-of-type) {
                margin-top: 20px;
            }
        }

        &-item-date {
            margin-bottom: 10px;
            font-size: 14px;
            font-weight: 500;
            color: #909399;
        }

        &-item-content {
            max-width: 320px;
            word-break: break-all;
        }
    }

    .btns {
        padding: 12px;
        text-align: right;

        .el-button {
            border-radius: 8px;
        }
    }
}
</style>
