<template>
    <el-dialog
        class="add-dialog"
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="500px"
        :before-close="handleCancel"
        :destroy-on-close="true"
        :close-on-click-modal="false"
    >
        <el-form ref="form" :model="form" :rules="rules">
            <el-form-item
                label="数据来源"
                label-width="100px"
            >
                <el-input
                    v-model.trim="sourceItem.title"
                    style="width: 240px;;"
                    disabled
                ></el-input>
            </el-form-item>
            <el-form-item
                label="流量来源"
                label-width="100px"
                prop="sourceName"
            >
                <el-input
                    v-model.trim="form.sourceName"
                    style="width: 240px;"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item
                label="父流量来源"
                label-width="100px"
                prop="parentId"
            >
                <el-cascader
                    ref="cascaderHandle"
                    v-model="form.parentId"
                    style="width: 240px;"
                    :options="source"
                    :props="{
                        checkStrictly: true,
                        value: 'id',
                        label: 'sourceName',
                        emitPath: false,
                    }"
                    clearable
                    :filterable="true"
                    @change="handleClose"
                ></el-cascader>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleConfirm">保存</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import _ from "lodash"
import { mapState, mapMutations } from "vuex"
import sellSvc from "services/sell"

export default {
    name: 'AddDialog',
    data () {
        return {
            dialogVisible: false,
            form: {
                sourceName: null,
                parentId: '',
                dataFrom: null
            },
            isAdd: true,
            parentCategory: [],
            rules: {
                dataFrom: [
                    {
                        required: true,
                        message: '请选择数据来源',
                        trigger: 'change'
                    }
                ],
                sourceName: [
                    {
                        required: true,
                        message: '请输入流量来源名称',
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        max: 50,
                        message: '长度在 2 到 50 个字符，且不能为空格',
                        trigger: 'blur'
                    }
                ],
                parentId: [
                    {
                        required: false,
                        message: '请选择父级品类',
                        trigger: 'change'
                    }
                ]
            }
        }
    },
    computed: {
        dialogTitle () {
            return `流量来源${this.isAdd ? '新增' : '编辑'}`
        },
        ...mapState({
            sourceItem: state => state.operation.sourceItem,
            source: state => state.operation.source
        })
    },
    methods: {
        setObjLevel (total, target, max) {
            // 设置级联级数
            return target.map(item => {
                if (total === max) {
                    item.children = null
                } else {
                    item.level = total
                    if (item.children && item.children.length) {
                        item.level++
                        item.children = this.setObjLevel(
                            item.level,
                            item.children,
                            max
                        )
                    }
                }
                return item
            })
        },
        handleDialog (query = {}, isAdd) {
            // form
            this.isAdd = !!isAdd
            this.handleVisible(true)
        },
        handleVisible (boo) {
            this.dialogVisible = boo
        },
        handleCancel () {
            this.resetForm()
            this.handleVisible(false)
        },
        handleConfirm (step) {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.operationCategory(step)
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        resetForm () {
            this.$refs.form.resetFields()
        },
        async operationCategory (step) {
            // 新建or编辑
            const form = _.cloneDeep(this.form)
            console.log(this.sourceItem)
            form.dataFrom = this.sourceItem.itemValue
            const res = await sellSvc.sellVisitSourceCreate(form)
            this.$message({
                message: '添加成功',
                type: 'success'
            })
            this.handleCancel()
            this.getSource()
        },
        async getSource() {
            const { data: source } = await sellSvc.getSellVisitSourceTree({
                dataFrom: this.sourceItem.itemValue
            })
            this.updateSource(source)
        },
        handleClose (val) {
            const cascader = this.$refs.cascaderHandle
            cascader.dropDownVisible = false
            if (!val) {
                // 清空已选择项
                cascader.$refs.panel.checkedValue = [] // 也可以是指定的值
                cascader.$refs.panel.calculateCheckedNodePaths()
            }
        },
        ...mapMutations('operation', {
            updateSource: 'UPDATE_SOURCE',
        }),
    }
}
</script>
<style lang="less" scoped>
.add-dialog {
    /deep/.el-dialog__footer {
        padding: 10px;
        border-top: 2px solid #f0f0f0;
        .el-button {
            height: auto;
            padding: 10px 18px;
            font-size: 14px;
            width: auto;
            border-radius: 8px;
        }
    }
    /deep/.el-form-item__label {
        font-weight: 500;
        color: #606266;
    }
}
</style>
