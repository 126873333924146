<template>
    <div class="gb-upload">
        <!-- 步骤条 -->
        <steps-bar class="gb-upload-header" :active="active"></steps-bar>
        <transition name="fade-transform" mode="out-in">
            <components
                :is="componentsName"
                :form-data="form[componentsName]"
                @handle-next="handleNext"
                @handle-prev="handlePrev"
                @handle-cancel="handleCancel"
            ></components>
        </transition>
    </div>
</template>
<script>
import sellSvc from 'services/sell'
import components from './components/index'
import { mapMutations } from 'vuex'
const { stepsBar, fieldStep, uploadStep, verifyStep } = components

export default {
    name: 'OperationUpload',
    components: {
        stepsBar,
        fieldStep,
        uploadStep,
        verifyStep
    },
    beforeRouteLeave (from, to, next) {
        this.updateFields([])
        this.updateSource([])
        this.updateSelectedPlatformId('')
        this.updateSourceItem(null)
        this.updateChannelId(null)
        this.addPlatformCategory(null)
        this.addParentCategory(null)
        this.setProductBrands(null)
        this.setChannels(null)
        this.setChannelsCompeting(null)
        this.setChannelsNotCompeting(null)
        next()
    },
    data () {
        return {
            active: 0, // default 0
            componentsName: 'uploadStep', // default uploadStep
            form: {
                uploadStep: {
                    dictionary: null, // 数据粒度
                    categoryId: null, // 数据维度
                    dateType: null, // 日期类型
                    channelId: null, // 渠道ID
                    dataFrom: null, // 数据来源
                    dataRangeType: null, // 数据范围 test-tag null
                    dataRangeDetailType: null, // 数据细分范围 test-tag null
                    file: null
                },
                fieldStep: [],
                verifyStep: {}
            }
        }
    },
    methods: {
        handleActive (active) {
            console.log(active)
            this.active = active
        },
        handleComponentName (name) {
            this.componentsName = name
        },
        handlePrev (active, name) {
            this.handleActive(active)
            this.handleComponentName(name)
        },
        async handleNext (active, form) {
            // 下一步 or 或完成
            if (active === 1) {
                // `第一步确定
                this.form.uploadStep = form // form
                const formData = new FormData()
                Object.keys(form).forEach(item => {
                    if (item === 'file') {
                        formData.append(item, form[item].raw)
                    } else {
                        formData.append(item, form[item])
                    }
                })
                const { data: fieldForm } = await sellSvc.importDataChkhead(
                    formData
                )
                const { data: fields } = await sellSvc.getIndicatorList({
                    categoryId: form.categoryId
                })
                const { data: source } = await sellSvc.getSellVisitSourceTree({
                    dataFrom: form.dataFrom
                })
                this.updateFields(fields)
                this.updateSource(source)
                this.updateChannelId(form.channelId)
                this.form.fieldStep = fieldForm
                this.componentsName = 'fieldStep'
            } else if (active === 2) {
                // 第二步确定
                this.form.fieldStep = form
                const { data: fieldForm } = await sellSvc.iportDataChkdata(form)
                this.form.verifyStep = fieldForm
                this.componentsName = 'verifyStep'
            } else if (active === 3) {
                // 第三步确定
                this.form.verifyStep = form
                const { data: fieldForm } = await sellSvc.iportData(form)
                if (fieldForm && fieldForm.length) {
                    this.handleComplete(fieldForm)
                } else {
                    this.handleCancel()
                }
            }
            this.handleActive(active)
            // if (active !== 3) {
            //     this.handleActive(active)
            // }
        },
        handleComplete (fieldForm) {
            const h = this.$createElement
            const contentDom = [
                h('h4', {
                    style: {
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#303133',
                        margin: '-20px 0 20px'
                    }
                }, '以下数据导入错误，对应数据行未进行导入，其他数据行已导入数据，行号以标题为第一行进行计数：'),
                h('div', {
                    style: {
                        maxHeight: '350px',
                        overflowY: 'auto'
                    }
                }, fieldForm.map((item, index) => {
                    return h('el-alert', {
                        style: index === 0 ? '' : 'margin-top: 12px',
                        props: {
                            title: `第${item.rowNo}行：${item.errorInfo}`,
                            type: 'error',
                            showIcon: true,
                            closable: false,
                            effect: 'dark'
                        }
                    })
                }))
            ]
            this.$msgbox({
                title: '',
                message: h('div', null, contentDom),
                showCancelButton: false,
                showClose: false,
                closeOnPressEscape: false,
                closeOnClickModal: false,
                confirmButtonText: '点击确认'
            }).then(action => {
                this.handleCancel()
            })
        },
        handleCancel () {
            // 跳转的时候必须加上当前路由名称，以便可以取消返回
            const { prevRouteName } = this.$route.query
            if (!prevRouteName) {
                // console.log(this.$router)
                this.$router.back()
                return
            }
            this.$router.replace({
                prevRouteName
            })
        },
        ...mapMutations('operation', {
            updateFields: 'UPDATE_FIELDS',
            updateSource: 'UPDATE_SOURCE',
            updateSelectedPlatformId: 'UPDATE_SELECTED_PLATFORM_ID',
            updateSourceItem: 'UPDATE_SOURCE_ITEM',
            updateChannelId: 'UPDATE_CHANNEL_ID'
        }),
        ...mapMutations('customer', {
            addPlatformCategory: 'UPDATE_PLATFORM_CATEGORY',
            addParentCategory: 'UPDATE_PARENT_CATEGORY',
            setProductBrands: 'UPDATE_PRODUCT_BRANDS',
            setChannels: 'UPDATE_CHANNELS',
            setChannelsCompeting: 'UPDATE_CHANNELS_COMPETING',
            setChannelsNotCompeting: 'UPDATE_CHANNELS_NOT_COMPETING'
        })
    }
}
</script>
<style lang="scss" scoped>
.gb-upload {
    padding: 35px 80px 60px;

    &-header {
        width: 900px;
        margin-bottom: 40px;
    }

    ::v-deep .el-step__icon {
        width: 32px;
        height: 32px;
        font-size: 18px;
    }
}

.el-message-box__title {
    font-size: 16px;
    line-height: 20px;
    color: #333;
}
</style>
