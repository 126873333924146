<template>
    <el-popover placement="bottom" width="360" v-model="dialogVisible" @hide="handleHide">
        <el-form ref="brandForm" class="custom-form" :model="form" :rules="rules">
            <el-form-item label="品牌名称" label-width="100px" prop="brandName">
                <el-input
                    v-model.trim="form.brandName"
                    class="w240"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item
                label="英文名"
                label-width="100px"
                prop="brandAlias"
            >
                <el-input
                    v-model.trim="form.brandAlias"
                    class="w240"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item
                label="描述"
                label-width="100px"
                prop="description"
            >
                <el-input
                    v-model="form.description"
                    type="textarea"
                    class="w240"
                    clearable
                    :autosize="{ minRows: 2, maxRows: 4}"
                >
                </el-input>
            </el-form-item>
        </el-form>
        <div style="text-align: right; margin: 0;padding-right: 20px;padding-bottom: 10px">
            <el-button class="custom-button" @click="handleCancel"
                >取消</el-button
            >
            <el-button class="custom-button" type="primary" @click="handleConfirm"
                >确定</el-button
            >
        </div>
        <span class="add-tip" slot="reference"
            ><i class="iconfont icon-benzhouxinzengfenxiaoshang"></i>新增</span
        >
    </el-popover>
</template>
<script>
import _ from 'lodash'
import productSvc from "services/product"
import { mapState } from 'vuex'

export default {
    name: 'verify-brand-popover',
    data() {
        return {
            dialogVisible: false,
            form: {
                brandName: null,
                brandAlias: null,
                description: null
            },
            parentCategory: [],
            rules: {
                brandName: [
                    {
                        required: true,
                        message: "请输入品牌名称",
                        trigger: "blur"
                    },
                    {
                        min: 2,
                        max: 50,
                        message: "长度在 2 到 50 个字符，且不能为空格",
                        trigger: "blur"
                    }
                ],
                brandAlias: [
                    {
                        required: false,
                        message: "请输入品牌英文",
                        trigger: "blur"
                    },
                    {
                        min: 2,
                        max: 50,
                        message: "长度在 2 到 50 个字符，且不能为空格",
                        trigger: "blur"
                    }
                ],
                description: [
                    {
                        required: false,
                        message: "请输入描述",
                        trigger: "blur"
                    },
                    {
                        min: 2,
                        max: 400,
                        message: "长度在 2 到 400 个字符，且不能为空格",
                        trigger: "blur"
                    }
                ],
            }
        }
    },
    computed: {
        ...mapState({
            platformCategory: state => state.customer.platformCategory
        }),
    },
    methods: {
        setObjLevel(total, target, max) {
            // 设置级联级数
            return target.map(item => {
                if (total === max) {
                    item.children = null
                } else {
                    item.level = total
                    if (item.children && item.children.length) {
                        item.level++
                        item.children = this.setObjLevel(
                            item.level,
                            item.children,
                            max
                        )
                    } else {
                        item.children = null
                    }
                }
                return item
            })
        },
        handleVisible(boo) {
            this.dialogVisible = boo
        },
        resetForm() {
            this.$refs.brandForm.resetFields()
        },
        handleHide() {
            this.resetForm()
        },
        handleCancel() {
            this.resetForm()
            this.handleVisible(false)
        },
        async handleConfirm(step) {
            this.$refs.brandForm.validate(async valid => {
                if (valid) {
                    try {
                        const res = await productSvc.productBrandCreate(this.form)
                        this.$message({
                            message: '添加成功',
                            type: "success"
                        })
                        this.handleVisible(false)
                        this.$emit("handle-refresh", 'getProductBrandList')
                    } catch (err) {
                        console.log('err', err)
                    }
                } else {
                    console.log("error submit!!")
                    return false
                }
            })
        },
        handleClose(val) {
            const cascader = this.$refs.cascaderHandle
            cascader.dropDownVisible = false
            if (!val) {
                // 清空已选择项
                cascader.$refs.panel.checkedValue = [] //也可以是指定的值
                cascader.$refs.panel.calculateCheckedNodePaths()
            }
        },
        async getParent() {
            // 获取父类 todo:computed
            const { platformId } = this.form
            const { data } = await customerSvc.parentCategory({
                platformId
            })
            const result = this.setObjLevel(1, _.cloneDeep(data), 4) // 新增or编辑4级
            this.parentCategory = result
        },
        handlePlatformidChange() {
            // 根据品类设置父类
            this.getParent()
        }
    }
}
</script>
<style lang="scss" scoped>
.custom-form {
    margin-top: 20px;
    .w240 {
        width: 240px;
    }
}
.custom-button {
    border-radius: 8px;
}
</style>
