<template>
    <div
        v-loading="loading"
        class="edit-box"
        @mouseover="handleOver"
        @mouseleave="handleLeave"
    >
        <el-input
            v-model="currentVal"
            class="textarea"
            :class="{'editable-textarea': editable}"
            type="textarea"
            resize="none"
            :readonly="!editable"
        ></el-input>
        <transition name="edit-hide">
            <div
                v-if="hovered"
                class="edit-icon"
            >
                <i class="iconfont icon-jinrijihua-bianji" @click="handleEdit"></i>
            </div>
        </transition>
        <transition name="edit-show">
            <div v-if="editable" class="edit-save">
                <span @click="handleCancel">
                    <i class="iconfont icon-wenbenkuangkuaijiequxiao"></i>
                </span>
                <span @click="handleSave">
                    <i class="iconfont icon-wenbenkuangkuaijiequeren"></i>
                </span>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    props: {
        value: {
            type: String,
            default: ''
        },
        fnType: {
            type: String,
            default: ''
        },
        saveFn: {
            type: Function,
            default: function () {}
        }
    },
    data () {
        return {
            currentVal: '',
            cloneVal: '',
            editable: false,
            loading: false,
            hovered: false
        }
    },
    watch: {
        value: {
            immediate: true,
            handler (val) {
                this.currentVal = val
                this.cloneVal = val
            }
        }
    },
    methods: {
        handleOver () {
            if (this.editable) {
                return
            }
            this.hovered = true
        },
        handleLeave () {
            if (this.editable) {
                return
            }
            this.hovered = false
        },
        handleEditable (val) {
            this.editable = val
            this.hovered = false
        },
        handleEdit () {
            this.handleEditable(true)
        },
        handleCancel () {
            this.currentVal = this.cloneVal
            this.handleEditable(false)
        },
        async handleSave () {
            this.loading = true
            await this.saveFn(this.fnType, this.currentVal)
            this.cloneVal = this.currentVal
            this.handleEditable(false)
            this.loading = false
        }
    }
}
</script>
<style lang="scss" scoped>
.edit-box {
    position: relative;
    width: 100%;
    height: 100px;
    min-height: 100px;
    border: 1px solid #dcdfe6;
    border-radius: 2px;

    .textarea,
    .textarea ::v-deep textarea {
        width: 100%;
        height: 100%;
        border: 0;
    }

    .textarea ::v-deep textarea {
        height: 100% !important;
        min-height: 100% !important;
        padding-right: 15px;
    }

    .editable-textarea ::v-deep textarea {
        padding-right: 15px;
    }

    .edit-icon {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        width: 20px;
        height: 100%;
        background: rgba(240, 240, 240, 0.5);
        border-radius: 0 1px 1px 0;

        i {
            margin-top: 5px;
            font-size: 10px;
            color: #909399;
            cursor: pointer;
        }
    }

    .edit-save {
        position: absolute;
        right: -1px;
        bottom: -30px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 28px;
        background: #fff;
        border: 1px solid #dcdfe6;
        border-radius: 0 0 5px 5px;
        box-shadow: 2px 2px 4px 0 rgba(209, 209, 209, 0.5);

        & > span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            margin: 2px;
            cursor: pointer;
            background: #f0f0f0;
            border-radius: 4px;

            i {
                font-size: 8px;
                color: #909399;
            }
        }
    }

    .edit-hide-enter-active,
    .edit-hide-leave-active,
    .edit-show-enter-active,
    .edit-show-leave-active {
        transition: width 0.5s;
    }

    .edit-hide-enter-active *,
    .edit-hide-leave-active *,
    .edit-show-enter-active *,
    .edit-show-leave-active * {
        transition: transform 0.5s;
    }

    .edit-hide-enter,
    .edit-hide-leave-to,
    .edit-show-enter,
    .edit-show-leave-to {
        width: 0;
    }

    .edit-hide-enter *,
    .edit-hide-leave-to *,
    .edit-show-enter *,
    .edit-show-leave-to * {
        transform: scale(0);
    }
}
</style>
