<template>
    <div class="gb-goods-query">
        <div
            class="gb-goods-query-group"
            :class="{ 'group-unfold': foldState }"
        >
            <!-- <el-select v-model="value" class="w240 mr40" placeholder="快捷筛选">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select> -->
            <el-input
                v-model="form.channelName"
                class="w240 mr40"
                placeholder="渠道"
                clearable
                @clear="handleSearch"
                @keyup.enter.native="handleSearch"
            ></el-input>
            <el-input
                v-model="form.platformSpuNo"
                class="w240 mr40"
                placeholder="平台商品ID"
                clearable
                @clear="handleSearch"
                @keyup.enter.native="handleSearch"
            ></el-input>
            <el-cascader
                ref="cascaderHandle"
                v-model="form.categoryIds"
                class="w240 mr40"
                :options="parentCategory"
                :props="{
                    checkStrictly: true,
                    value: 'id',
                    label: 'categoryName',
                    emitPath: false,
                    multiple: true
                }"
                collapse-tags
                clearable
                :filterable="true"
                placeholder="请选择平台品类"
                @change="handleClose"
            ></el-cascader>
            <el-input
                v-model="form.spuAlias"
                class="w240 mr40"
                placeholder="商品简称"
                clearable
                @clear="handleSearch"
                @keyup.enter.native="handleSearch"
            ></el-input>
            <gb-dictionary
                v-model="form.spuPoint"
                class="w240 mr40"
                group="店铺商品定位"
                :clearable="true"
                :filterable="true"
                placeholder="请选择商品定位"
                @change="handleSearch"
            ></gb-dictionary>
            <el-row style="width: 100%;">
                <div class="textarea-group">
                    <el-select
                        v-model="textType"
                        class="w160 mr20"
                        placeholder="选择输入类型"
                        clearable
                        :filterable="true"
                        @change="handleSearch"
                        @clear="handleSearch"
                    >
                        <el-option
                            v-for="item in goodsFilterTypes"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                    <el-input
                        v-model="textareaVal"
                        type="textarea"
                        class="w340 mr40"
                        clearable
                        :autosize="{ minRows: 2, maxRows: 4}"
                        placeholder="可输入多个，多个时可以用逗号、空格或换行分开"
                    >
                    </el-input>
                </div>
            </el-row>
            <!-- <el-select
                v-model="form.spuPoint"
                class="w240 mr40"
                placeholder="请选择定位"
                clearable
                :filterable="true"
                @change="handleSearch"
                @clear="handleSearch"
            >
                <el-option
                    v-for="item in goodsFilterTypes"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
            </el-select> -->
            <!-- <el-checkbox v-model="checked">查看关注</el-checkbox> -->
        </div>
        <div class="gb-goods-query-btns">
            <span
                class="flod"
                :class="{ unfold: foldState }"
                @click="handleFold"
            >{{ foldText }}<i class="iconfont icon-xiala"></i></span>
            <el-button
                type="primary"
                round
                class="custom-btn"
                @click="handleSearch"
            >
                查询
            </el-button>
            <el-button round class="custom-btn" @click="handleReset">重置</el-button>
        </div>
    </div>
</template>
<script>
import gbDictionary from '@/components/dictionary'
import _ from 'lodash'
import { mapState } from 'vuex'
import { GOODS_FILTER_TYPES } from '../../contant'

export default {
    name: 'GoodsQuery',
    components: {
        gbDictionary
    },
    data () {
        return {
            foldState: false,
            // todo:form值
            form: {
                current: 1, // 当前页
                size: 10, // 显示数量
                enabled: null, // 启用状态
                channelName: null, // 渠道名称
                platformSpuNo: null, // 渠道类型id
                categoryId: null, // 平台商品id
                categoryIds: null, // 平台商品id
                spuAlias: null, // Spu简称模糊
                spuPoint: null, // 定位
                skuAliasList: null,
                spuAliasList: null,
                platformSkuNoList: null,
                platformSpuNoList: null,
                foeFlag: false
            },
            goodsFilterTypes: GOODS_FILTER_TYPES,
            textareaVal: '',
            textType: ''
        }
    },
    computed: {
        foldText () {
            return this.foldState ? '收起' : '展开'
        },
        ...mapState({
            parentCategory: state => state.customer.parentCategory
        })
    },
    methods: {
        handleFold () {
            this.foldState = !this.foldState
        },
        handleReset () {
            Object.keys(this.form).forEach(item => {
                this.form[item] = null
            })
            this.textareaVal = ''
            this.textType = ''
            this.form.current = 1
            this.form.size = 10
            this.form.foeFlag = false
            this.$emit('handle-search', this.form)
        },
        handleSearch () {
            // 请求接口
            const form = _.cloneDeep(this.form)
            // 格式化form
            ;[
                'skuAliasList',
                'spuAliasList',
                'platformSkuNoList',
                'platformSpuNoList'
            ].forEach(item => {
                if (item === this.textType) {
                    form[item] = this.textareaVal
                        ? this.textareaVal.split(/[(\s\r\n,，)\s\r\n,，]+/g)
                        : null
                } else {
                    form[item] = null
                }
            })
            this.$emit('handle-search', form)
        },
        handleClose (val) {
            // const cascader = this.$refs.cascaderHandle
            // cascader.dropDownVisible = false
            // if (!val) {
            //     // 清空已选择项
            //     cascader.$refs.panel.checkedValue = [] //也可以是指定的值
            //     cascader.$refs.panel.calculateCheckedNodePaths()
            // }
            this.handleSearch()
        }
    }
}
</script>
<style lang="less" scoped>
.gb-goods-query {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .w240 {
        width: 240px;
    }

    .w160 {
        width: 160px;
    }

    .w340 {
        width: 340px;
    }

    .mr40 {
        margin-right: 40px;
    }

    .mr20 {
        margin-right: 20px;
    }

    &-group {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        max-height: 52px;
        overflow: hidden;
        transition: all 0.3s;

        & > div {
            margin-bottom: 12px;
        }

        /deep/.el-checkbox {
            padding-top: 10px;
        }

        .textarea-group {
            display: flex;
        }
    }

    .group-unfold {
        max-height: 100%;
    }

    &-btns {
        min-width: 194px;

        .flod {
            display: inline-flex;
            margin-right: 8px;
            font-size: 14px;
            font-weight: 400;
            color: #3595e1;
            cursor: pointer;
        }

        .flod i {
            display: flex;
            align-items: center;
            transition: all 0.3s;
            transform: scale(0.6);
        }

        .unfold i {
            transform: scale(0.6) rotate(180deg);
        }

        .custom-btn {
            padding: 12px 18px;
            font-size: 14px;
            border: 1px solid #dcdfe6;
            border-radius: 8px;

            & + btn {
                background: #3595e1;
            }
        }
    }
}
</style>
