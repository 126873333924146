<template>
    <div class="upload-step">
        <el-form ref="fieldForm" :model="form" :rules="rules">
            <el-row>
                <el-col>
                    <el-form-item
                        label="数据粒度"
                        prop="dictionary"
                        label-width="90px"
                    >
                        <gb-radio
                            v-model="form.dictionary"
                            group="销售数据分组类别"
                            :disableds="['1', '2', '4']"
                            @change="handleDictionary"
                        ></gb-radio>
                    </el-form-item>
                </el-col>
                <el-col v-if="form.dictionary">
                    <el-form-item
                        label="数据维度"
                        prop="categoryId"
                        label-width="90px"
                    >
                        <el-radio-group
                            v-if="dictionaryList.length"
                            v-model="form.categoryId"
                        >
                            <el-radio
                                v-for="item in dictionaryList"
                                :key="item.id"
                                :label="item.id"
                                :value="item.id"
                            >
                                {{ item.title }}
                            </el-radio>
                        </el-radio-group>
                        <!-- <el-tag v-if="!dictionaryList.length" size="small"
                            >功能开发中...</el-tag
                        > -->
                    </el-form-item>
                </el-col>
                <el-col>
                    <el-form-item
                        label="时间维度"
                        prop="dateType"
                        label-width="90px"
                    >
                        <gb-radio
                            v-model="form.dateType"
                            group="销售时间类型"
                            :disableds="['0', '2', '3', '4', '5']"
                            @change="handleValidateFiled('dateType')"
                        ></gb-radio>
                    </el-form-item>
                </el-col>
                <el-col v-if="form.categoryId" class="download-template">
                    <i class="iconfont icon-daoru" /><span
                        @click="handleDownload"
                    >下载模板</span>
                </el-col>
                <el-col>
                    <el-form-item
                        label="渠道"
                        prop="channelId"
                        label-width="90px"
                    >
                        <!-- 渠道搜索框 -->
                        <el-select
                            v-model="form.channelId"
                            filterable
                            clearable
                            class="w240"
                        >
                            <el-option
                                v-for="item in channels"
                                :key="item.id"
                                class="custom-select-item"
                                :label="item.channelName"
                                :value="item.id"
                                @click.native="
                                    handleChannelOption($event, item)
                                "
                            >
                                <span>{{ item.channelName }}</span>
                                <div>
                                    <el-tag
                                        v-if="item.platformName"
                                        size="mini"
                                    >
                                        平台：{{ item.platformName }}
                                    </el-tag>
                                    <el-tag
                                        v-if="item.platformId"
                                        size="mini"
                                    >
                                        平台ID：{{ item.platformId }}
                                    </el-tag>
                                </div>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col>
                    <el-form-item
                        label="数据来源"
                        prop="dataFrom"
                        label-width="90px"
                    >
                        <!-- 数据来源 -->
                        <gb-dictionary
                            ref="xssj"
                            v-model="form.dataFrom"
                            :level="1"
                            :options-filter="optionsFilterSource"
                            :transfer="true"
                            group="销售数据来源"
                            @change="handleDataFrom"
                            @items-change="itemsChange"
                        ></gb-dictionary>
                    </el-form-item>
                </el-col>
                <el-col v-show="dataRangeTypeVisible">
                    <el-form-item
                        label="数据范围"
                        label-width="90px"
                        :rules="form.dataFrom ? rules.dataRangeType : [{required: false}]"
                    >
                        <!-- 数据范围 -->
                        <!-- <gb-dictionary
                            group="销售数据范围"
                            class="mr10"
                            v-model="form.dataRangeType"
                            @change="handleValidateFiled('dataRangeType')"
                        ></gb-dictionary> -->
                        <gb-dictionary
                            v-model="form.dataRangeType"
                            class="mr10"
                            :level="2"
                            :options-filter="list => optionsFilter(list, 1)"
                            group="销售数据来源"
                            @change="handleValidateFiled('dataRangeType')"
                        ></gb-dictionary>
                        <!-- <gb-dictionary
                            group="销售数据范围细分类型"
                            v-model="form.dataRangeDetailType"
                            @change="handleValidateFiled('dataRangeType')"
                        ></gb-dictionary> -->
                        <gb-dictionary
                            v-model="form.dataRangeDetailType"
                            class="mr10"
                            :level="2"
                            :options-filter="list => optionsFilter(list, 2)"
                            group="销售数据来源"
                            @change="handleValidateFiled('dataRangeType')"
                        ></gb-dictionary>
                    </el-form-item>
                </el-col>
                <el-col>
                    <el-form-item
                        label="导入文件"
                        prop="file"
                        label-width="90px"
                    >
                        <el-upload
                            class="upload-demo"
                            drag
                            :auto-upload="false"
                            :on-change="handleChange"
                            action=""
                            :file-list="fileList"
                            :on-remove="handleRemove"
                        >
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">
                                将文件拖到此处，或<em>点击上传</em>
                            </div>
                            <div slot="tip" class="el-upload__tip">
                                说明： <br />
                                *
                                导入模板字段顺序无需一样，导入后自动匹配对应字段名<br />称进行导入匹配，匹配后进入数据信息，刷新数据后显示<br />
                                * 支持 10MB 以内的xls、xlsx格式文件<br />
                                * 文件中数据不能超过10000行、200列
                            </div>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-row class="upload-step-footer">
            <el-col>
                <el-button
                    type="primary"
                    :disabled="!form.categoryId"
                    @click="handleConfirm"
                >
                    下一步
                </el-button>
                <el-button @click="handleCancel">取消</el-button>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import _ from 'lodash'
import qs from 'qs'
import customerSvc from 'services/customer'
import gbDictionary from '@/components/dictionary'
import gbRadio from '@/components/dictradio'
import sellSvc from 'services/sell'
import settings from 'settings'
import { downloadExcel } from '@/utils'
import axios from 'axios'

export default {
    name: 'UploadStep',
    props: {
        formData: {
            type: Object,
            default: () => {
                return {}
                // return {
                //     dictionary: null, // 数据粒度
                //     categoryId: null, // 数据维度
                //     dateType: null, // 日期类型
                //     channelId: null, // 渠道ID
                //     dataFrom: null, // 数据来源
                //     dataRangeType: 0, // 数据范围  测试加的0 test-tag null
                //     dataRangeDetailType: 0, //数据细分范围 测试加的0 test-tag null
                //     file: null
                // }
            }
        }
    },
    data () {
        return {
            channels: [],
            fileList: [],
            form: {
                dictionary: null, // 数据粒度
                categoryId: null, // 数据维度
                dateType: null, // 日期类型
                channelId: null, // 渠道ID
                dataFrom: null, // 数据来源
                dataRangeType: null, // 数据范围  测试加的0 test-tag null
                dataRangeDetailType: null, // 数据细分范围 测试加的0 test-tag null
                file: null
            },
            dictionaryList: [],
            dictionary: [],
            rules: {
                dictionary: [
                    {
                        required: true,
                        message: '请选择数据粒度',
                        trigger: 'change'
                    }
                ],
                categoryId: [
                    {
                        required: true,
                        message: '请选择数据维度',
                        trigger: 'change'
                    }
                ],
                dateType: [
                    {
                        required: true,
                        message: '请选择时间维度',
                        trigger: 'change'
                    }
                ],
                channelId: [
                    {
                        required: true,
                        message: '请选择渠道',
                        trigger: 'change'
                    }
                ],
                dataFrom: [
                    {
                        required: true,
                        message: '请选择数据来源',
                        trigger: 'change'
                    }
                ],
                dataRangeType: [
                    {
                        required: true, // test-tag true
                        message: '请选择数据范围',
                        // trigger: 'change',
                        validator: this.dataValidator
                    }
                ],
                file: [
                    {
                        required: true,
                        message: '请选择文件',
                        trigger: 'change',
                        validator: this.fileValidator
                    }
                ]
            }
        }
    },
    computed: {
        downloadUrl () {
            return (
                settings.getServer('sellServer') +
                '/sell-IndicatorData/export-template?categoryId=' +
                this.form.categoryId
            )
        },
        watchChannel () {
            return {
                selectedPlatformId: this.selectedPlatformId,
                channelId: this.form.channelId
            }
        },
        dataRangeTypeVisible () {
            if (_.isEmpty(this.dictionary)) {
                return false
            }
            const { dataFrom } = this.form
            return this.dictionary.some(
                ({ parentNo, intValue, itemGroup }) =>
                    parentNo == dataFrom &&
                    [1, 2].includes(intValue * 1) &&
                    itemGroup === '销售数据来源'
            )
        },
        ...mapState({
            selectedPlatformId: state => state.operation.selectedPlatformId
        })
    },
    watch: {
        formData: {
            immediate: true,
            handler (newVal) {
                this.form = _.cloneDeep(newVal)
                this.fileList = this.form.file ? [this.form.file] : []
            }
        },
        'form.dictionary': {
            immediate: true,
            async handler (newVal) {
                if (newVal) {
                    const { data } = await sellSvc.getCategoryList({
                        categoryType: newVal
                    })
                    this.dictionaryList = data || []
                }
            }
        },
        watchChannel: {
            immediate: true,
            deep: true,
            async handler (newVal) {
                // if (newVal.selectedPlatformId && newVal.channelId) {
                //     this.$nextTick(() => {
                //         this.$refs.xssj.refreshOptions()
                //     })
                // }
                this.$nextTick(() => {
                    this.$refs.xssj.refreshOptions()
                })
            }
        }
    },
    components: {
        gbDictionary,
        gbRadio
    },
    methods: {
        handleDownload () {
            if (!this.form.categoryId) {
                this.$message.error('请先选择数据粒度和维度')
            }
            axios({
                method: 'post',
                url:
                    settings.servers.sellServer +
                    '/sell-IndicatorData/export-template', // 请求地址
                responseType: 'blob', // 表明返回服务器返回的数据类型
                data: qs.stringify({ categoryId: this.form.categoryId }),
                headers: {
                    authToken: this.$store.getters['currentUser'].id || '',
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(response => {
                downloadExcel(response.data, '文件上传模板.xlsx')
            })
        },
        handleChannelOption (event, item) {
            // this.selectedPlatformId = item.platformId
            this.updateSelectedPlatformId(item.platformId)
            this.form.dataFrom = null
            this.form.dataRangeType = null
            this.form.dataRangeDetailType = null
            this.updateSourceItem(null)
        },
        itemsChange (val) {
            this.dictionary = val || []
        },
        optionsFilterSource (list) {
            if (!this.form.channelId || !this.selectedPlatformId) {
                return []
            }
            if (!list || list.length <= 0) {
                return list
            }
            let list1 = []
            list.forEach(item => {
                if (
                    item.longValue == this.selectedPlatformId ||
                    item.longValue == 0
                ) {
                    list1.push(item)
                }
            })
            return list1
        },
        optionsFilter (list, value) {
            if (!list || list.length <= 0) {
                return list
            }
            let list1 = []
            list.forEach(item => {
                if (item.intValue == value) {
                    list1.push(item)
                }
            })
            return list1
        },
        handleDataFrom (val) {
            this.updateSourceItem(val)
            this.handleValidateFiled('dataFrom')
            this.form.dataRangeType = null
            this.form.dataRangeDetailType = null
        },
        dataValidator (rule, value, callback) {
            if (!this.form.dataRangeType || !this.form.dataRangeDetailType) {
                callback(new Error('数据范围'))
                return
            }
            callback()
        },
        fileValidator (rule, value, callback) {
            if (!this.fileList.length) {
                callback(new Error('请选择文件'))
                return
            }
            callback()
        },
        handleDictionary () {
            this.form.categoryId = null
            this.$refs.fieldForm.validateField('dictionary')
        },
        handleValidateFiled (field) {
            this.$refs.fieldForm.validateField(field)
        },
        handleConfirm () {
            this.$refs.fieldForm.validate(valid => {
                if (valid) {
                    const form = _.cloneDeep(this.form)
                    form.file = this.fileList[0]
                    form.dataRangeType = form.dataRangeType || 0
                    form.dataRangeDetailType = form.dataRangeDetailType || 0
                    this.handleNext(form)
                } else {
                    console.log(valid)
                }
            })
        },
        handleNext (form) {
            this.$emit('handle-next', 1, form)
        },
        handleCancel () {
            this.$emit('handle-cancel')
        },
        async getChannels () {
            // 获取渠道列表
            const query = {
                foeFlag: false,
                enabled: true
            }
            const { data = [] } = await customerSvc.getChannels(query)
            this.channels = data || []
            this.form.channelId = this.channels[0] && this.channels[0].id ? this.channels[0].id : null
            this.updateSelectedPlatformId(this.channels[0] && this.channels[0].platformId ? this.channels[0].platformId : null)
        },
        handleChange (file) {
            this.fileList = []
            const fileName = file.name
            const fileType = fileName.substring(fileName.lastIndexOf('.') + 1)
            if (!['xlsx', 'xls'].includes(fileType)) {
                this.$message.error('请上传正确的文件格式！')
                return false
            }
            if (this.size && file.size > 1024 * 1024 * 10) {
                this.$message.error('上传图片大小不能超过10MB！')
                return false
            }
            this.fileList.push(file)
            file.status = 'success'
            this.handleValidateFiled('file')
        },
        // 删除文件触发的事件
        handleRemove (file, fileList) {
            this.fileList.splice(this.fileList.indexOf(file), 1)
        },
        ...mapMutations('operation', {
            updateSelectedPlatformId: 'UPDATE_SELECTED_PLATFORM_ID',
            updateSourceItem: 'UPDATE_SOURCE_ITEM'
        })
    },
    created () {
        this.getChannels()
        gbDictionary.init(this)
    }
}
</script>
<style lang="scss" scoped>
.upload-step {
    ::v-deep .el-form-item__label {
        padding-right: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #606266;
    }

    .download-template {
        padding-bottom: 30px;
        padding-left: 90px;
        font-size: 14px;
        font-weight: 400;
        color: #3595e1;

        .icon-daoru {
            margin-right: 10px;
        }

        span {
            cursor: pointer;
        }
    }

    .mr10 {
        margin-right: 10px;
    }

    .el-upload__tip {
        margin-top: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #606266;
    }

    &-footer {
        padding-left: 90px;
        margin-top: 18px;

        .el-button {
            border-radius: 8px;
        }
    }
}

.custom-select-item {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 50px;
    line-height: 50px;

    & > div,
    & > span {
        line-height: 1;
    }

    & > div {
        font-size: 12px;
        color: #b4b4b4;

        .el-tag {
            &:not(:first-of-type) {
                margin-left: 10px;
            }
            color: #b4b4b4;
        }
    }
}
</style>
