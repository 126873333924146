<template>
    <el-popover placement="bottom" width="360" v-model="dialogVisible" @hide="handleHide">
        <el-form ref="cateForm" class="custom-form" :model="form" :rules="rules">
            <el-form-item label="平台" label-width="100px" prop="platformId">
                <el-select
                    class="w240"
                    v-model="form.platformId"
                    placeholder="平台"
                    :filterable="true"
                    :popper-append-to-body="false"
                    @change="handlePlatformidChange"
                >
                    <el-option
                        v-for="item in platformCategory"
                        :key="item.id"
                        :label="item.platformName"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                label="品类"
                label-width="100px"
                prop="categoryName"
            >
                <el-input
                    v-model.trim="form.categoryName"
                    class="w240"
                    clearable
                    :disabled="!!!form.platformId"
                ></el-input>
            </el-form-item>
            <el-form-item
                label="父品类"
                label-width="100px"
                prop="parentId"
            >
                <el-cascader
                    class="w240"
                    ref="cascaderHandle"
                    v-model="form.parentId"
                    :options="parentCategory"
                    :disabled="!form.platformId"
                    :props="{
                        checkStrictly: true,
                        value: 'id',
                        label: 'categoryName',
                        emitPath: false,
                    }"
                    clearable
                    :filterable="true"
                    @change="handleClose"
                ></el-cascader>
            </el-form-item>
        </el-form>
        <div style="text-align: right; margin: 0;padding-right: 20px;padding-bottom: 10px">
            <el-button class="custom-button" @click="handleCancel"
                >取消</el-button
            >
            <el-button class="custom-button" type="primary" @click="handleConfirm"
                >确定</el-button
            >
        </div>
        <span class="add-tip" slot="reference"
            ><i class="iconfont icon-benzhouxinzengfenxiaoshang"></i>新增</span
        >
    </el-popover>
</template>
<script>
import _ from 'lodash'
import customerSvc from "services/customer"
import { mapState } from 'vuex'

export default {
    name: 'verify-category-popover',
    data() {
        return {
            dialogVisible: false,
            form: {
                platformId: null,
                categoryName: "",
                parentId: null
            },
            parentCategory: [],
            rules: {
                platformId: [
                    {
                        required: true,
                        message: "请选择平台品类",
                        trigger: "change"
                    }
                ],
                categoryName: [
                    {
                        required: true,
                        message: "请输入品类名称",
                        trigger: "blur"
                    },
                    {
                        min: 2,
                        max: 50,
                        message: "长度在 2 到 50 个字符，且不能为空格",
                        trigger: "blur"
                    }
                ],
                parentId: [
                    {
                        required: false,
                        message: "请选择父级品类",
                        trigger: "change"
                    }
                ]
            }
        }
    },
    computed: {
        ...mapState({
            platformCategory: state => state.customer.platformCategory
        }),
    },
    methods: {
        setObjLevel(total, target, max) {
            // 设置级联级数
            return target.map(item => {
                if (total === max) {
                    item.children = null
                } else {
                    item.level = total
                    if (item.children && item.children.length) {
                        item.level++
                        item.children = this.setObjLevel(
                            item.level,
                            item.children,
                            max
                        )
                    } else {
                        item.children = null
                    }
                }
                return item
            })
        },
        handleVisible(boo) {
            this.dialogVisible = boo
        },
        resetForm() {
            this.$refs.cateForm.resetFields()
        },
        handleHide() {
            this.resetForm()
        },
        handleCancel() {
            this.resetForm()
            this.handleVisible(false)
        },
        async handleConfirm(step) {
            this.$refs.cateForm.validate(async valid => {
                if (valid) {
                    try {
                        this.form.parentId = this.form.parentId || 0
                        const res = await customerSvc.createCategory(this.form)
                        this.$message({
                            message: '添加成功',
                            type: "success"
                        })
                        this.handleVisible(false)
                        this.$emit("handle-refresh", 'getCategoryList')
                    } catch (err) {
                        console.log('err', err)
                    }
                } else {
                    console.log("error submit!!")
                    return false
                }
            })
        },
        handleClose(val) {
            const cascader = this.$refs.cascaderHandle
            cascader.dropDownVisible = false
            if (!val) {
                // 清空已选择项
                cascader.$refs.panel.checkedValue = [] //也可以是指定的值
                cascader.$refs.panel.calculateCheckedNodePaths()
            }
        },
        async getParent() {
            // 获取父类 todo:computed
            const { platformId } = this.form
            const { data } = await customerSvc.parentCategory({
                platformId
            })
            const result = this.setObjLevel(1, _.cloneDeep(data), 4) // 新增or编辑4级
            this.parentCategory = result
        },
        handlePlatformidChange() {
            // 根据品类设置父类
            this.getParent()
        }
    }
}
</script>
<style lang="scss" scoped>
.custom-form {
    margin-top: 20px;
    .w240 {
        width: 240px;
    }
}
.custom-button {
    border-radius: 8px;
}
</style>
