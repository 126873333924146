<template>
    <el-radio-group v-model="currentItem"
        v-bind="$attrs"
        value-key="itemValue"
        @change="selectChanged"
        v-if="!this.hiddenEmpty || (this.options && this.options.length)">
        <el-radio
            v-for="item in options"
            :key="item.itemValue"
            :label="item.itemValue"
            :value="item.itemValue">{{item.title}}</el-radio>
    </el-radio-group>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'

const init = function(vue, groups) {
    if (vue.__gbDictionary) {
        // 已经初始化过了
        return
    }
    if (typeof groups !== 'Array') {
        groups = []
    }
    let instance = (vue.__gbDictionary = {
        groups, // 用于保存有多少个字典组
        items: [], // 用于保存所有的字典项,
        groupItems: {} // 用于保存每个分组下面的子项
    })
    // 监听当前页面的mounted事件
    vue.$on('hook:mounted', function() {
        let groups = instance.groups
        if (groups && groups.length) {
            axios
                .fetch('commonServer', '/dict/getItemViewsByGroup', {
                    groups: groups.join(',')
                })
                .then(res => {
                    let list = res.data
                    instance.items = list
                    vue.$emit('gb-dictionary-change-items', list)
                    // 分组
                    let gi = instance.groupItems
                    list.forEach(element => {
                        let items = gi[element.itemGroup]
                        if (!items) items = gi[element.itemGroup] = []
                        items.push(element)
                    })
                })
        }
    })
}

const filter = function(value, vue, group) {
    if (value === undefined || value === null || !vue || !group) {
        return value
    }
    if (!vue.__gbDictionary || !vue.__gbDictionary.groupItems) {
        return value
    }
    let items = vue.__gbDictionary.groupItems[group]
    if (!items || !items.length) {
        return value
    }
    for (let i = 0; i < items.length; i++) {
        const item = items[i]
        if (item.itemValue == value) {
            return item.title
        }
    }
    return value
}

export default {
    name: 'gbDictionaryRadio',
    init,
    filter,
    model: {
        prop: 'checked',
        event: 'change'
    },
    props: {
        checked: [String, Number],
        dictBus: Vue, // 页面级通讯组件
        category: String, // 数据字典实例组名，用于同一页面有多个同类字典的情形
        group: {
            // 数据字典类别名称，比如“民族”
            type: String,
            required: true
        },
        level: {
            // 级联下拉框的层次，第一层为1
            type: Number,
            default: 1
        },
        hiddenEmpty: Boolean, // 如果没有下来选项，是否需要隐藏
        empty: Boolean
    },
    data() {
        return {
            currentItem: null,
            options: [],
            parentLevel: 0,
            selectData: {},
            value0: undefined, // 表单首次绑定值
            pageBus: null, // 页面级通讯组件
            selectBus: null // 当前组通讯组件（级联）
        }
    },
    watch: {
        value(newVal, oldVal) {
            this.valueChanged(newVal)
        }
    },
    created() {
        this.pageBus = this.dictBus
        if (!this.pageBus) {
            //未提供通讯组件，则向上查找
            let parent = this.$parent
            while (parent) {
                if (parent.__gbDictionary) {
                    break
                }
                parent = parent.$parent
            }
            this.pageBus = parent
        }
        if (this.pageBus && this.category) {
            let bus = this.pageBus.__gbDictionary[this.category]
            if (!bus) {
                bus = this.pageBus.__gbDictionary[this.category] = new Vue()
            }
            this.selectBus = bus
        } else {
            this.selectBus = this.pageBus
        }
        if (this.pageBus && this.pageBus.__gbDictionary && this.level == 1) {
            this.pageBus.__gbDictionary.groups.push(this.group)
        }

        if (this.level > 1) {
            // 联动下拉框
            this.parentLevel = this.level - 1
            if (this.selectBus instanceof Vue) {
                // 侦听上级变化的事件
                this.selectBus.$on(
                    'gb-dictionary-change-' +
                        this.group +
                        '-' +
                        this.parentLevel,
                    this.parentChanged
                )
            }
            return
        }
        // 侦听总数据的变化
        if (this.pageBus instanceof Vue && this.pageBus.__gbDictionary) {
            this.pageBus.$on('gb-dictionary-change-items', this.itemsChange)
            return
        }
        // 根级
        axios
            .fetch('commonServer', '/dict/getItemViewsByGroup', {
                groups: this.group
            })
            .then(res => {
                let data = (this.selectData.items = res.data)
                this.itemsInit(data)
            })
    },
    beforeDestroy() {
        if (this.parentLevel && this.selectBus instanceof Vue) {
            this.selectBus.$off(
                'gb-dictionary-change-' + this.group + '-' + this.parentLevel,
                this.parentChanged
            )
        }
        if (this.pageBus instanceof Vue && this.pageBus.__gbDictionary) {
            this.pageBus.$off('gb-dictionary-change-items', this.itemsChange)
        }
    },
    methods: {
        findItem(value) {
            let item = this.options.find(item => {
                return item.itemValue == value
            })
            return item
        },
        parentChanged(data) {
            // 父级编号已变化
            this.selectData = data
            if (
                data &&
                data.items &&
                data.items.length &&
                data['value' + this.parentLevel]
            ) {
                let parentNo = data['value' + this.parentLevel]
                let items = data.items
                let list = []
                items.forEach(element => {
                    if (
                        element.itemGroup == this.group &&
                        element.parentNo == parentNo &&
                        element.itemLevel == this.level
                    ) {
                        list.push(element)
                    }
                })
                this.options = list
            } else {
                this.options = []
            }
            this.optionsChanged()
        },
        optionsChanged() {
            // 下拉选项已发生变化
            let value = this.value
            let item
            if (value) {
                item = this.findItem(value)
            } else {
                item = null
            }
            if (this.currentItem != item) {
                this.currentItem = item
                this.currentItemChanged()
            }
            // 发出通知选项已变空
            let isEmpty = !this.options || !this.options.length
            this.$emit('update:empty', isEmpty)
            this.$emit('optionsChanged', this.options)
        },
        valueChanged(newVal) {
            // 绑定值已发生变化（的回调！）
            let item = this.currentItem
            if (newVal === undefined || newVal === null) {
                item = null
            } else if (
                this.currentItem == null ||
                this.currentItem != newVal
            ) {
                item = this.findItem(newVal)
            }
            if (this.currentItem != item) {
                this.currentItem = item
                this.currentItemChanged(true)
            }
        },
        currentItemChanged(isValue) {
            // 当前项已发生变化
            let item = this.currentItem
            let itemValue = item || ''

            // 通知使用者值已经改变
            if (!isValue) {
                this.$emit('change', item)
            }

            // 发出变更通知
            if (this.selectData) {
                this.selectData['value' + this.level] = itemValue
            }
            if (this.selectBus instanceof Vue) {
                this.selectBus.$emit(
                    'gb-dictionary-change-' + this.group + '-' + this.level,
                    this.selectData
                )
            }
        },
        selectChanged() {
            //下拉框选中时触发
            this.currentItemChanged()
        },
        itemsInit(data) {
            // 从服务器获取数据后的处理
            let list = []
            if (data) {
                data.forEach(element => {
                    if (element.parentNo == this.group) {
                        list.push(element)
                    }
                })
            }
            this.options = list
            this.optionsChanged()
        },
        itemsChange(items) {
            // 从总数据里获得数据后的处理
            if (!items || items.length <= 0) {
                return
            }
            let list = []
            items.forEach(element => {
                if (element.itemGroup == this.group) {
                    list.push(element)
                }
            })
            this.selectData.items = list
            this.itemsInit(list)
        }
    }
}
</script>
