<template>
    <div class="operation-action-library">
        <query :query="query" @handle-search="handleCallSearch" @handle-reset="handleReset"></query>
        <list v-loading="loading" :list="list" @handle-refresh="handleRefresh"></list>
        <el-pagination
            v-if="total > 0"
            class="custom-page"
            :current-page="query.current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="query.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
    </div>
</template>
<script>
import _ from 'lodash'
import sellSvc from 'services/sell'
import components from './components/index.js'

export default {
    name: 'OperationActionLibrary',
    components: {
        ...components
    },
    beforeRouteLeave (from, to, next) {
        next()
    },
    provide () {
        return {
            transfer: this.reactive
        }
    },
    data () {
        return {
            query: {
                current: 1, // 当前页码
                size: 10, // 每页数量
                actionIndicatorId: '', // 指标id
                actionName: '', // 运营动作名称
                actionSubject: '', // 运营主题
                minMonitorInterval: '', // 最小监控天数
                maxMonitorInterval: '' // 最大监控天数
            },
            list: [],
            total: 0,
            reactive: {},
            loading: false
        }
    },
    created () {
        this.getAllData()
    },
    methods: {
        handleRefresh () {
            this.handleSearch()
        },
        handleSizeChange (size) {
            this.query.size = size
            this.handleSearch()
        },
        handleCurrentChange (current) {
            this.query.current = current
            this.handleSearch()
        },
        handleReset (query) {
            // 重置
            this.query = _.cloneDeep(query)
            this.handleSearch()
        },
        handleCallSearch (query) {
            console.log('1')
            this.query = _.cloneDeep(query)
            this.query.current = 1
            this.handleSearch()
        },
        async handleSearch () {
            console.log('2')
            // 查询
            this.loading = true
            const { data, total } = await sellSvc.getActions(this.query)
            this.list = data
            this.total = total
            this.loading = false
        },
        async getCategory () {
            // 数据分组
            const platformCategory = await sellSvc.getCategoryList({ categoryType: 0 })
            // 子组件需要的平台品类
            const { data } = platformCategory || {}
            this.reactive.category = data
        },
        async getValueAllIndicator () {
            // 指标库
            const indicator = await sellSvc.getValueAllIndicator()
            const { data } = indicator || {}
            this.reactive.allIndicator = data
        },
        getAllData () {
            try {
                this.getCategory()
                this.getValueAllIndicator()
                this.handleSearch()
            } catch (e) {
                this.loading = false
                console.log(e)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.operation-action-library {
    ::v-deep.el-pagination {
        text-align: right;
    }

    .custom-page {
        margin: 30px 0 10px;
    }
}
</style>
