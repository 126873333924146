<template>
    <el-dialog
        class="add-dialog"
        width="800px"
        :visible.sync="dialogVisible"
        :title="dialogTitle"
        :before-close="handleCancel"
        :destroy-on-close="true"
        :close-on-click-modal="false"
    >
        <el-form ref="form" :model="form" :rules="rules">
            <el-row>
                <el-col :span="12">
                    <el-form-item
                        v-if="!isAdd"
                        label="编码"
                        label-width="120px"
                        class="weight500"
                        prop="channelNo"
                    >
                        <el-input
                            v-model.trim="form.channelNo"
                            clearable
                            class="w240"
                            disabled
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="渠道名称"
                        label-width="120px"
                        class="weight500"
                        prop="channelName"
                    >
                        <el-input
                            v-model.trim="form.channelName"
                            clearable
                            class="w240"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="下游用户类型"
                        label-width="120px"
                        class="weight500"
                        prop="channelType"
                    >
                        <gb-dictionary
                            ref="xssj"
                            v-model="form.channelType"
                            class="w240"
                            :level="1"
                            group="交易渠道下游用户类型"
                            :clearable="true"
                            :filterable="true"
                            placeholder="下游用户类型"
                        >
                        </gb-dictionary>
                    </el-form-item>
                </el-col>
                <!-- todo 管理品牌 -->
                <el-col :span="12">
                    <el-form-item
                        label="关联品牌"
                        label-width="120px"
                        class="weight500"
                        prop="brandIds"
                    >
                        <!-- 多选 -->
                        <el-select
                            v-model="form.brandIds"
                            class="w240"
                            :filterable="true"
                            clearable
                            multiple
                        >
                            <el-option
                                v-for="item in productBrands"
                                :key="item.id"
                                :label="item.brandName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="平台店铺ID"
                        label-width="120px"
                        class="weight500"
                        prop="platformShopNo"
                    >
                        <el-input
                            v-model.trim="form.platformShopNo"
                            clearable
                            class="w240"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="渠道主体"
                        label-width="120px"
                        class="weight500"
                        prop="channelCompanyId"
                    >
                        <el-select
                            v-model="form.channelCompanyId"
                            class="w240"
                            :filterable="true"
                            clearable
                            remote
                            reserve-keyword
                            :remote-method="
                                keyword => {
                                    querySearchPartner(keyword)
                                }
                            "
                            :loading="pLoading"
                            @change="handleCompanyChange"
                        >
                            <el-option
                                v-for="item in partnerList"
                                :key="item.id"
                                :label="item.partnerName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="运营主体"
                        label-width="120px"
                        class="weight500"
                        prop="operationCompanyId"
                    >
                        <el-select
                            v-model="form.operationCompanyId"
                            class="w240"
                            :filterable="true"
                            remote
                            reserve-keyword
                            :remote-method="
                                keyword => {
                                    querySearchPartner(keyword, 2)
                                }
                            "
                            :loading="pLoading2"
                            clearable
                        >
                            <el-option
                                v-for="item in partnerList2"
                                :key="item.id"
                                :label="item.partnerName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="开票主体"
                        label-width="120px"
                        class="weight500"
                        prop="taxCompanyId"
                    >
                        <el-select
                            v-model="form.taxCompanyId"
                            class="w240"
                            :filterable="true"
                            clearable
                            remote
                            reserve-keyword
                            :remote-method="
                                keyword => {
                                    querySearchPartner(keyword, 3)
                                }
                            "
                            :loading="pLoading3"
                        >
                            <el-option
                                v-for="item in partnerList3"
                                :key="item.id"
                                :label="item.partnerName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="平台"
                        label-width="120px"
                        class="weight500"
                        prop="platformId"
                    >
                        <el-select
                            v-model="form.platformId"
                            class="w240"
                            :filterable="true"
                            clearable
                        >
                            <el-option
                                v-for="item in platformCategory"
                                :key="item.id"
                                :label="item.platformName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="管理组织"
                        label-width="120px"
                        class="weight500"
                        prop="groupId"
                    >
                        <el-cascader
                            ref="cascaderHandle"
                            v-model="form.groupId"
                            class="w240"
                            :options="groupTree"
                            :props="{
                                checkStrictly: true,
                                value: 'id',
                                label: 'groupName',
                                emitPath: false
                            }"
                            clearable
                            :filterable="true"
                            placeholder="请选择组织"
                            @change="handleClose"
                        ></el-cascader>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="登录名"
                        label-width="120px"
                        class="weight500"
                        prop="loginName"
                    >
                        <el-input
                            v-model.trim="form.loginName"
                            clearable
                            class="w240"
                            maxlength="25"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="登录密码"
                        label-width="120px"
                        class="weight500"
                        prop="loginPassword"
                    >
                        <el-input
                            v-model.trim="form.loginPassword"
                            clearable
                            class="w240"
                            maxlength="25"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <!-- Todo: 管理员 -->
                <el-col :span="12">
                    <el-form-item
                        label="管理员"
                        label-width="120px"
                        class="weight500"
                        prop="ownerId"
                    >
                        <el-select
                            v-model="form.ownerId"
                            class="w240"
                            :filterable="true"
                            clearable
                        >
                            <el-option
                                v-for="item in emlpoyeesList"
                                :key="item.employeeId"
                                :label="item.employeeName"
                                :value="item.employeeId"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleConfirm()">保存</el-button>
            <el-button
                v-if="isAdd"
                type="primary"
                @click="handleConfirm('continue')"
            >
                保存并维护竞争渠道
            </el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import customerSvc from 'services/customer'
import { getPartnerInfoPage } from 'services/distribution'
import utils from 'utils'
import gbDictionary from '@/components/dictionary'

export default {
    name: 'AddDialog',
    components: {
        gbDictionary
    },
    data () {
        return {
            dialogVisible: false,
            form: {
                channelNo: null, // 渠道编码
                channelName: null, // 渠道名称
                channelType: null, // 渠道类型
                brandIds: null, // 关联品牌
                platformShopNo: null, // 平台店铺ID
                channelCompanyId: null, // 渠道主体
                operationCompanyId: null, // 运营公司ID
                taxCompanyId: null, //  开票主体公司ID
                platformId: null, // 平台id
                groupId: null, // 管理组织
                loginName: null, // 登录名
                loginPassword: null, // 登录密码
                ownerId: null, // 联系人id
                ownerName: '', // 联系人名称
                ownerPhone: '', // 联系人电话
                foeFlag: false
            },
            isAdd: true,
            keyword: '',
            rules: {
                channelNo: [
                    {
                        required: true,
                        message: '请输入编码',
                        trigger: 'blur'
                    },
                    {
                        min: 1,
                        max: 50,
                        message: '长度在 1 到 50 个字符，且不能为空格',
                        trigger: 'blur'
                    }
                ],
                channelName: [
                    {
                        required: true,
                        message: '请输入渠道名称',
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        max: 50,
                        message: '长度在 2 到 50 个字符，且不能为空格',
                        trigger: 'blur'
                    }
                ],
                channelType: [
                    {
                        required: true,
                        message: '请选择用户类型',
                        trigger: 'change'
                    }
                ],
                platformId: [
                    {
                        required: true,
                        message: '请选择平台',
                        trigger: 'change'
                    }
                ],
                platformShopNo: [
                    {
                        required: false,
                        message: '请输入平台店铺ID',
                        trigger: 'blur'
                    },
                    {
                        min: 1,
                        max: 50,
                        message: '长度在 1 到 50 个字符，且不能为空格',
                        trigger: 'blur'
                    }
                ],
                channelCompanyId: [
                    {
                        required: true,
                        message: '请选择渠道主体',
                        trigger: 'change'
                    }
                ],
                operationCompanyId: [
                    {
                        required: true,
                        message: '请选择运营主体',
                        trigger: 'change'
                    }
                ],
                taxCompanyId: [
                    {
                        required: true,
                        message: '请选择开票主体',
                        trigger: 'change'
                    }
                ],
                groupId: [
                    {
                        required: true,
                        message: '请选择管理组织',
                        trigger: 'change'
                    }
                ]
            },
            pLoading: false,
            partnerList: [],
            pLoading2: false,
            partnerList2: [],
            pLoading3: false,
            partnerList3: []
        }
    },
    computed: {
        dialogTitle () {
            return `${this.isAdd ? '新增' : '编辑'}渠道`
        },
        ...mapState({
            gropus: state => state.customer.allDeps,
            platformCategory: state => state.customer.platformCategory,
            productBrands: state => state.customer.productBrands,
            emlpoyeesList: state => state.customer.emlpoyeesList
        }),
        groupTree () {
            if (_.isEmpty(this.gropus)) {
                return []
            }
            return utils.makeDataToTree(
                this.gropus,
                'parentId',
                'id',
                'groupName'
            )
        }
    },
    methods: {
        async handleDialog (query = {}, isAdd) {
            this.isAdd = !!isAdd
            const target = _.cloneDeep(query)
            Object.keys(query).forEach(item => {
                this.form[item] = target[item]
            })
            this.handleVisible(true)
            if (!isAdd) {
                const query = {
                    current: 1,
                    size: 30
                }
                // todol临时加的功能,写法不好之后优化
                if (![null, undefined].includes(this.form.channelCompanyId)) {
                    const { data } = await getPartnerInfoPage({
                        ...query,
                        id: this.form.channelCompanyId
                    })
                    this.partnerList = data || []
                }
                if (![null, undefined].includes(this.form.operationCompanyId)) {
                    const { data: data2 } = await getPartnerInfoPage({
                        ...query,
                        id: this.form.operationCompanyId
                    })
                    this.partnerList2 = data2 || []
                }
                if (![null, undefined].includes(this.form.taxCompanyId)) {
                    const { data: data3 } = await getPartnerInfoPage({
                        ...query,
                        id: this.form.taxCompanyId
                    })
                    this.partnerList3 = data3 || []
                }
            }
        },
        handleVisible (boo) {
            this.dialogVisible = boo
            if (boo) {
                this.$nextTick(() => {
                    this.clearValidate()
                })
            }
        },
        handleCancel () {
            // Object.keys(this.form).forEach(item => {
            //     this.form[item] = null
            // })
            this.resetForm()
            this.handleVisible(false)
            // this.$nextTick(() => {
            //     this.handleVisible(false)
            // })
        },
        clearValidate () {
            this.$refs.form.clearValidate()
        },
        resetForm () {
            this.$refs.form.resetFields()
        },
        handleConfirm (step) {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.operationCategory(step)
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // handleRemove() {
        //     // 删除添加项
        // },
        // querySearchAsync(queryString, cb) {
        //     // 远程搜索
        // },
        // handleSelect(item) {
        //     // 选择完后添加
        //     this.list.push(item)
        // },
        handleCompanyChange () {
            ;['operationCompanyId', 'taxCompanyId'].forEach((item, index) => {
                this.form[item] = this.form.channelCompanyId
                this[`partnerList${index + 2}`] = this.partnerList
            })
        },
        async operationCategory (step) {
            // 新建or编辑
            const reqMethod = this.isAdd ? 'create' : 'update'
            // const loading = this.$loading()
            try {
                const form = _.cloneDeep(this.form)
                form.parentId = form.parentId || 0
                const { employeeName } =
                    this.emlpoyeesList.find(
                        item => item.employeeId === form.ownerId
                    ) || {}
                form.ownerName = employeeName
                form.foeFlag = false
                const { data } = await customerSvc[`${reqMethod}Channel`](form)
                this.$message({
                    message: `保存成功${
                        step === 'continue' ? ', 继续添加竞品' : ''
                    }`,
                    type: 'success'
                })
                this.$emit('handle-refresh', step, data)
                this.handleCancel()
                // loading.close()
            } catch (err) {
                console.log('err', err)
                // loading.close()
            }
        },
        handleClose (val) {
            this.$emit('change', val)
            const cascader = this.$refs['cascaderHandle']
            cascader.dropDownVisible = false
            if (!val) {
                // 清空已选择项
                cascader.$refs.panel.checkedValue = [] // 也可以是指定的值
                cascader.$refs.panel.calculateCheckedNodePaths()
            }
        },
        async querySearchPartner (queryString, type = '') {
            // 快速增加sku
            if (!queryString) {
                return
            }
            this[`pLoading${type}`] = true
            // 远程搜索
            const query = {
                partnerNameLike: queryString, // 渠道名称
                current: 1,
                size: 30
            }
            const { data = [], total = 0 } = await getPartnerInfoPage(query)
            this[`pLoading${type}`] = false
            this[`partnerList${type}`] = data
        }
    }
}
</script>
<style lang="less" scoped>
.add-dialog {
    .w240 {
        width: 240px;
    }

    .weight500 {
        font-weight: 500;
    }

    /deep/.el-dialog__body {
        max-height: 600px;
        padding: 33px 0 21px;
        overflow-y: auto;
    }

    /deep/.el-dialog__footer {
        padding: 10px;
        border-top: 2px solid #f0f0f0;

        .el-button {
            width: auto;
            height: auto;
            padding: 10px 18px;
            font-size: 14px;
            border-radius: 8px;
        }
    }

    .search-list {
        padding: 0 37px;

        .search {
            margin-bottom: 41px;

            h3 {
                margin-bottom: 20px;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                color: #909399;
            }

            .search-input {
                width: 480px;
            }
        }

        h4 {
            display: flex;
            justify-content: space-between;
            margin-bottom: 11px;

            span {
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                color: #606266;
            }
        }

        .item-desc {
            margin-bottom: 20px;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            color: #606266;
        }

        .custom-item {
            margin-bottom: 0;
        }
    }
}
</style>
