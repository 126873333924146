const vueFiles = require.context('./', false, /[a-z]+\.vue$/, 'lazy') // lazy是否懒加载 第四个参数不填默认同步
console.log('vueFiles.keys()', vueFiles.keys())
const target = {}
vueFiles.keys().forEach(item => {
    const fileName = item.split('/').pop().replace(/\.\w+$/, '')
    const config = vueFiles(item)
    // target[fileName] = config.default || config
    target[fileName] = () => config.default || config // 异步加载
})

export default target
