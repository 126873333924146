<template>
    <el-dialog
        width="800px"
        :visible.sync="dialogVisible"
        class="competing-dialog"
        :title="dialogTitle"
        :before-close="handleCancel"
        :destroy-on-close="true"
        :close-on-click-modal="false"
    >
        <div class="search">
            <div class="title">
                当前维护渠道：{{ parentChannel }}
            </div>
            <!-- <el-autocomplete
                v-model="keyword"
                class="w240 search-input"
                :fetch-suggestions="querySearchAsync"
                @select="handleSelect"
                placeholder="基于竞争店铺快速增加"
                value-key="channelName"
            >
                <i
                    slot="suffix"
                    class="el-input__icon el-icon-search"
                ></i>
            </el-autocomplete> -->
            <el-select
                v-model="keyword"
                filterable
                remote
                reserve-keyword
                placeholder="基于竞争渠道快速增加"
                :remote-method="querySearchAsync"
                :loading="loading"
            >
                <el-option
                    v-for="item in searchList"
                    :key="item.id"
                    class="custom-select-item"
                    :label="item.channelName"
                    :value="item.id"
                    @click.native="handleOption($event, item)"
                >
                    <span>{{ item.channelName }}</span>
                    <div>
                        <el-tag
                            size="mini"
                        >
                            平台店铺Id：{{ item.platformShopNo }}
                        </el-tag>
                        <el-tag
                            size="mini"
                        >
                            平台：{{ item.platformName }}
                        </el-tag>
                    </div>
                </el-option>
            </el-select>
        </div>
        <el-form ref="form" :model="form">
            <div
                v-for="(item, i) in form.sellChannelRelationList"
                :key="item.id"
                class="list"
            >
                <h4>
                    <span>渠道{{ i + 1 }}</span>
                    <el-button type="text" @click="handleRemove(i, item)">
                        删除
                    </el-button>
                </h4>
                <el-row>
                    <el-col :span="12">
                        <el-form-item
                            class="custom-item weight500"
                            label-width="100px"
                            label="渠道名称"
                            :prop="`sellChannelRelationList[${i}].relationName`"
                            :rules="rules.relationName"
                        >
                            <!-- 文本 -->
                            <el-input
                                v-model.trim="item.relationName"
                                :disabled="!!item.channelId"
                                class="w240"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            class="custom-item weight500"
                            label-width="100px"
                            label="平台"
                            :prop="`sellChannelRelationList[${i}].platformId`"
                            :rules="rules.platformId"
                        >
                            <el-select
                                v-model="item.platformId"
                                class="w240"
                                placeholder="平台"
                                :filterable="true"
                                clearable
                                :disabled="!!item.channelId"
                            >
                                <el-option
                                    v-for="item in platformCategory"
                                    :key="item.id"
                                    :label="item.platformName"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            class="custom-item weight500"
                            label-width="100px"
                            label="平台店铺ID"
                            :prop="`sellChannelRelationList[${i}].platformShopNo`"
                            :rules="rules.platformShopNo"
                        >
                            <!-- 文本 -->
                            <el-input
                                v-model="item.platformShopNo"
                                :disabled="!!item.channelId"
                                class="w240"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            class="custom-item weight500"
                            label-width="100px"
                            label="品牌"
                            :prop="`sellChannelRelationList[${i}].brandIds`"
                            :rules="rules.brandIds"
                        >
                            <el-select
                                v-model="item.brandIds"
                                class="w240"
                                :filterable="true"
                                clearable
                                multiple
                                :disabled="!!item.channelId"
                            >
                                <el-option
                                    v-for="item in productBrands"
                                    :key="item.id"
                                    :label="item.brandName"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-divider></el-divider>
            </div>
            <div class="add-footer">
                <span @click="handleAddOption">
                    <i
                        class="iconfont icon-benzhouxinzengfenxiaoshang"
                    />新增竞争渠道
                </span>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleConfirm()">保存</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import customerSvc from 'services/customer'

export default {
    name: 'CompetingDialog',
    data () {
        return {
            dialogVisible: false,
            isAdd: true,
            keyword: '',
            searchList: [],
            loading: false,
            parentChannel: '',
            cloneList: [],
            form: {
                tenantId: null,
                channelId: null, // 渠道ID
                relationId: null,
                relationName: null,
                platformId: null,
                platformShopNo: null,
                sellChannelRelationList: []
            },
            rules: {
                relationName: [
                    {
                        required: true,
                        message: '请输入渠道名称',
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        max: 50,
                        message: '长度在 2 到 50 个字符，且不能为空格',
                        trigger: 'blur'
                    }
                ],
                platformId: [
                    {
                        required: true,
                        message: '请选择平台',
                        trigger: 'change'
                    }
                ],
                platformShopNo: [
                    {
                        required: false,
                        message: '请输入平台店铺ID',
                        trigger: 'blur'
                    },
                    {
                        min: 1,
                        max: 50,
                        message: '长度在 1 到 50 个字符，且不能为空格',
                        trigger: 'blur'
                    }
                ],
                brandIds: [
                    {
                        required: true,
                        message: '请选择品牌',
                        trigger: 'change'
                    }
                ]
            }
        }
    },
    computed: {
        dialogTitle () {
            return `${this.isAdd ? '新增' : '编辑'}竞争渠道`
        },
        ...mapState({
            productBrands: state => state.customer.productBrands,
            platformCategory: state => state.customer.platformCategory
        })
    },
    methods: {
        handleDialog (parentForm = {}, isAdd, query) {
            // todo:form表单渠道id，渠道名称
            this.isAdd = !!isAdd
            this.parentChannel = parentForm.channelName
            this.form.channelId = parentForm.id || ''
            if (!this.isAdd) {
                Object.keys(query).forEach(item => {
                    this.form[item] = query[item]
                })
                this.cloneList = _.cloneDeep(!this.isAdd ? this.form.sellChannelRelationList : [])
            }
            this.handleVisible(true)
        },
        handleVisible (boo) {
            this.dialogVisible = boo
            if (boo) {
                this.$nextTick(() => {
                    this.clearValidate()
                })
            }
        },
        handleCancel () {
            Object.keys(this.form).forEach(item => {
                if (item === 'sellChannelRelationList') {
                    this.form[item] = []
                } else {
                    this.form[item] = null
                }
            })
            this.keyword = ''
            this.cloneList = []
            this.form.sellChannelRelationList = []
            this.clearValidate()
            this.handleVisible(false)
        },
        resetForm () {
            this.$refs.form.resetFields()
        },
        clearValidate () {
            this.$refs.form.clearValidate()
        },
        handleConfirm () {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.operationCategory()
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        async handleRemove (i, row) {
            // 删除添加项
            // if(row.id) {
            //     const res = await customerSvc.removeRelation({id: row.id, version: row.version})
            //     this.$emit("handle-refresh")
            // }
            this.form.sellChannelRelationList.splice(i, 1)
            this.$nextTick(() => {
                this.clearValidate()
            })
        },
        handleOption (event, val) {
            // 渠道id
            const target = _.cloneDeep(val)
            const list = this.form.sellChannelRelationList
            if (list.find(item => item.channelNo === target.channelNo)) {
                this.$message('已经添加过')
                return
            }
            target.relationName = target.channelName
            target.channelId = target.id
            delete target.id // 修改不需要这个,这个其实是这条渠道的id --mark-1
            delete target.version // 删除不需要这个 --mark-1
            this.form.sellChannelRelationList.push(target)
        },
        handleAddOption () {
            // 非渠道添加
            this.form.sellChannelRelationList.push({
                relationName: '',
                platformShopNo: '',
                brandIds: [],
                platformId: ''
            })
        },
        async querySearchAsync (queryString) {
            if (!queryString) {
                return
            }
            this.loading = true
            // 远程搜索
            const query = {
                current: 1, // 当前页
                size: 10, // 显示数量
                enabled: null, // 启用状态
                platformShopNoOrchannelNameLike: queryString // 渠道名称
            }
            const { data = [], total = 0 } = await customerSvc.getChannelList(
                query
            )
            this.loading = false
            this.searchList = data
        },
        async operationCategory () {
            // 新建or编辑 预留
            const reqMethod = this.isAdd ? 'save' : 'save'
            // const loading = this.$loading()
            try {
                const form = _.cloneDeep(this.form)
                form.sellChannelRelationList = form.sellChannelRelationList.map(item => {
                    if (item.relationId || item.channelId) {
                        const res = {
                            relationId: item.relationId || item.channelId
                        }
                        if (!this.isAdd && item.id) {
                            // 修改须传item.id，这里的id是这条数据的id --mark-1
                            res.id = item.id
                        }
                        return res
                    }
                    const {
                        relationName,
                        platformShopNo,
                        brandIds,
                        platformId
                    } = item
                    return {
                        relationName,
                        platformShopNo,
                        brandIds,
                        platformId
                    }
                })
                !this.isAdd && this.cloneList.forEach(item => {
                    if (!form.sellChannelRelationList.find(fItem => fItem.relationId === item.channelId)) {
                        const target = {
                            enabled: false,
                            id: item.id,
                            relationId: item.relationId || item.channelId
                        }
                        form.sellChannelRelationList.push(target)
                    }
                })
                const res = await customerSvc[`${reqMethod}Relation`](form)
                this.handleCancel()
                this.$emit('handle-refresh')
                // loading.close()
            } catch (err) {
                console.log('err', err)
                // loading.close()
            }
        }
    }
}
</script>
<style lang="less" scoped>
.competing-dialog {
    .w240 {
        width: 240px;
    }

    .weight500 {
        font-weight: 500;
    }

    /deep/.el-dialog__body {
        max-height: 600px;
        padding: 17px 37px 24px;
        overflow-y: auto;
    }

    /deep/.el-dialog__footer {
        padding: 10px;
        border-top: 2px solid #f0f0f0;

        .el-button {
            width: auto;
            height: auto;
            padding: 10px 18px;
            font-size: 14px;
            border-radius: 8px;
        }
    }

    /deep/.el-divider--horizontal {
        margin: 0 0 24px;
    }

    .search {
        .title {
            font-size: 14px;
            font-weight: 400;
            color: #606266;
        }

        .el-select {
            width: 480px;
            margin: 27px 0;
        }
    }

    .list h4 {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span {
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            color: #c0c4cc;
        }
    }

    .item-desc {
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #606266;
    }

    .custom-item {
    }

    .add-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        color: #3595e1;

        span {
            cursor: pointer;
        }

        i {
            margin-right: 7px;
        }
    }
}

.custom-select-item {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 50px;
    line-height: 50px;

    & > div,
    & > span {
        line-height: 1;
    }

    & > div {
        font-size: 12px;
        color: #b4b4b4;

        .el-tag {
            &:not(:first-of-type) {
                margin-left: 10px;
            }
            color: #b4b4b4;
        }
    }
}
</style>
