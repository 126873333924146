<template>
    <div v-if="pageVisible" class="record-info">
        <el-row class="custom-row">
            <el-col
                :span="12"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="12"
                class="custom-col"
            >
                <span>编码</span>
                <div>{{ infoData.actionRecordNo }}</div>
            </el-col>
            <el-col
                :span="12"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="12"
                class="custom-col"
            >
                <span>渠道</span>
                <div>{{ infoData.channelName }}</div>
            </el-col>
            <el-col
                :span="12"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="12"
                class="custom-col"
            >
                <span>商品</span>
                <div>{{ infoData.spuAlias }}</div>
            </el-col>
            <el-col
                :span="12"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="12"
                class="custom-col"
            >
                <span>运营动作</span>
                <div>{{ infoData.actionName }}</div>
            </el-col>
            <el-col :span="24" :xs="24" class="custom-col">
                <span>调整时间</span>
                <div>{{ infoData.strActionTime }}</div>
            </el-col>
            <el-col
                :span="12"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="12"
                class="custom-col"
            >
                <span>动作原因</span>
                <div>
                    <edit-box
                        class="mb10"
                        fn-type="actionReason"
                        :value="infoData.actionReason"
                        :save-fn="handleSaveInfo"
                        @handle-save="handleSave"
                    ></edit-box>
                </div>
            </el-col>
            <el-col
                :span="12"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="12"
                class="custom-col"
            >
                <span>调整方向</span>
                <div>
                    <edit-box
                        class="mb10"
                        fn-type="adjustmentDirection"
                        :value="infoData.adjustmentDirection"
                        :save-fn="handleSaveInfo"
                        @handle-save="handleSave"
                    ></edit-box>
                </div>
            </el-col>
            <el-col
                :span="12"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="12"
                class="custom-col"
            >
                <span>结果评价</span>
                <div class="custom-rate">
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="置空评分"
                        placement="top"
                    >
                        <i class="iconfont icon-shanchu" @click="handleZero"></i>
                    </el-tooltip>
                    <el-rate
                        v-model="infoData.resultEvaluation"
                        :colors="colors"
                        :texts="texts"
                        show-text
                        @change="rateChange"
                    >
                    </el-rate>
                </div>
            </el-col>
            <el-col
                :span="12"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="12"
                class="custom-col"
            >
                <span>结果说明</span>
                <div>
                    <edit-box
                        class="mb10"
                        fn-type="resultDescription"
                        :value="infoData.resultDescription"
                        :save-fn="handleSaveInfo"
                        @handle-save="handleSave"
                    ></edit-box>
                </div>
            </el-col>
        </el-row>
        <el-row class="indicators-title">
            <el-col :span="24">
                关联指标数据变化
            </el-col>
        </el-row>
        <el-row :gutter="40" class="chart-row">
            <el-col
                v-for="item in chart"
                :key="item.recordIdActionId"
                class="col-chart"
                :span="8"
                :xs="24"
                :sm="24"
                :md="12"
                :lg="12"
                :xl="8"
            >
                <indicators-chart :id="item.recordIdActionId" :key="item.recordIdActionId" :option="item"></indicators-chart>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import _ from 'lodash'
import sellSvc from 'services/sell'
import EditBox from './editBox'
import IndicatorsChart from './indicatorsChart'

export default {
    components: {
        EditBox,
        IndicatorsChart
    },
    props: {
        info: {
            type: Object,
            default: () => {}
        },
        chart: {
            type: Array,
            default: () => []
        },
        pageVisible: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
            infoData: {},
            texts: ['产生明显逆向效果', '产生轻微逆向效果', '无明显效果', '有明显改进', '有显著改进']
        }
    },
    watch: {
        info: {
            immediate: true,
            handler (newVal) {
                this.infoData = _.cloneDeep(newVal || {})
            }
        }
    },
    methods: {
        async handleSave () {

        },
        rateChange (val) {
            this.handleSaveInfo('resultEvaluation', val)
        },
        handleZero () {
            if (this.infoData.resultEvaluation === 0) {
                return
            }
            this.infoData.resultEvaluation = 0
            this.handleSaveInfo('resultEvaluation', 0)
        },
        async handleSaveInfo (type, val) {
            const {
                id,
                actionReason,
                adjustmentDirection,
                resultEvaluation,
                resultDescription
            } = this.info
            const query = {
                id,
                actionReason,
                adjustmentDirection,
                resultEvaluation,
                resultDescription
            }
            query[type] = val
            const { data } = await sellSvc.updateRecord(query)
            this.$emit('update-info', type, val)
            return true
        }
    }
}
</script>
<style lang="scss" scoped>
.record-info {
    display: flex;
    flex-direction: column;
    height: auto !important;
    max-height: 100%;
    padding-top: 64px;

    .custom-col {
        display: flex;
        padding-right: 10px;
        padding-left: 23px;
        margin-bottom: 28px;
        font-size: 14px;

        & > span {
            display: inline-block;
            width: 60px;
            margin-right: 12px;
            font-weight: 500;
            color: #606266;
            text-align: right;
        }

        & > div {
            flex: 1;
            font-weight: 400;
            color: #606266;
        }
    }

    .custom-rate {
        display: flex;

        ::v-deep i {
            font-size: 23px !important;
        }

        ::v-deep .el-rate__text {
            font-size: 10px;
            color: #606266 !important;
        }

        .icon-shanchu {
            margin-top: 3px;
            margin-right: 10px;
            font-size: 12px !important;
            cursor: pointer;
        }

        .icon-shanchu + div {
            margin-top: -3px;
        }
    }

    .custom-row {
        display: flex;
        flex-wrap: wrap;
    }

    .indicators-title {
        margin-bottom: 18px;
        font-size: 14px;
        font-weight: 500;
        color: #606266;
    }

    .mb10 {
        margin-bottom: 10px;
    }

    .col-chart {
        margin-bottom: 30px;
    }

    .chart-row {
        margin-right: 0 !important;
        // margin-left: 0 !important;
    }
}
</style>
