
// 筛选类型
const GOODS_FILTER_TYPES = [
    {
        name: '平台商品ID',
        id: 'platformSpuNoList'
    },
    {
        name: '平台SKU ID',
        id: 'platformSkuNoList'
    },
    {
        name: '商品简称',
        id: 'spuAliasList'
    },
    {
        name: 'SKU简称',
        id: 'skuAliasList'
    }
]

export {
    GOODS_FILTER_TYPES
}
