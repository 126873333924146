<template>
    <div class="gb-channel">
        <!-- 查询 -->
        <query @handle-search="getList"></query>
        <!-- 列表 -->
        <list :list-data="listData" @handle-refresh="handleRefresh"></list>
        <!-- 分页 -->
        <el-pagination
            v-if="total > 0"
            class="custom-page"
            :current-page="form.current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="form.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
    </div>
</template>
<script>
import _ from 'lodash'
import { mapMutations } from 'vuex'
import oaSvc from 'services/oa'
import BreadSlotMixin from 'mixins/breadSlot'
import customerSvc from 'services/customer'
import {
    list,
    query,
    breadSlot
} from './components/index'

// 插入自定义面包屑
const breadSlotMixin = new BreadSlotMixin({ breadSlot })

export default {
    name: 'Channel',
    components: {
        list,
        query
    },
    mixins: [breadSlotMixin],
    beforeRouteLeave (from, to, next) {
        this.setAllDepts(null)
        // this.setPartnerInfo(null)
        this.addPlatformCategory(null)
        next()
    },
    data () {
        return {
            listData: [],
            total: 0,
            form: {
                current: 1, // 当前页
                size: 10, // 显示数量
                enabled: null, // 启用状态
                channelName: '', // 渠道名称
                channelType: '', // 渠道类型id
                platformShopNo: '', // 平台店铺id
                groupId: '', // 组织id
                platformId: '', // 平台id
                platformShopNoList: '', // 平台店铺id集合
                partnerInfoId: '', // 渠道主体id
                foeFlag: false
            },
            formClone: {}
        }
    },
    created () {
        this.getAllSvcData()
    },
    methods: {
        handleSizeChange (size) {
            this.form.size = size
            this.getList()
        },
        handleCurrentChange (page) {
            this.form.current = page
            this.getList()
        },
        handleRefresh (step) {
            const form = step === 'create' ? this.formClone : this.form
            this.getList(form)
        },
        async getList (query = this.form) {
            // 获取列表
            this.form.current = query.current
            if (this.form.size !== 10) {
                query.size = this.form.size
            }
            query.foeFlag = false
            this.form = _.cloneDeep(query)
            const { data = [], total = 0 } = await customerSvc.getChannelList(query)
            this.total = total
            this.listData = data
        },
        async getCategory () {
            const platformCategory = await customerSvc.platformCategory()
            // 子组件需要的平台品类
            const { data } = platformCategory || {}
            this.addPlatformCategory(data)
        },
        async getAllDepts () {
            // 获取管理部门
            const { data = [] } = await oaSvc.getAllDepts()
            this.setAllDepts(data)
        },
        async getProductBrandList () {
            // 获取品牌
            const { data = [] } = await customerSvc.getProductBrands()
            this.setProductBrands(data)
        },
        // getEmlpoyeesList
        async getEmlpoyees () {
            // 获取员工
            const { data = [] } = await oaSvc.getEmlpoyeesList()
            this.setEmlpoyeesList(data)
        },
        async getAllSvcData () {
            try {
                this.getList()
                this.getAllDepts()
                this.getCategory()
                this.getProductBrandList()
                this.getEmlpoyees()
            } catch (err) {
                console.log(err)
            }
        },
        ...mapMutations('customer', {
            setAllDepts: 'UPDATE_ALL_DEPTS',
            addPlatformCategory: 'UPDATE_PLATFORM_CATEGORY',
            setProductBrands: 'UPDATE_PRODUCT_BRANDS',
            setEmlpoyeesList: 'UPDATE_EMLPOYEES_LIST'
        })
    }
}
</script>
<style lang="less" scoped>
.gb-channel {
    /deep/.el-pagination {
        text-align: right;
    }

    .custom-page {
        margin: 30px 0 10px;
    }
}
</style>
