<template>
    <div class="operation-actions-record">
        <query
            :query="query"
            @handle-search="handleCallSearch"
            @handle-reset="handleReset"
        ></query>
        <div class="operation-actions-record-main">
            <left
                v-loading="listLoading"
                class="operation-actions-record-left"
                :list="list"
                :total="total"
                :selected="selected"
                :query="query"
                :page-visible="pageVisible"
                @handle-refresh="handleRefresh"
                @handle-get-info="handleGetInfo"
                @handle-page-change="handlePageChange"
            ></left>
            <right
                :key="selected"
                v-loading="infoLoading"
                class="operation-actions-record-right"
                :info="info"
                :chart="chart"
                :page-visible="pageVisible"
                @update-info="updateInfo"
            ></right>
        </div>
    </div>
</template>
<script>
import { mapMutations } from 'vuex'
import sellSvc from 'services/sell'
import customerSvc from 'services/customer'
import components from './components/index'
import BreadSlotMixin from 'mixins/breadSlot'
import breadSlot from './components/breadSlot.vue'

// 插入自定义面包屑
const breadSlotMixin = new BreadSlotMixin({ breadSlot })

export default {
    name: 'OperationActionsRecord',
    components: {
        ...components
    },
    mixins: [breadSlotMixin],
    data () {
        return {
            query: {
                current: 0,
                size: 10,
                channelIds: null, // 渠道ID 多选
                spuIds: null, // 商品ID 多选
                actionIds: null, // 运营动作 多选
                actionSubjects: null, // 主题选择(多选)
                resultEvaluations: null, // 评分(多选)
                employeeIds: null, // 创建人(多选)
                feedbackType: null // 反馈情况(多选)
            },
            total: 0,
            selected: null,
            list: [],
            info: {},
            chart: [],
            infoLoading: true,
            listLoading: true
        }
    },
    computed: {
        pageVisible () {
            return !!(this.list && this.list.length)
        }
    },
    beforeRouteLeave (from, to, next) {
        this.setChannels(null)
        this.setActions(null)
        next()
    },
    mounted () {
        this.getAllData()
    },
    methods: {
        setSelected (id) {
            // 设置选中ID
            this.selected = id
        },
        async handleRefresh () {
            await this.getActionRecord()
            this.getInfo()
            await this.getChart()
        },
        handlePageChange (form) {
            this.query = { ...this.query, ...form }
            this.handleSearch(this.query)
        },
        handleGetInfo (id) {
            // 点击触发选择
            this.setSelected(id)
            this.getInfo()
            this.getChart()
        },
        handleReset (form) {
            this.handleSearch(form)
        },
        handleCallSearch (form) {
            this.query = { ...form }
            this.query.current = 1
            this.handleSearch(this.query)
        },
        async handleSearch (form) {
            this.query = { ...form }
            await this.getActionRecord()
            this.getInfo()
            await this.getChart()
        },
        async getActionRecord () {
            // 获取列表
            this.listLoading = true
            const { data = [], total = 0 } = await sellSvc.getActionRecord(
                this.query
            )
            this.total = total
            this.list = data
            this.setSelected(data[0] && data[0].id ? data[0].id : null)
            this.listLoading = false
        },
        updateInfo (type, value) {
            this.list = this.list.map(item => {
                if (item.id === this.selected) {
                    item[type] = value
                }
                return item
            })
        },
        getInfo () {
            // 获取左侧信息
            this.info = this.list.find(item => item.id === this.selected) || {}
        },
        async getChart () {
            // 获取图表
            if (!this.selected) {
                return
            }
            this.infoLoading = true
            const { data = {} } = await sellSvc.getIndicator({
                id: this.selected
            })
            this.chart = data
            this.infoLoading = false
        },
        async getChannels () {
            // 获取渠道列表
            const query = {
                enabled: true,
                foeFlag: false
            }
            const { data = [] } = await customerSvc.getChannels(query)
            this.setChannels(data)
        },
        async getAllActions () {
            // 获取动作库列表
            const { data = [] } = await sellSvc.getAllActions()
            this.setActions(data)
        },
        async getAllData () {
            try {
                await this.getChannels()
                await this.getAllActions()
                await this.getActionRecord()
                this.getInfo()
                await this.getChart()
            } catch (e) {
                this.infoLoading = false
                this.listLoading = false
            } finally {
                this.infoLoading = false
                this.listLoading = false
            }
        },
        ...mapMutations('customer', {
            setChannels: 'UPDATE_CHANNELS',
            setActions: 'UPDATE_ACTIONS'
        })
    }
}
</script>
<style lang="scss" scoped>
.operation-actions-record {
    &-main {
        display: flex;
        flex: 1;
        justify-content: space-around;
        overflow: hidden;
    }

    &-left,
    &-right {
        flex: 1;
        height: 100%;
        overflow-y: auto;
    }

    &-left {
        margin-right: 50px;
    }

    &-right {
    }
}
</style>
