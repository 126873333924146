<template>
    <el-dialog
        width="800px"
        :visible.sync="visible"
        :title="title"
        :before-close="handleCancel"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        class="update-dialog"
    >
        <el-form
            ref="form"
            :model="formData"
            :rules="rules"
        >
            <el-row>
                <el-col :span="12">
                    <el-form-item
                        label="渠道"
                        label-width="140px"
                        class="weight500"
                        prop="channelId"
                    >
                        <el-select
                            v-model="formData.channelId"
                            filterable
                            clearable
                            class="w240"
                            @change="handleChannelChange"
                        >
                            <el-option
                                v-for="item in channels"
                                :key="item.id"
                                :label="item.channelName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <!-- todo: -->
                <el-col :span="12">
                    <el-form-item
                        label="操作时间"
                        label-width="140px"
                        class="weight500"
                        prop="strActionTime"
                    >
                        <el-date-picker
                            v-model="formData.strActionTime"
                            type="date"
                            value-format="yyyy/MM/dd"
                            placeholder="选择日期"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-divider></el-divider>
            <div class="search-list">
                <template v-if="visible">
                    <form-item
                        v-for="(item, i) in formData.sellActionRecords"
                        :key="item.id"
                        :item="item"
                        :index="i"
                        :visible="visible"
                        :channel-id="formData.channelId"
                        @handle-validate-filed="handleValidateFiled"
                        @handle-remove="handleRemove"
                    ></form-item>
                </template>
            </div>
            <div class="add-footer">
                <span @click="handleAddOption">
                    <i
                        class="iconfont icon-benzhouxinzengfenxiaoshang"
                    />新增运营动作
                </span>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleConfirm()">保存</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import moment from 'moment'
import _ from 'lodash'
import { mapState } from 'vuex'
import sellSvc from 'services/sell'
import FormItem from './formItem.vue'

export default {
    name: 'AddDialog',
    components: {
        FormItem
    },
    props: {
        // form: {
        //     type: Object,
        //     default: () => {}
        // }
    },
    data () {
        return {
            formData: {
                channelId: null,
                strActionTime: null,
                sellActionRecords: []
            },
            formDataClone: {
                channelId: null,
                strActionTime: null,
                sellActionRecords: []
            },
            visible: false,
            dialogType: '',
            rules: {
                channelId: [
                    {
                        required: true,
                        message: '请选择渠道',
                        trigger: 'change'
                    }
                ],
                strActionTime: [
                    {
                        required: true,
                        message: '请选择日期',
                        trigger: 'change'
                    }
                ]
            }
        }
    },
    computed: {
        title () {
            const titles = {
                add: '运营动作记录'
            }
            return titles[this.dialogType]
        },
        ...mapState({
            channels: state => state.customer.channels
        }),
        visibleObj () {
            return {
                channels: this.channels,
                visible: this.visible
            }
        }
    },
    watch: {
        visibleObj: {
            immediate: true,
            deep: true,
            handler (newVal) {
                console.log(newVal)
                if (newVal.channels && newVal.visible) {
                    this.formData.channelId = newVal.channels[0].id
                }
            }
        }
    },
    methods: {
        handleChannelChange () {
            // 渠道变化
            this.formData.sellActionRecords = this.formData.sellActionRecords.map(item => {
                item.spuIds = null
                return item
            })
        },
        async handleDialog (id, dialogType = 'add') {
            this.dialogType = dialogType
            this.formDataClone = _.cloneDeep(this.formData)
            this.handleVisible(true)
            Object.keys(this.formData).forEach(item => {
                this.formData[item] = null
                if (item === 'strActionTime') {
                    this.formData[item] = moment().format('YYYY/MM/DD')
                }
                if (item === 'sellActionRecords') {
                    this.formData[item] = [{
                        actionId: null, // 动作ID(必须)
                        spuIds: [], // 商品ID列表(必须)
                        actionReason: '', // 动作原因(非必须)
                        adjustmentDirection: '' // 调整方向(非必须)
                    }]
                }
            })
            this.$nextTick(() => {
                this.clearValidate()
            })
        },
        handleVisible (boo) {
            this.visible = boo
        },
        handleAddOption () {
            const form = {
                actionId: [], // 动作ID(必须)
                spuIds: null, // 商品ID列表(必须)
                actionReason: '', // 动作原因(非必须)
                adjustmentDirection: '' // 调整方向(非必须)
            }
            this.formData.sellActionRecords.push(form)
            this.$nextTick(() => {
                this.clearValidate()
            })
        },
        handleRemove (i, { id }) {
            this.formData.sellActionRecords.splice(i, 1)
            this.$nextTick(() => {
                this.clearValidate()
            })
        },
        handleConfirm () {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.operationCategory()
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        handleCancel () {
            // Object.keys(this.form).forEach(item => {
            //     if(item === 'sellSkuList') {
            //         this.form[item] = []
            //     } else {
            //         this.form[item] = null
            //     }
            // })
            this.handleVisible(false)
        },
        clearValidate () {
            this.$refs.form.clearValidate()
        },
        resetForm () {
            this.$refs.form.resetFields()
        },
        handleValidateFiled (field) {
            this.$refs.form.validateField(field)
        },
        async operationCategory () {
            const form = _.cloneDeep(this.formData)
            const data = await sellSvc.saveActionRecord(form)
            this.$emit('handle-refresh')
            this.handleVisible(false)
        }
    }
}
</script>
<style lang="scss" scoped>
.update-dialog {
    .w480 {
        width: 480px;
    }

    .w240 {
        width: 240px;
    }

    .weight500 {
        font-weight: 500;
    }

    ::v-deep.el-dialog__body {
        max-height: 600px;
        padding: 33px 0 21px;
        overflow-y: auto;
    }

    ::v-deep.el-dialog__footer {
        padding: 10px;
        border-top: 2px solid #f0f0f0;

        .el-button {
            width: auto;
            height: auto;
            padding: 10px 18px;
            font-size: 14px;
            border-radius: 8px;
        }
    }

    .search-list {
        padding: 0 37px;
    }

    .add-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        color: #3595e1;

        span {
            cursor: pointer;
        }

        i {
            margin-right: 7px;
        }
    }
}
</style>
