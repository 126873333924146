<template>
    <div class="add-form">
        <el-form ref="notForm" :model="form" :rules="rules">
            <el-row>
                <el-col :span="12">
                    <el-form-item
                        label="渠道"
                        label-width="110px"
                        class="weight500"
                        prop="channelId"
                    >
                        <el-select
                            v-model="form.channelId"
                            filterable
                            clearable
                            class="w240"
                        >
                            <el-option
                                v-for="item in channelsNotCompeting"
                                :key="item.id"
                                :label="item.channelName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                        <!-- <verify-channel-popover @handle-refresh="handleRefresh"></verify-channel-popover> -->
                    </el-form-item>
                </el-col>
                <!-- todo: -->
                <!-- productSpuId -->
                <el-col :span="12">
                    <el-form-item
                        label="主关联货品"
                        label-width="110px"
                        class="weight500"
                        prop="productSpuId"
                    >
                        <el-select
                            v-model="form.productSpuId"
                            filterable
                            clearable
                            remote
                            :remote-method="remoteLoadingMethod"
                            :loading="remoteLoading"
                            class="w240"
                        >
                            <el-option
                                class="custom-select-item"
                                v-for="item in spuListOptions"
                                :key="item.id"
                                :label="item.productTitle"
                                :value="item.id"
                                @click.native="handleProSpuChange(item)"
                            >

                                <span>{{ item.productTitle }}{{item.id}}</span>
                                <div>
                                    <el-tag size="mini" v-if="item.productAlias"
                                        >简称：{{ item.productAlias }}</el-tag
                                    >
                                    <el-tag size="mini" v-if="item.spuNo"
                                        >编码：{{ item.spuNo }}</el-tag
                                    >
                                </div>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <!-- todo: -->
                <el-col :span="12">
                    <el-form-item
                        label="平台商品ID"
                        label-width="110px"
                        class="weight500"
                        prop="platformSpuNo"
                    >
                        <el-input
                            class="w240"
                            clearable
                            v-model="form.platformSpuNo"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="商品简称"
                        label-width="110px"
                        class="weight500"
                        prop="spuAlias"
                    >
                        <el-input
                            class="w240"
                            clearable
                            v-model="form.spuAlias"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <!-- todo: -->
                <el-col :span="12">
                    <el-form-item
                        label="平台品类"
                        label-width="110px"
                        class="weight500"
                        prop="categoryId"
                    >
                        <el-cascader
                            class="w240"
                            ref="cascaderHandle"
                            v-model="form.categoryId"
                            :options="parentCategory"
                            :props="{
                                checkStrictly: true,
                                value: 'id',
                                label: 'categoryName',
                                emitPath: false
                            }"
                            clearable
                            :filterable="true"
                            @change="handleClose"
                        ></el-cascader>
                        <verify-category-popover @handle-refresh="handleRefresh"></verify-category-popover>
                    </el-form-item>
                </el-col>
                <!-- todo: -->
                <el-col :span="12">
                    <el-form-item
                        label="定位"
                        label-width="110px"
                        class="weight500"
                        prop="spuPoint"
                    >
                        <gb-dictionary
                            v-model="form.spuPoint"
                            class="w240 mr40"
                            group="店铺商品定位"
                            :clearable="true"
                            :filterable="true"
                            placeholder="请选择商品定位"
                        ></gb-dictionary>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="品牌"
                        label-width="110px"
                        class="weight500"
                        prop="brandId"
                    >
                        <el-select
                            v-model="form.brandId"
                            class="w240"
                            :filterable="true"
                            clearable
                        >
                            <el-option
                                v-for="item in productBrands"
                                :key="item.id"
                                :label="item.brandName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                        <verify-brand-popover @handle-refresh="handleRefresh"></verify-brand-popover>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="商品链接"
                        label-width="110px"
                        class="weight500"
                        prop="spuUrl"
                    >
                        <el-input
                            clearable
                            class="w240"
                            v-model="form.spuUrl"
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import _ from 'lodash'
import customerSvc from 'services/customer'
import gbDictionary from '@/components/dictionary'
import VerifyCategoryPopover from './VerifyCategoryPopover.vue' // 后期拆分为一个
// import VerifyChannelPopover from './VerifyChannelPopover.vue'
import VerifyBrandPopover from './VerifyBrandPopover.vue'

export default {
    name: 'VerifyAddDialog',
    data() {
        return {
            isCompeting: false,
            form: {
                productSpuId: null,
                channelId: null,
                categoryId: null,
                brandId: null,
                platformSpuNo: null,
                spuAlias: null,
                spuPoint: null, // 测试用
                spuUrl: null,
                sellSkuList: []
            },
            isAdd: true,
            remoteLoading: false,
            spuListOptions: [],
            rules: {
                channelId: [
                    {
                        required: true,
                        message: '请选择渠道',
                        trigger: 'change'
                    }
                ],
                productSpuId: [
                    {
                        required: true,
                        message: '请选择主关联货品SPU',
                        trigger: 'change'
                    }
                ],
                brandId: [
                    {
                        required: true,
                        message: '请选择品牌',
                        trigger: 'change'
                    }
                ],
                platformSpuNo: [
                    {
                        required: true,
                        message: '请输入商品ID',
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        max: 50,
                        message: '长度在 2 到 50 个字符，且不能为空格',
                        trigger: 'blur'
                    }
                ],
                spuAlias: [
                    {
                        required: true,
                        message: '请输入商品简称',
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        max: 50,
                        message: '长度在 2 到 50 个字符，且不能为空格',
                        trigger: 'blur'
                    }
                ],
                categoryId: [
                    {
                        required: true,
                        message: '请选择平台品类',
                        trigger: 'change'
                    }
                ],
                spuPoint: [
                    {
                        required: true,
                        message: '请选择定位',
                        trigger: 'change'
                    }
                ],
                spuUrl: [
                    {
                        required: false,
                        message: '请输入商品链接',
                        trigger: 'blur'
                    },
                    {
                        min: 1,
                        max: 1000,
                        message: '长度在 1 到 1000 个字符，且不能为空格',
                        trigger: 'blur'
                    }
                ]
            },
            // 搜索区域所需字段
            keyword: '',
            searchList: []
        }
    },
    components: {
        gbDictionary,
        VerifyCategoryPopover,
        // VerifyChannelPopover,
        VerifyBrandPopover
    },
    computed: {
        dialogTitle() {
            return `${this.isAdd ? '新增' : '编辑'}店铺商品`
        },
        ...mapState({
            productBrands: state => state.customer.productBrands,
            parentCategory: state => state.customer.parentCategory,
            channelsNotCompeting: state => state.customer.channelsNotCompeting,
            spuList: state => state.customer.spuList,
            channelId: state => state.operation.channelId
        })
    },
    watch: {
        channelId: {
            immediate: true,
            handler(newVal) {
                if(newVal) {
                    this.form.channelId = newVal
                }
            }
        }
    },
    created() {
    },
    methods: {
        handleRefresh(name) {
            this.$emit('handle-refresh', name)
        },
        handleProSpuChange(val) {
            this.form.spuAlias = val.productAlias
        },
        handleClose(val) {
            const cascader = this.$refs.cascaderHandle
            cascader.dropDownVisible = false
            if (!val) {
                // 清空已选择项
                cascader.$refs.panel.checkedValue = [] //也可以是指定的值
                cascader.$refs.panel.calculateCheckedNodePaths()
            }
        },
        handleCancel() {
            this.resetForm()
            this.$emit('handle-visible', false)
        },
        clearValidate() {
            this.$refs.notForm.clearValidate()
        },
        resetForm() {
            this.$refs.notForm.resetFields()
        },
        handleConfirm(step) {
            this.$refs.notForm.validate(valid => {
                if (valid) {
                    this.operationCategory(step)
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        async operationCategory(step) {
            // 新建or编辑
            const reqMethod = this.isAdd ? 'create' : 'update'
            // const loading = this.$loading()
            try {
                const form = _.cloneDeep(this.form)
                const { data } = await customerSvc[`${reqMethod}Spu`](form)
                this.$message({
                    message: '添加成功',
                    type: 'success'
                })
                // this.$emit('handle-refresh', step, data)
                this.$emit('handle-cancel')
            } catch (err) {
                console.log('err', err)
            }
        },
        async remoteLoadingMethod(query, row) {
            if (query !== '') {
                this.remoteLoading = true
                const req = {
                    noOrTitleLike: query,
                    current:1,
                    size:30
                }
                const { data = []} = await customerSvc.getGoodsSpuPage(req)
                this.spuListOptions = data
                this.remoteLoading = false
            } else {
                this.spuListOptions = []
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.add-form {
    .w240 {
        width: 240px;
    }
    .w300 {
        width: 300px;
    }
    .weight500 {
        font-weight: 500;
    }
    ::v-deep.el-dialog__body {
        max-height: 600px;
        overflow-y: auto;
        padding: 33px 0 21px;
    }
    ::v-deep.el-dialog__footer {
        padding: 10px;
        border-top: 2px solid #f0f0f0;
        .el-button {
            width: auto;
            height: auto;
            padding: 10px 18px;
            border-radius: 8px;
            font-size: 14px;
        }
    }
    .search-input {
        margin-left: 20px;
    }
    ::v-deep .add-tip {
        position: absolute;
        margin-left: 8px;
        font-weight: 400;
        color: #3595E1;
        font-size: 14px;
        cursor: pointer;
        i {
            font-size: 12px;
            margin-right: 8px;
        }
    }
    &-header {
        padding-left: 110px;
        margin-bottom: 20px;
    }
}
.custom-select-item {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    height: 50px;
    line-height: 50px;
    & > div,
    & > span {
        line-height: 1;
    }
    & > div {
        font-size: 12px;
        color: #b4b4b4;
        .el-tag {
            &:not(:first-of-type) {
                margin-left: 10px;
            }
            color: #b4b4b4;
        }
    }
}
</style>
