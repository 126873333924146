<template>
    <el-dialog
        class="add-dialog"
        width="840px"
        :visible.sync="dialogVisible"
        :title="dialogTitle"
        :before-close="handleCancel"
        :destroy-on-close="true"
        :close-on-click-modal="false"
    >
        <el-row>
            <el-col class="add-dialog-header">
                <el-checkbox v-model="isCompeting" @change="handleCompeting">是否竞品</el-checkbox>
                    <el-select
                        class="w500 search-input"
                        v-model="keyword"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="输入搜索，选择对应关联竞争的自销商品"
                        :remote-method="querySearchAsync"
                        :loading="loading"
                        v-if="isCompeting"
                    >
                        <el-option
                            class="custom-select-item"
                            v-for="item in searchList"
                            :key="item.id"
                            :label="item.spuAlias"
                            :value="item.id"
                            @click.native="handleOption($event, item)"
                        >
                            <span>{{ item.spuAlias }}</span>
                            <div>
                                <el-tag size="mini" v-if="item.platformSpuNo"
                                    >平台商品Id：{{ item.platformSpuNo }}</el-tag
                                >
                                <el-tag size="mini" v-if="item.spuAlias"
                                    >商品简称：{{ item.spuAlias }}</el-tag
                                >
                                <el-tag size="mini" v-if="item.channelName"
                                    >渠道：{{ item.channelName }}</el-tag
                                >
                            </div>
                        </el-option>
                    </el-select>
            </el-col>
        </el-row>
        <verify-not-competing-form ref="notCompeting" v-if="!isCompeting" @handle-visible="handleVisible" @handle-refresh="handleRefresh" @handle-cancel="handleCancel"></verify-not-competing-form>
        <verify-competing-form ref="competing" v-if="isCompeting" @handle-visible="handleVisible" @handle-cancel="handleCancel" @handle-refresh="handleRefresh"></verify-competing-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleConfirm()">保存</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import _ from 'lodash'
import { mapMutations } from 'vuex'
import VerifyNotCompetingForm from './VerifyNotCompetingForm.vue'
import VerifyCompetingForm from './VerifyCompetingForm.vue'
import customerSvc from 'services/customer'

export default {
    name: 'VerifyAddProcuctDialog',
    data() {
        return {
            dialogVisible: false,
            isCompeting: false,
            searchList: [],
            keyword: '',
            loading: false,
        }
    },
    components: {
        VerifyNotCompetingForm,
        VerifyCompetingForm
    },
    computed: {
        dialogTitle() {
            return `${this.isAdd ? '新增' : '新增'}店铺商品`
        },
    },
    watch: {
        // keyword: {
        //     handler(newVal) {
        //         if(!newVal) {
        //             this.$refs.competing.handleOption('', null)
        //         }
        //     }
        // }
    },
    created() {
        this.getAllData()
    },
    methods: {
        handleDialog(query = {}, isAdd) {
            this.isAdd = !!isAdd
            this.handleVisible(true)
        },
        handleVisible(boo) {
            this.dialogVisible = boo
        },
        handleCancel() {
            const ref = this.isCompeting ? 'competing' : 'notCompeting'
            this.$refs[ref].handleCancel()
            this.keyword = ''
            this.isCompeting = false
        },
        handleConfirm() {
            const ref = this.isCompeting ? 'competing' : 'notCompeting'
            this.$refs[ref].handleConfirm()
        },
        handleOption(event, val) {
            this.$refs.competing.handleOption(event, val)
        },
        handleCompeting() {
            this.keyword = ''
        },
        async querySearchAsync(queryString) {
            if (!queryString) {
                return
            }
            this.loading = true
            // 远程搜索
            const query = {
                current: 1, // 当前页
                size: 10, // 显示数量
                enabled: true, // 启用状态
                foeFlag: false,
                platformSpuNoOrSpuAliasLike: queryString // 渠道名称
            }
            const { data = [], total = 0 } = await customerSvc.getSpuList(query)
            this.loading = false
            this.searchList = data
        },
        handleRefresh(name) {
            this[name]()
        },
        setObjLevel(total, target, max) {
            // 设置级联级数
            return target.map(item => {
                if (total === max) {
                    item.children = null
                } else {
                    item.level = total
                    if (item.children && item.children.length) {
                        item.level++
                        item.children = this.setObjLevel(
                            item.level,
                            item.children,
                            max
                        )
                    } else {
                        item.children = null
                    }
                }
                return item
            })
        },
        // 提取store---start
        async getCategory () {
            // todo: 获取平台品类 提取成store
            const platformCategory = await customerSvc.platformCategory()
            // 子组件需要的平台品类
            const { data } = platformCategory || {}
            this.addPlatformCategory(data)
        },
        async getCategoryList() {
            // 获取品类列表
            const { data = [] } = await customerSvc.parentCategory({
                platformId: null
            })
            this.addParentCategory(this.setObjLevel(1, _.cloneDeep(data), 5))
        },
        async getProductBrandList() {
            // 获取品牌
            const { data = []} = await customerSvc.getProductBrands()
            this.setProductBrands(data)
        },
        async getChannelsNotCompeting() {
            // 非竞品渠道
            this.getChannelList(false)
        },
        async getChannelsCompeting() {
            // 竞品渠道
            this.getChannelList(true)
        },
        async getChannels() {
            this.getChannelList(this.isCompeting)
        },
        async getChannelList(foeFlag = false) {
            // 获取渠道列表
            const query = {
                foeFlag,
                enabled: true,
            }
            const { data = []} = await customerSvc.getChannels(query)
            const setType = foeFlag ? 'Competing' : 'NotCompeting'
            this[`setChannels${setType}`](data)
        },
        ...mapMutations('customer', {
            addPlatformCategory: 'UPDATE_PLATFORM_CATEGORY',
            addParentCategory: 'UPDATE_PARENT_CATEGORY',
            setProductBrands: 'UPDATE_PRODUCT_BRANDS',
            setChannelsCompeting: 'UPDATE_CHANNELS_COMPETING',
            setChannelsNotCompeting: 'UPDATE_CHANNELS_NOT_COMPETING',

        }),
        // 提取store---end
        async getAllData() {
            try {
                await this.getCategoryList()
                await this.getCategory()
                await this.getProductBrandList()
                await this.getChannelsCompeting()
                await this.getChannelsNotCompeting()
            } catch(err) {
                console.log(err)
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.add-dialog {
    .w240 {
        width: 240px;
    }
    .w500 {
        width: 500px;
    }
    .weight500 {
        font-weight: 500;
    }
    ::v-deep.el-dialog__body {
        max-height: 600px;
        overflow-y: auto;
        padding: 33px 0 21px;
    }
    ::v-deep.el-dialog__footer {
        padding: 10px;
        border-top: 2px solid #f0f0f0;
        .el-button {
            width: auto;
            height: auto;
            padding: 10px 18px;
            border-radius: 8px;
            font-size: 14px;
        }
    }
    .search-input {
        margin-left: 20px;
    }
    &-header {
        padding-left: 110px;
        margin-bottom: 20px;
    }
}
.custom-select-item {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    height: 50px;
    line-height: 50px;
    & > div,
    & > span {
        line-height: 1;
    }
    & > div {
        font-size: 12px;
        color: #b4b4b4;
        .el-tag {
            &:not(:first-of-type) {
                margin-left: 10px;
            }
            color: #b4b4b4;
        }
    }
}
</style>
