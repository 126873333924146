<template>
    <el-select
        class="w240 search-input"
        v-model="id"
        filterable
        remote
        reserve-keyword
        placeholder="输入搜索，不选择表示不导入"
        :remote-method="querySearchAsync"
        :loading="loading"
        @change="handleChange"
        :clearable="true"
    >
        <el-option
            class="custom-select-item"
            v-for="item in searchList"
            :key="item.id"
            :label="item.spuAlias"
            :value="item.id"
        >
            <span>{{ item.spuAlias }}</span>
            <div>
                <el-tag size="mini" v-if="item.platformSpuNo"
                    >平台商品Id：{{ item.platformSpuNo }}</el-tag
                >
                <el-tag size="mini" v-if="item.spuAlias"
                    >商品简称：{{ item.spuAlias }}</el-tag
                >
                <el-tag size="mini" v-if="item.channelName"
                    >渠道：{{ item.channelName }}</el-tag
                >
            </div>
        </el-option>
    </el-select>
</template>
<script>
import customerSvc from 'services/customer'

export default {
    name: 'verify-select-remote',
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {
        }
    },
    data() {
        return {
            searchList: [],
            loading: false,
            id: null,
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(newVal) {
                this.id = newVal
            }
        }
    },
    methods: {
        async querySearchAsync(queryString) {
            console.log(queryString)
            if (!queryString) {
                return
            }
            this.loading = true
            // 远程搜索
            const query = {
                current: 1, // 当前页
                size: 10, // 显示数量
                enabled: true, // 启用状态
                platformSpuNoOrSpuAliasLike: queryString // 渠道名称
            }
            const { data = [], total = 0 } = await customerSvc.getSpuList(query)
            this.loading  = false
            this.searchList = data
        },
        handleChange(val) {
            console.log(val)
            this.$emit('change', val)
        }
    }
}
</script>
<style lang="scss" scoped>
.custom-select-item {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    height: 50px;
    line-height: 50px;
    & > div,
    & > span {
        line-height: 1;
    }
    & > div {
        font-size: 12px;
        color: #b4b4b4;
        .el-tag {
            &:not(:first-of-type) {
                margin-left: 10px;
            }
            color: #b4b4b4;
        }
    }
}
.w240 {
    width: 240px;
}
</style>
