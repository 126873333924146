<template>
    <div class="gb-channel-list">
        <div class="gb-channel-list-head">
            <div class="gb-channel-list-head-operation">
                <div class="item" @click="handleAdd(true)">
                    <i class="iconfont icon-benzhouxinzengfenxiaoshang" />新增
                </div>
                <!-- <div class="item" @click="handleDisabled">
                    <i class="iconfont icon-tingyong" />停用
                </div> -->
                <!-- <div class="item" @click="handleImport">
                    <i class="iconfont icon-daoru" />导入
                </div>
                <div class="item" @click="handleDisabled">
                    <i class="iconfont icon-tingyong" />停用
                </div> -->
            </div>
            <!-- <span>当前关注数：xxx</span> -->
        </div>
        <div class="gb-channel-list-main">
            <el-table
                height="500"
                :data="listData"
                style="width: 100%;"
                @expand-change="handleExpand"
            >
                <el-table-column align="center" type="selection" width="55">
                </el-table-column>
                <el-table-column align="center" type="expand">
                    <template
                        v-if="
                            tabs &&
                                tabs[props.row.id] &&
                                tabs[props.row.id].activeName
                        "
                        slot-scope="props"
                    >
                        <!-- todo:tab页 自定义 -->
                        <!-- v-model="tabs[scope.row.id].active" -->
                        <el-tabs
                            v-model="tabs[props.row.id].activeName"
                            v-loading="false"
                            @tab-click="handleClickTab"
                        >
                            <el-tab-pane label="竞争渠道" name="goods">
                                <el-table
                                    v-if="
                                        tabs &&
                                            tabs[props.row.id] &&
                                            tabs[props.row.id].activeName ===
                                            'goods'
                                    "
                                    :data="tabs[props.row.id].goods"
                                >
                                    <el-table-column
                                        align="center"
                                        label="渠道名称"
                                        prop="relationName"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        align="center"
                                        label="平台"
                                        prop="platformName"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        align="center"
                                        label="平台店铺ID"
                                        prop="platformShopNo"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        align="center"
                                        label="操作"
                                        prop="setup"
                                    >
                                        <template slot-scope="childScope">
                                            <el-button
                                                type="text"
                                                @click="
                                                    handleRemoveRelation(
                                                        childScope.row
                                                    )
                                                "
                                            >
                                                删除
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-tab-pane>
                            <!-- <el-tab-pane label="日志" name="log"
                                >日志</el-tab-pane
                            > -->
                        </el-tabs>
                        <!-- <el-pagination layout="prev, pager, next" :total="1000">
                        </el-pagination> -->
                    </template>
                </el-table-column>
                <el-table-column align="center" label="序号" prop="index">
                    <template slot-scope="scope">
                        <span>{{ scope.$index + 1 }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="编码" prop="channelNo">
                </el-table-column>
                <el-table-column align="center" label="渠道" prop="channelName">
                </el-table-column>
                <!-- 渠道类型 -->
                <el-table-column
                    align="center"
                    label="下游用户类型"
                    prop="channelType"
                >
                    <template slot-scope="scope">
                        <!-- {{ scope.row.channelType == 0 ? '消费者' : '分销者' }} -->
                        {{ scope.row.channelType | filterChannelType }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="关联品牌" prop="brands">
                </el-table-column>
                <el-table-column
                    align="center"
                    label="平台店铺ID"
                    prop="platformShopNo"
                >
                </el-table-column>
                <!-- todo:渠道主体 开票主体 -->
                <el-table-column
                    align="center"
                    label="渠道主体"
                    prop="channelCompanyName"
                >
                </el-table-column>
                <el-table-column
                    align="center"
                    label="运营主体"
                    prop="operationCompanyName"
                >
                </el-table-column>
                <el-table-column
                    align="center"
                    label="开票主体"
                    prop="taxCompanyName"
                >
                </el-table-column>
                <el-table-column
                    align="center"
                    label="平台"
                    prop="platformName"
                >
                </el-table-column>
                <el-table-column
                    align="center"
                    label="管理组织"
                    prop="groupName"
                >
                </el-table-column>
                <el-table-column align="center" label="登录名" prop="loginName">
                    <!-- <template slot-scope="scope">
                        {{scope.row.loginName.replace()}}
                    </template> -->
                </el-table-column>
                <el-table-column
                    align="center"
                    label="登录密码"
                    prop="loginPassword"
                >
                    <template slot-scope="scope">
                        ******
                    </template>
                </el-table-column>
                <el-table-column align="center" label="管理员" prop="ownerName">
                </el-table-column>
                <el-table-column
                    align="center"
                    label="操作"
                    fixed="right"
                    prop="desc"
                    width="200"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            @click="handleAdd(false, scope.row)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            @click="handleRelation(scope.row)"
                        >
                            编辑竞渠
                        </el-button>
                        <!-- todo:v-if 启用or停用 -->
                        <el-button
                            v-if="!scope.row.enabled"
                            type="text"
                            @click="
                                handleItem('enabled', {
                                    id: scope.row.id,
                                    version: scope.row.version
                                })
                            "
                        >
                            启用
                        </el-button>
                        <el-button
                            v-else
                            type="text"
                            @click="
                                handleItem('disabled', {
                                    id: scope.row.id,
                                    version: scope.row.version
                                })
                            "
                        >
                            停用
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <add-dialog
            ref="addDialog"
            @handle-refresh="handleRefresh"
        ></add-dialog>
        <competing-dialog
            ref="competingDialog"
            @handle-refresh="handleComRefresh"
        ></competing-dialog>
    </div>
</template>
<script>
import customerSvc from 'services/customer'
import addDialog from './addDialog'
import competingDialog from './competingDialog'

export default {
    name: 'ChannelList',
    components: {
        addDialog,
        competingDialog
    },
    filters: {
        filterChannelType (val) {
            const valToString = val + ''
            const names = {
                1: '分销商',
                2: '消费者'
            }
            return names[valToString] || '-'
        }
    },
    props: {
        listData: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            tabs: {}
        }
    },
    methods: {
        handleAdd (isAdd, row = {}) {
            // 打开编辑弹窗
            let query = {}
            // 结构待优化
            const {
                id = null, // 渠道ID
                channelNo = null, // 渠道编码
                channelName = null, // 渠道名称
                channelType = null, // 渠道类型
                brandIds = null, // 关联品牌
                platformShopNo = null, // 平台店铺ID
                channelCompanyId = null, // 渠道主体
                operationCompanyId = null, // 运营公司ID
                taxCompanyId = null, //  开票主体公司ID
                platformId = null, // 平台id
                groupId = null, // 管理组织
                loginName = null, // 登录名
                loginPassword = null, // 登录密码
                ownerId = '', // 联系人id
                ownerName = '', // 联系人名称
                ownerPhone = '', // 联系人电话
                version = null, // 版本
                foeFlag = false
            } = row
            query = {
                channelNo, // 渠道编码
                channelName, // 渠道名称
                channelType, // 渠道类型
                brandIds, // 关联品牌
                platformShopNo, // 平台店铺ID
                channelCompanyId, // 渠道主体
                operationCompanyId, // 运营公司ID
                taxCompanyId, //  开票主体公司ID
                platformId, // 平台id
                groupId, // 管理组织
                loginName, // 登录名
                loginPassword, // 登录密码
                ownerId, // 联系人id
                ownerName, // 联系人名称
                ownerPhone, // 联系人电话
                foeFlag
            }
            if (!isAdd) {
                query.id = id
                query.version = version
            }
            this.$refs.addDialog.handleDialog(query, isAdd)
        },
        handleRefresh (step, parentForm) {
            // 刷薪列表 competingDialog
            // if 继续 打开窗口
            if (step === 'continue') {
                this.$refs.competingDialog.handleDialog(parentForm, true)
            }
            this.$emit('handle-refresh')
        },
        handleComRefresh () {
            this.$emit('handle-refresh')
        },
        handleImport () {},
        handleClickTab () {},
        handleDisabled () {},
        async handleExpand (row, isExpand) {
            let goods = []
            if (isExpand.find(item => item.id === row.id)) {
                const { data } = await customerSvc.getRelations({
                    channelId: row.id
                })
                goods = data || []
            }
            this.$set(this.tabs, row.id, {
                activeName: 'goods',
                goods,
                logs: []
            })
        },
        async handleRemoveRelation (row) {
            // 删除
            this.$confirm('确定要删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    const res = await customerSvc.removeRelation({
                        id: row.id,
                        version: row.version
                    })
                    const goods = this.tabs[row.channelId].goods.filter(
                        item => item.id !== row.id
                    )
                    this.tabs[row.channelId].goods = goods
                })
                .catch(() => {
                })
        },
        async handleItem (type, query) {
            // 停用 or 启用 是否要确认框
            try {
                const res = await customerSvc[`${type}Channel`](query)
                this.handleRefresh()
            } catch (err) {
                console.log(err)
            }
        },
        async handleRelation (row) {
            const { data = [] } = await customerSvc.getRelations({
                channelId: row.id
            })
            const query = {
                tenantId: null,
                channelId: row.id, // 渠道ID
                relationId: null,
                relationName: null,
                platformId: null,
                platformShopNo: null,
                sellChannelRelationList: []
            }
            query.sellChannelRelationList = data.map(item => {
                const {
                    relationName,
                    platformShopNo,
                    brandIds,
                    platformId,
                    channelId,
                    relationId,
                    id
                } = item
                return {
                    relationName,
                    platformShopNo,
                    brandIds,
                    platformId,
                    channelId: relationId || channelId,
                    id
                }
            })
            this.$refs.competingDialog.handleDialog(
                { channelName: row.channelName },
                false,
                query
            )
        }
    }
}
</script>
<style lang="less" scoped>
.gb-channel-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: 13px;
    overflow: hidden;

    &-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;

        & + div {
            margin-top: 19px;
        }

        &-operation {
            display: flex;
            align-items: center;
        }

        &-operation .item {
            margin-right: 19px;
            font-weight: 400;
            color: #3595e1;
            cursor: pointer;
        }

        &-operation .item i {
            margin-right: 9px;
            font-size: 12px;
        }

        & > span {
            color: #606266;
        }
    }

    &-main {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow: hidden;
    }

    /deep/.el-table {
        &::before {
            height: 0;
        }

        .has-gutter th {
            font-size: 14px;
            font-weight: 500;
            color: #606266;
            background-color: #f7f9fc;
        }

        .expanded + tr:not(.el-table__row) > td {
            padding: 0;
            background: #f7f9fc;

            &:hover {
                background: #f7f9fc !important;
            }

            .el-pagination * {
                background: transparent;
            }
        }

        .el-table--fit {
            background-color: #f7f9fc;
        }
    }

    /deep/.el-table th.is-leaf {
        font-size: 14px;
        font-weight: 500;
        color: #606266;
        background-color: #f7f9fc;
    }

    /deep/.el-tabs__nav-wrap {
        padding: 0 116px;

        .el-tabs__item {
            color: #909399;
        }
    }

    /deep/.el-tabs__header {
        margin: 0;
    }

    /deep/.el-tabs__content .el-table tr {
        background-color: #f7f9fc;
    }
}
</style>
