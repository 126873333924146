<template>
    <div class="gb-goods">
        <!-- todo查询 -->
        <query @handle-search="getList"></query>
        <!-- todo:列表 -->
        <list :list-data="listData" @handle-refresh="handleRefresh" @handle-refresh-api="handleRefreshApi"></list>
        <!-- todo分页 -->
        <el-pagination
            v-if="total > 0"
            class="custom-page"
            :current-page="form.current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="form.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
    </div>
</template>
<script>
import _ from 'lodash'
import { mapMutations } from 'vuex'
import BreadSlotMixin from 'mixins/breadSlot'
import customerSvc from 'services/customer'
import {
    list,
    query,
    breadSlot
} from './components/index'

// 插入自定义面包屑
const breadSlotMixin = new BreadSlotMixin({ breadSlot })

export default {
    name: 'Goods',
    components: {
        list,
        query
    },
    mixins: [breadSlotMixin],
    beforeRouteLeave (from, to, next) {
        this.setProductBrands(null)
        this.addPlatformCategory(null)
        this.setChannels(null)
        this.setChannelsNotCompeting(null)
        this.setChannelsCompeting(null)
        next()
    },
    data () {
        return {
            listData: [],
            total: 0,
            form: {
                current: 1, // 当前页
                size: 10, // 显示数量
                enabled: null, // 启用状态
                channelName: '', // 渠道名称
                platformSpuNo: '', // 渠道类型id
                categoryId: '', // 平台店铺id
                categoryIds: '', // 平台店铺id
                spuAlias: '', // 简称
                spuPoint: '', // 平台id
                foeFlag: false
            },
            formClone: {}
        }
    },
    created () {
        this.getAllSvcData()
    },
    methods: {
        handleSizeChange (size) {
            this.form.size = size
            this.getList()
        },
        handleCurrentChange (page) {
            this.form.current = page
            this.getList()
        },
        handleRefreshApi (name) {
            this[name]()
        },
        handleRefresh (step) {
            const form = step === 'create' ? this.formClone : this.form
            this.getList(form)
        },
        async getList (query = this.form) {
            // todo:获取列表
            this.form.current = query.current
            if (this.form.size !== 10) {
                query.size = this.form.size
            }
            this.form = _.cloneDeep(query)
            const { data = [], total = 0 } = await customerSvc.getSpuList(query)
            this.total = total
            this.listData = data
        },
        async getProductBrandList () {
            // 获取品牌
            const { data = [] } = await customerSvc.getProductBrands()
            this.setProductBrands(data)
        },
        setObjLevel (total, target, max) {
            // 设置级联级数
            return target.map(item => {
                if (total === max) {
                    item.children = null
                } else {
                    item.level = total
                    if (item.children && item.children.length) {
                        item.level++
                        item.children = this.setObjLevel(
                            item.level,
                            item.children,
                            max
                        )
                    } else {
                        item.children = null
                    }
                }
                return item
            })
        },
        async getCategoryList () {
            // 获取品类列表
            const { data = [] } = await customerSvc.parentCategory({
                platformId: null
            })
            this.addParentCategory(this.setObjLevel(1, _.cloneDeep(data), 5))
        },
        async getChannelsNotCompeting () {
            // 非竞品渠道
            this.getChannelList(false)
        },
        async getChannelsCompeting () {
            // 竞品渠道
            this.getChannelList(true)
        },
        async getChannels () {
            this.getChannelList('all')
        },
        async getChannelList (foeFlag = false) {
            // 获取渠道列表
            const query = {
                foeFlag,
                enabled: true
            }
            if (foeFlag === 'all') {
                delete query.foeFlag
            }
            const { data = [] } = await customerSvc.getChannels(query)
            let setType = foeFlag ? 'Competing' : 'NotCompeting'
            if (foeFlag === 'all') {
                setType = ''
            }
            this[`setChannels${setType}`](data)
        },
        // async getChannels() {
        //     // 获取渠道列表
        //     const query = {
        //         foeFlag: false,
        //         enabled: true,
        //     }
        //     const { data = []} = await customerSvc.getChannels(query)
        //     this.setChannels(data)
        // },
        // async getSpuList() {
        //     // 获取spu列表
        //     const query = {
        //         enabled: true
        //     }
        //     const { data = []} = await customerSvc.getGoodsSpu(query)
        //     this.setSpuList(data)
        // },
        async getCategory () {
            // todo: 获取平台品类 提取成store
            const platformCategory = await customerSvc.platformCategory()
            // 子组件需要的平台品类
            const { data } = platformCategory || {}
            console.log(data)
            this.addPlatformCategory(data)
        },
        async getAllSvcData () {
            try {
                this.getList()
                this.getProductBrandList()
                this.getCategoryList()
                this.getChannels()
                this.getCategory()
                // this.getChannelsNotCompeting()
                // this.getChannelsCompeting()
                // this.getSpuList()
            } catch (err) {
                console.log(err)
            }
        },
        ...mapMutations('customer', {
            setProductBrands: 'UPDATE_PRODUCT_BRANDS',
            addPlatformCategory: 'UPDATE_PLATFORM_CATEGORY',
            addParentCategory: 'UPDATE_PARENT_CATEGORY',
            setChannels: 'UPDATE_CHANNELS',
            setChannelsNotCompeting: 'UPDATE_CHANNELS_NOT_COMPETING',
            setChannelsCompeting: 'UPDATE_CHANNELS_COMPETING'
            // setSpuList: 'UPDATE_SPULIST',
        })
    }
}
</script>
<style lang="less" scoped>
.gb-goods {
    /deep/.el-pagination {
        text-align: right;
    }

    .custom-page {
        margin: 30px 0 10px;
    }
}
</style>
