<template>
    <el-steps :active="active" finish-status="success" class="custom-steps">
        <el-step title="文件上传"></el-step>
        <el-step title="字段匹配"></el-step>
        <el-step title="数据验证"></el-step>
    </el-steps>
</template>
<script>
export default {
    name: 'steps-bar',
    props: {
        active: {
            type: Number,
            default: 0
        }
    }
}
</script>
<style lang="scss" scoped>
.custom-steps {
    ::v-deep {
        .el-step__head.is-process, .el-step__head.is-success {
            color: #3595E1;
            border-color: #3595E1
        }
        .el-step__title.is-process, .el-step__title.is-success {
            color: #3595E1;
        }

        .el-step.is-horizontal .el-step__line {
            top: 15px;
        }
    }
}
</style>
