<template>
    <el-dialog
        width="800px"
        :visible.sync="dialogVisible"
        class="competing-dialog"
        :title="dialogTitle"
        :before-close="handleCancel"
        :destroy-on-close="true"
        :close-on-click-modal="false"
    >
        <div class="search">
            <div class="title">当前维护店铺商品：{{ tabline }}</div>
            <el-select
                v-model="keyword"
                class="w240 search-input"
                filterable
                remote
                reserve-keyword
                placeholder="输入搜索已有商品快速增加"
                :remote-method="querySearchAsync"
                :loading="loading"
            >
                <el-option
                    v-for="item in searchList"
                    :key="item.id"
                    class="custom-select-item"
                    :label="item.spuAlias"
                    :value="item.id"
                    @click.native="handleOption($event, item)"
                >
                    <span>{{ item.spuAlias }}</span>
                    <div>
                        <el-tag
                            v-if="item.platformSpuNo"
                            size="mini"
                        >
                            平台商品Id：{{ item.platformSpuNo }}
                        </el-tag>
                        <el-tag
                            v-if="item.spuAlias"
                            size="mini"
                        >
                            商品简称：{{ item.spuAlias }}
                        </el-tag>
                        <el-tag
                            v-if="item.channelName"
                            size="mini"
                        >
                            渠道：{{ item.channelName }}
                        </el-tag>
                    </div>
                </el-option>
            </el-select>
        </div>
        <el-form ref="form" :model="form">
            <div
                v-for="(item, i) in form.sellSpuRelationDTOList"
                :key="item.id"
                class="list"
            >
                <h4>
                    <span>商品{{ i + 1 }}</span>
                    <el-button type="text" @click="handleRemove(i, item)">
                        删除
                    </el-button>
                </h4>
                <el-row>
                    <el-col :span="12">
                        <el-form-item
                            class="custom-item weight500"
                            label-width="100px"
                            label="渠道"
                            :prop="`sellSpuRelationDTOList[${i}].channelId`"
                            :rules="rules.channelId"
                        >
                            <el-select
                                v-model="item.channelId"
                                filterable
                                clearable
                                class="w240"
                                :disabled="!!item.goodsId"
                            >
                                <el-option
                                    v-for="item in channels"
                                    :key="item.id"
                                    class="custom-select-item"
                                    :label="item.channelName"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                            <verify-channel-popover @handle-refresh="handleRefresh"></verify-channel-popover>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            class="custom-item weight500"
                            label-width="100px"
                            label="平台商品ID"
                            :prop="`sellSpuRelationDTOList[${i}].platformSpuNo`"
                            :rules="rules.platformSpuNo"
                        >
                            <el-input
                                v-model="item.platformSpuNo"
                                clearable
                                class="w240"
                                :disabled="!!item.goodsId"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            class="custom-item weight500"
                            label-width="100px"
                            label="商品简称"
                            :prop="`sellSpuRelationDTOList[${i}].spuAlias`"
                            :rules="rules.spuAlias"
                        >
                            <el-input
                                v-model="item.spuAlias"
                                clearable
                                class="w240"
                                :disabled="!!item.goodsId"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            class="custom-item weight500"
                            label-width="100px"
                            label="平台品类"
                            :prop="`sellSpuRelationDTOList[${i}].categoryId`"
                            :rules="rules.categoryId"
                        >
                            <el-cascader
                                ref="cascaderHandle"
                                v-model="item.categoryId"
                                class="w240"
                                :options="parentCategory"
                                :props="{
                                    checkStrictly: true,
                                    value: 'id',
                                    label: 'categoryName',
                                    emitPath: false
                                }"
                                clearable
                                :filterable="true"
                                :disabled="!!item.goodsId"
                                @change="handleClose"
                            ></el-cascader>
                            <verify-category-popover @handle-refresh="handleRefresh"></verify-category-popover>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            class="custom-item weight500"
                            label-width="100px"
                            label="品牌"
                            :prop="`sellSpuRelationDTOList[${i}].brandId`"
                            :rules="rules.brandId"
                            :disabled="!!item.goodsId"
                        >
                            <el-select
                                v-model="item.brandId"
                                class="w240"
                                :filterable="true"
                                clearable
                                :disabled="!!item.goodsId"
                            >
                                <el-option
                                    v-for="item in productBrands"
                                    :key="item.id"
                                    :label="item.brandName"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                            <verify-brand-popover @handle-refresh="handleRefresh"></verify-brand-popover>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            class="custom-item weight500"
                            label-width="100px"
                            label="商品链接"
                            :prop="`sellSpuRelationDTOList[${i}].spuUrl`"
                            :rules="rules.spuUrl"
                        >
                            <el-input
                                v-model="item.spuUrl"
                                clearable
                                class="w240"
                                :disabled="!!item.goodsId"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-divider></el-divider>
            </div>
            <div class="add-footer">
                <span @click="handleAddOption">
                    <i
                        class="iconfont icon-benzhouxinzengfenxiaoshang"
                    />新增竞争商品
                </span>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleConfirm()">保存</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import customerSvc from 'services/customer'
import VerifyCategoryPopover from 'modules/operation/upload/components/VerifyCategoryPopover.vue' // 后期拆分为一个
import VerifyBrandPopover from 'modules/operation/upload/components/VerifyBrandPopover.vue'
import VerifyChannelPopover from 'modules/operation/upload/components/VerifyChannelPopover.vue'

export default {
    name: 'CompetingDialog',
    components: {
        VerifyCategoryPopover,
        VerifyBrandPopover,
        VerifyChannelPopover
    },
    data () {
        return {
            dialogVisible: false,
            form: {
                spuId: '',
                sellSpuRelationDTOList: []
            },
            isAdd: true,
            spuAlias: '',
            platformSpuNo: '',
            searchList: [],
            keyword: '',
            loading: false,
            cloneList: [],
            rules: {
                channelId: [
                    {
                        required: true,
                        message: '请选择渠道',
                        trigger: 'change'
                    }
                ],
                platformSpuNo: [
                    {
                        required: true,
                        message: '请输入平台商品ID',
                        trigger: 'blur'
                    },
                    {
                        min: 1,
                        max: 50,
                        message: '长度在 1 到 50 个字符，且不能为空格',
                        trigger: 'blur'
                    }
                ],
                spuAlias: [
                    {
                        required: true,
                        message: '请输入商品简称',
                        trigger: 'blur'
                    },
                    {
                        min: 1,
                        max: 50,
                        message: '长度在 1 到 50 个字符，且不能为空格',
                        trigger: 'blur'
                    }
                ],
                categoryId: [
                    {
                        required: true,
                        message: '请选择平台品类',
                        trigger: 'change'
                    }
                ],
                brandId: [
                    {
                        required: true,
                        message: '请选择品牌',
                        trigger: 'change'
                    }
                ],
                spuUrl: [
                    {
                        required: false,
                        message: '请输入商品链接',
                        trigger: 'blur'
                    },
                    {
                        min: 1,
                        max: 1000,
                        message: '长度在 1 到 1000 个字符，且不能为空格',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    computed: {
        dialogTitle () {
            return `${this.isAdd ? '新增' : '编辑'}竞争商品`
        },
        tabline () {
            return `${this.channelName} ${this.spuAlias} ${this.platformSpuNo}`
        },
        ...mapState({
            productBrands: state => state.customer.productBrands,
            parentCategory: state => state.customer.parentCategory,
            channels: state => state.customer.channels,
            spuList: state => state.customer.spuList
        })
    },
    methods: {
        handleRefresh (name) {
            this.$emit('handle-refresh-api', name)
        },
        handleClose (val) {
            const cascader = this.$refs.cascaderHandle
            cascader.dropDownVisible = false
            if (!val) {
                // 清空已选择项
                cascader.$refs.panel.checkedValue = [] // 也可以是指定的值
                cascader.$refs.panel.calculateCheckedNodePaths()
            }
        },
        handleDialog (parentForm = {}, isAdd, query = {}) {
            console.log(parentForm)
            this.isAdd = !!isAdd
            this.spuAlias = parentForm.spuAlias
            this.platformSpuNo = parentForm.platformSpuNo
            this.channelName = parentForm.channelName
            this.form.spuId = parentForm.id
            console.log(this.form)
            !this.isAdd && Object.keys(query).forEach(item => {
                this.form[item] = query[item]
            })
            if (!this.isAdd) {
                this.cloneList = _.cloneDeep(!this.isAdd ? this.form.sellSpuRelationDTOList : [])
            }
            this.handleVisible(true)
        },
        handleVisible (boo) {
            this.dialogVisible = boo
            if (boo) {
                this.$nextTick(() => {
                    this.clearValidate()
                })
            }
        },
        handleCancel () {
            Object.keys(this.form).forEach(item => {
                if (item === 'sellSpuRelationDTOList') {
                    this.form[item] = []
                } else {
                    this.form[item] = null
                }
            })
            this.keyword = ''
            this.cloneList = []
            this.form.sellSpuRelationDTOList = []
            this.clearValidate()
            this.handleVisible(false)
        },
        resetForm () {
            this.$refs.form.resetFields()
        },
        clearValidate () {
            this.$refs.form.clearValidate()
        },
        handleConfirm () {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.operationCategory()
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        async handleRemove (i, row) {
            // 删除添加项
            // if(row.id) {
            //     const res = await customerSvc.removeRelationSpu({id: row.id, version: row.version})
            //     this.$emit("handle-refresh")
            // }

            this.form.sellSpuRelationDTOList.splice(i, 1)
            this.$nextTick(() => {
                this.clearValidate()
            })
        },
        async querySearchAsync (queryString) {
            if (!queryString) {
                return
            }
            this.loading = true
            // 远程搜索
            const query = {
                current: 1, // 当前页
                size: 10, // 显示数量
                enabled: true, // 启用状态
                // foeFlag: true, // 竞品
                platformSpuNoOrSpuAliasLike: queryString // 渠道名称
            }
            const { data = [], total = 0 } = await customerSvc.getSpuList(query)
            this.loading = false
            this.searchList = data
        },
        handleOption (event, val) {
            // 渠道id
            const target = _.cloneDeep(val)
            const list = this.form.sellSpuRelationDTOList
            if (list.find(item => item.spuNo === target.spuNo)) {
                this.$message('已经添加过')
                return
            }
            // target.relationName = target.channelName
            target.goodsId = target.id
            delete target.id // 修改不需要这个,这个其实是这条渠道的id --mark-1
            delete target.version // 删除不需要这个 --mark-1
            this.form.sellSpuRelationDTOList.push(target)
        },
        handleAddOption () {
            // 非渠道添加
            this.form.sellSpuRelationDTOList.push({
                channelId: '',
                categoryId: '',
                brandId: '',
                relationName: '',
                platformSpuNo: '',
                spuUrl: ''
            })
        },
        async operationCategory () {
            // 新建or编辑 预留
            const reqMethod = this.isAdd ? 'save' : 'save'
            // const loading = this.$loading()
            try {
                const form = _.cloneDeep(this.form)
                console.log(form)
                form.sellSpuRelationDTOList = form.sellSpuRelationDTOList.map(item => {
                    if (item.relationId || item.goodsId) {
                        const res = {
                            relationId: item.relationId || item.goodsId
                        }
                        if (!this.isAdd && item.id) {
                            // 修改须传item.id，这里的id是这条数据的id --mark-1
                            res.id = item.id
                        }
                        return res
                    }
                    const {
                        channelId,
                        categoryId,
                        brandId,
                        spuAlias: relationName,
                        platformSpuNo,
                        spuUrl
                    } = item
                    return {
                        channelId,
                        categoryId,
                        brandId,
                        relationName,
                        platformSpuNo,
                        spuUrl
                    }
                })
                !this.isAdd && this.cloneList.forEach(item => {
                    console.log(form.sellSpuRelationDTOList.find(fItem => fItem.relationId !== item.goodsId))
                    if (!form.sellSpuRelationDTOList.find(fItem => fItem.relationId === item.goodsId)) {
                        const target = {
                            enabled: false,
                            id: item.id,
                            relationId: item.relationId || item.channelId
                        }
                        form.sellSpuRelationDTOList.push(target)
                    }
                })
                const res = await customerSvc[`${reqMethod}RelationSpu`](form)
                this.handleCancel()
                this.$emit('handle-refresh')
                // loading.close()
            } catch (err) {
                console.log('err', err)
                // loading.close()
            }
        }
    }
}
</script>
<style lang="less" scoped>
.competing-dialog {
    .w240 {
        width: 200px;
    }

    .weight500 {
        font-weight: 500;
    }

    /deep/.add-tip {
        position: absolute;
        top: 0;
        width: 60px;
        margin-left: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #3595e1;
        cursor: pointer;

        i {
            margin-right: 8px;
            font-size: 12px;
        }
    }

    /deep/.el-dialog__body {
        max-height: 600px;
        padding: 17px 37px 24px;
        overflow-y: auto;
    }

    /deep/.el-dialog__footer {
        padding: 10px;
        border-top: 2px solid #f0f0f0;

        .el-button {
            width: auto;
            height: auto;
            padding: 10px 18px;
            font-size: 14px;
            border-radius: 8px;
        }
    }

    /deep/.el-divider--horizontal {
        margin: 0 0 24px;
    }

    .search {
        .title {
            font-size: 14px;
            font-weight: 400;
            color: #606266;
        }

        .search-input {
            width: 480px;
            margin: 27px 0;
        }
    }

    .list h4 {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span {
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            color: #c0c4cc;
        }
    }

    .item-desc {
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #606266;
    }

    .add-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        color: #3595e1;

        span {
            cursor: pointer;
        }

        i {
            margin-right: 7px;
        }
    }
}

.custom-select-item {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 50px;
    line-height: 50px;

    & > div,
    & > span {
        line-height: 1;
    }

    & > div {
        font-size: 12px;
        color: #b4b4b4;

        .el-tag {
            &:not(:first-of-type) {
                margin-left: 10px;
            }
            color: #b4b4b4;
        }
    }
}
</style>
