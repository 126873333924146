<template>
    <div class="indicators-chart">
        <div class="indicators-chart--title">
            {{ option.indicatorCategoryName }}-{{ option.indicatorName
            }}<i class="iconfont icon-shangsheng" :style="style"></i>
        </div>
        <div class="indicators-chart--percentage">
            {{ option.afterAvrgValue || '-' }}
        </div>
        <div class="indicators-chart--small">
            动作前均值
            <span>{{ option.preAvrgValue || '-' }}</span>
        </div>
        <div class="indicators-chart--small">
            较动作前
            <span>{{ option.changeRatio || '-' }}</span>
        </div>
        <div :id="chartId" class="chart-view"></div>
    </div>
</template>
<script>
import echarts from 'echarts'

export default {
    name: 'IndicatorsChart',
    props: {
        id: {
            type: String,
            default: ''
        },
        option: {
            type: Object,
            default: () => {}
        }
    },
    data () {
        return {
            myChart: null
        }
    },
    computed: {
        chartId () {
            return `chart${this.id}`
        },
        style () {
            const { expectedType, changeRatio, isInExpectedMinMax } = this.option
            let rotate = 0
            if (/^-/.test(changeRatio)) {
                // 判断是否为负数
                rotate = 180
            } else if (/^((0\.0+)|(0))%$/.test(changeRatio)) {
                // 判断吗是否为 0% 或者0.0+%
                rotate = 90
            } else {
                rotate = 0
            }
            const maps = {
                1: 0,
                2: 180,
                3: 90
            }
            let color = '#E6A23C'
            if (maps[expectedType] === rotate) {
                color = '#6FD3D1'
                // if (expectedType === 3) {
                //     color = '#E6A23C'
                // }
            } else {
                if (expectedType - 0 === 4 && isInExpectedMinMax) {
                    color = '#6FD3D1'
                } else {
                    color = '#DF5A68'
                }
            }
            return {
                transform: `rotate(${rotate}deg)`,
                color
            }
        }
    },
    mounted () {
        this.chartInit()
        window.onresize = () => {
            //  根据窗口大小调整曲线大小
            this.myChart.resize()
        }
    },
    beforeDestroy () {
        this.myChart && this.myChart.dispose()
    },
    activated () {
        window.onresize = () => {
            //  根据窗口大小调整曲线大小
            this.myChart && this.myChart.resize()
        }
    },
    methods: {
        chartInit () {
            this.myChart = echarts.init(document.getElementById(this.chartId))
            this.myChart.hideLoading()
            this.myChart.clear()
            const { date, sellActionIndicatorDataVOList, isRatio } = this.option
            if (
                !sellActionIndicatorDataVOList ||
                !sellActionIndicatorDataVOList.length
            ) {
                this.myChart.clear()
                // this.myChart.showLoading({
                //     text: '没有相关图表数据',
                //     color: 'transparent',
                //     textColor: '#8a8e91',
                //     maskColor: 'transparent'
                // })
                this.myChart.setOption({
                    title: {
                        show: true,
                        textStyle: {
                            color: '#bcbcbc',
                            fontSize: '12',
                            fontWeight: 'normal'
                        },
                        text: '没有相关图表数据',
                        left: 'center',
                        top: 'center'
                    }
                })
                return
            }
            const option = {
                grid: {
                    // containLabel: true,
                    right: '0',
                    left: '0',
                    top: '29px',
                    bottom: '25px',
                    width: '100%'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        },
                        label: {
                            precision: 0,
                            formatter: function (val, index, i) {
                                if (val.axisDimension === 'x') {
                                    return date[val.seriesData[0].dataIndex]
                                }
                                return val.value
                            }
                        }
                    },
                    formatter: function (val) {
                        return `日期：${date[val[0].dataIndex]}<br/>值：${
                            val[0].value
                        }${isRatio ? '%' : ''}`
                    }
                },
                toolbox: {
                    feature: {}
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisPointer: {
                        type: 'shadow'
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#A7B6C3',
                        align: 'left'

                    }
                },
                yAxis: {
                    type: 'value',
                    // boundaryGap: [0, '30%'],
                    axisPointer: {
                        // 交叉轴
                        label: {
                            show: false
                        }
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        inside: true,
                        margin: 0,
                        color: '#A7B6C3',
                        formatter: function (value, index) {
                            if (index === 0) {
                                return ''
                            }
                            return `${value}${isRatio ? '%' : ''}`
                        }
                    }
                },
                visualMap: {
                    type: 'piecewise',
                    show: false,
                    dimension: 0,
                    seriesIndex: 0,
                    pieces: [
                        {
                            gt: 0,
                            lt: 9,
                            color: 'rgba(225, 236, 253, 1)'
                        }
                    ]
                }
            }
            option.series = [
                {
                    type: 'line',
                    smooth: true,
                    symbol: 'none',
                    symbolSize: 10,
                    // symbolOffset: [-3, 0],
                    showAllSymbol: true,
                    lineStyle: {
                        color: '#3595E1',
                        width: 3
                    },
                    markLine: {
                        symbol: ['none', 'none'],
                        label: { show: false },
                        data: [],
                        lineStyle: {
                            color: 'rgba(167, 182, 195, .4)'
                        }
                    },
                    areaStyle: {
                        normal: {
                            color: 'rgba(225, 236, 253, 1)'
                        }
                    },
                    tooltip: {
                        formatter: function (val) {
                            return val
                        }
                    }
                }
            ]
            option.xAxis.data = sellActionIndicatorDataVOList.map(
                (item, index) => {
                    if (index === sellActionIndicatorDataVOList.length - 1) {
                        item.date = {
                            value: item.date,
                            textStyle: {
                                align: 'right'
                            }
                        }
                    }
                    return item.date
                }
            )
            option.series[0].data = sellActionIndicatorDataVOList.map(
                ({ date, value }, index) => {
                    return {
                        value: value * 1,
                        symbol:
                            index === sellActionIndicatorDataVOList.length - 1
                                ? 'emptyCircle'
                                : 'none'
                    }
                }
            )
            option.series[0].markLine.data = [
                {
                    xAxis: sellActionIndicatorDataVOList.findIndex(
                        item => item.isActionTime
                    )
                }
            ]
            this.myChart.setOption(option)
        }
    }
}
</script>
<style lang="scss" scoped>
.indicators-chart {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 293px;

    &--title {
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 500;
        color: #909399;
    }

    &--percentage {
        margin-bottom: 8px;
        font-size: 24px;
        font-weight: 500;
        color: #54636f;
    }

    &--small {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        color: #909399;

        span {
            font-weight: 500;
            color: #606266;
        }
    }

    &--small + &--small {
        margin-top: 8px;
    }

    .chart-view {
        flex: 1;
    }

    .icon-shangsheng {
        display: inline-block;
        margin-left: 10px;
        font-size: 10px;
    }
}
</style>
