<template>
    <div class="gb-goods-list">
        <div class="gb-goods-list-head">
            <div class="gb-goods-list-head-operation">
                <div class="item" @click="handleAdd">
                    <i class="iconfont icon-benzhouxinzengfenxiaoshang" />新增
                </div>
                <!-- <div class="item" @click="handleImport">
                    <i class="iconfont icon-daoru" />导入
                </div>
                <div class="item" @click="handleDisabled">
                    <i class="iconfont icon-tingyong" />停用
                </div> -->
            </div>
            <!-- <span>当前关注数：xxx</span> -->
        </div>
        <div class="gb-goods-list-main">
            <el-table
                height="500"
                :data="listData"
                style="width: 100%;"
                @expand-change="handleExpand"
            >
                <el-table-column type="selection" width="55" align="center">
                </el-table-column>
                <el-table-column type="expand">
                    <template
                        v-if="
                            tabs &&
                                tabs[props.row.id] &&
                                tabs[props.row.id].activeName
                        "
                        slot-scope="props"
                    >
                        <!-- todo:tab页 自定义 -->
                        <el-tabs
                            v-model="tabs[props.row.id].activeName"
                            v-loading="false"
                            @tab-click="
                                handleClickTab(
                                    $event,
                                    props.row,
                                    tabs[props.row.id].activeName
                                )
                            "
                        >
                            <el-tab-pane label="商品SKU" name="sku">
                                <el-table
                                    v-if="
                                        tabs &&
                                            tabs[props.row.id] &&
                                            tabs[props.row.id].activeName ===
                                            'sku'
                                    "
                                    :data="tabs[props.row.id].list"
                                >
                                    <el-table-column
                                        align="center"
                                        label="货品"
                                        prop="skuTitle"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        align="center"
                                        label="规格"
                                        prop="skuProperty"
                                    >
                                    </el-table-column>
                                    <!-- <el-table-column
                                        align="center"
                                        label="条码"
                                        prop="skuBarCode"
                                    >
                                    </el-table-column> -->
                                    <el-table-column
                                        align="center"
                                        label="简称"
                                        prop="skuAlias"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        align="center"
                                        label="平台SKU ID"
                                        prop="platformSkuNo"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        align="center"
                                        label="操作"
                                        prop="setup"
                                    >
                                        <template slot-scope="childScope">
                                            <el-button
                                                type="text"
                                                @click="
                                                    handleRemoveRelation(
                                                        props.row,
                                                        childScope.row,
                                                        'Sku'
                                                    )
                                                "
                                            >
                                                删除
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-tab-pane>
                            <el-tab-pane label="竞争商品" name="spu">
                                <el-table
                                    v-if="
                                        tabs &&
                                            tabs[props.row.id] &&
                                            tabs[props.row.id].activeName ===
                                            'spu'
                                    "
                                    :data="tabs[props.row.id].list"
                                >
                                    <el-table-column
                                        align="center"
                                        label="渠道"
                                        prop="channelName"
                                    >
                                    </el-table-column>
                                    <!-- todo:商品简称 -->
                                    <el-table-column
                                        align="center"
                                        label="商品简称"
                                        prop="relationName"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        align="center"
                                        label="平台商品ID"
                                        prop="platformSpuNo"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        align="center"
                                        label="品牌"
                                        prop="brandName"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        align="center"
                                        label="操作"
                                        prop="setup"
                                    >
                                        <template slot-scope="childScope">
                                            <el-button
                                                type="text"
                                                @click="
                                                    handleRemoveRelation(
                                                        props.row,
                                                        childScope.row,
                                                        'Spu'
                                                    )
                                                "
                                            >
                                                删除
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-tab-pane>
                            <!-- <el-tab-pane label="日志" name="log"
                                >日志</el-tab-pane
                            > -->
                        </el-tabs>
                        <!-- <el-pagination
                            layout="prev, pager, next"
                            :total="1000"
                        >
                        </el-pagination> -->
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    label="序号"
                    prop="index"
                    width="80"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.$index + 1 }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="渠道" prop="channelName">
                </el-table-column>
                <el-table-column
                    align="center"
                    label="平台商品ID"
                    prop="platformSpuNo"
                >
                </el-table-column>
                <el-table-column align="center" label="简称" prop="spuAlias">
                </el-table-column>
                <el-table-column
                    align="center"
                    label="定位"
                    prop="spuPoint"
                    width="60"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.spuPoint | filterSpuPoint }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="品牌" prop="brandName">
                </el-table-column>
                <el-table-column
                    align="center"
                    label="主关联货品SPU简称"
                    prop="spuAlias"
                    width="160"
                >
                </el-table-column>
                <el-table-column
                    align="center"
                    label="平台品类"
                    prop="categoryName"
                >
                </el-table-column>
                <el-table-column
                    align="center"
                    label="商品链接"
                    prop="spuUrl"
                    width="200"
                >
                    <template slot-scope="scope">
                        <el-tooltip
                            v-if="
                                scope.row.spuUrl &&
                                    scope.row.spuUrl.length > 180
                            "
                            effect="dark"
                            placement="top-start"
                        >
                            <div slot="content" style="width: 200px; white-space: warp;">{{ scope.row.spuUrl }}</div>
                            <a
                                target="_blank"
                                :href="scope.row.spuUrl"
                                style="display: inline-block; width: 180px; overflow: hidden; color: #66b1ff; text-overflow: ellipsis; white-space: nowrap;"
                            >
                                {{ scope.row.spuUrl }}
                            </a>
                        </el-tooltip>
                        <a
                            v-if="
                                !scope.row.spuUrl ||
                                    !(scope.row.spuUrl.length > 180)
                            "
                            target="_blank"
                            :href="scope.row.spuUrl"
                            style="color: #66b1ff;"
                        >
                            {{ scope.row.spuUrl }}
                        </a>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    label="商品SKU"
                    prop="sellSkuNames"
                >
                </el-table-column>
                <el-table-column
                    align="center"
                    label="竞争商品"
                    prop="sellSpuRelationNames"
                >
                </el-table-column>
                <el-table-column
                    align="center"
                    label="创建时间"
                    prop="createTime"
                    width="180"
                >
                </el-table-column>
                <el-table-column
                    align="center"
                    label="操作"
                    fixed="right"
                    prop="desc"
                    width="200"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            @click="handleAdd(false, scope.row)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            @click="handleRelation(scope.row)"
                        >
                            编辑竞品
                        </el-button>
                        <!-- todo:v-if 启用or停用 -->
                        <el-button v-if="!scope.row.favoriteId || scope.row.favoriteId === '0'" type="text" @click="handleFavorite(scope.row)">
                            关注
                        </el-button>
                        <el-button v-else type="text" @click="handleFavorite(scope.row)">
                            取消关注
                        </el-button>
                        <!-- <el-button v-if="!scope.row.enabled" type="text">
                            启用
                        </el-button>
                        <el-button v-else type="text">
                            停用
                        </el-button> -->
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <add-dialog
            ref="addDialog"
            @handle-refresh="handleRefresh"
            @handle-refresh-api="handleRefreshApi"
        ></add-dialog>
        <competing-dialog
            ref="competingDialog"
            @handle-refresh="handleComRefresh"
            @handle-refresh-api="handleRefreshApi"
        ></competing-dialog>
    </div>
</template>
<script>
import customerSvc from 'services/customer'
import sellSvc from 'services/sell'
import addDialog from './addDialog'
import competingDialog from './competingDialog'

export default {
    name: 'GoodsList',
    components: {
        addDialog,
        competingDialog
    },
    filters: {
        filterSpuPoint (val) {
            const names = ['S', 'A', 'B', 'C']
            return names.includes(val) ? val : '-'
        }
    },
    props: {
        listData: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            tabs: {}
        }
    },
    methods: {
        async handleAdd (isAdd, row = {}) {
            console.log(row)
            // 打开弹窗
            let query = {}
            const {
                productSpuId = null,
                channelId = null,
                categoryId = null,
                brandId = null,
                platformSpuNo = null,
                spuAlias = null,
                spuPoint = null, // 测试用s
                spuUrl = null,
                sellSkuList = [],
                id = null,
                spuNo = null,
                version = null
            } = row
            // todo:返回数据
            if (!isAdd) {
                query = {
                    id,
                    spuNo,
                    productSpuId,
                    channelId,
                    version,
                    categoryId,
                    brandId,
                    platformSpuNo,
                    spuAlias,
                    spuPoint,
                    spuUrl,
                    sellSkuList
                }
                const { data } = await customerSvc.skuRelation({
                    spuId: row.id
                })
                query.sellSkuList = data
            } else {
                query = {
                    productSpuId,
                    channelId,
                    categoryId,
                    brandId,
                    platformSpuNo,
                    spuAlias,
                    spuPoint,
                    spuUrl,
                    sellSkuList
                }
            }
            this.$refs.addDialog.handleDialog(query, isAdd)
        },
        async handleRelation (row) {
            // todo:返回数据
            const { data = [] } = await customerSvc.spuRelation({
                spuId: row.id
            })
            const {
                channelName = null,
                spuAlias = null,
                platformSpuNo = null,
                id
            } = row
            const query = {
                spuId: id
            }
            query.sellSpuRelationDTOList = data.map(item => {
                const {
                    channelId,
                    relationId,
                    platformSpuNo,
                    relationName: spuAlias,
                    categoryId,
                    brandId,
                    spuUrl,
                    id
                } = item
                return {
                    channelId,
                    platformSpuNo,
                    spuAlias,
                    categoryId,
                    brandId,
                    spuUrl,
                    goodsId: relationId || channelId,
                    id
                }
            })
            this.$refs.competingDialog.handleDialog(
                { channelName, spuAlias, platformSpuNo, id },
                false,
                query
            )
        },
        handleRefreshApi (name) {
            this.$emit('handle-refresh-api', name)
        },
        handleRefresh (step, parentForm) {
            // 刷薪列表 competingDialog
            // if 继续 打开窗口
            if (step === 'continue') {
                this.$refs.competingDialog.handleDialog(parentForm, true)
            }
            this.$emit('handle-refresh')
        },
        handleComRefresh () {
            this.$emit('handle-refresh')
        },
        handleClickTab (el, row, activeName) {
            // 切换tab
            this.getList(row, activeName)
        },
        async getList (row, type) {
            let list = []
            const query = {}
            query.spuId = row.id
            const { data } = await customerSvc[`${type}Relation`](query)
            list = data || []
            console.log('list', list)
            this.$set(this.tabs, row.id, {
                activeName: type,
                list
            })
        },
        async handleExpand (row, isExpand) {
            if (isExpand.find(item => item.id === row.id)) {
                this.getList(row, 'sku')
            }
        },
        handleFavorite (row) {
            // 关注
            console.log(row)
            const {
                favoriteId,
                id
            } = row
            const isFavorite = !favoriteId || favoriteId === '0'
            this.$confirm(`确定要${isFavorite ? '' : '取消'}关注吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: isFavorite ? 'info' : 'warning'
            })
                .then(async () => {
                    const type = isFavorite ? 'create' : 'delete'
                    let query = {}
                    if (isFavorite) {
                        query = {
                            favoriteType: 0,
                            itemId: id
                        }
                    } else {
                        query = {
                            id: favoriteId
                        }
                    }
                    const res = await sellSvc[`${type}Favorite`](query)
                    this.$emit('handle-refresh')
                })
                .catch(() => {})
        },
        async handleRemoveRelation (row, childRow, type) {
            // 删除
            this.$confirm('确定要删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    const res = await customerSvc[`removeRelation${type}`]({
                        id: childRow.id,
                        version: childRow.version
                    })
                    const list = this.tabs[row.id].list.filter(
                        item => item.id !== childRow.id
                    )
                    this.tabs[row.id].list = list
                })
                .catch(() => {})
        }
    }
}
</script>
<style lang="less" scoped>
.gb-goods-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: 13px;
    overflow: hidden;

    &-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;

        & + div {
            margin-top: 19px;
        }

        &-operation {
            display: flex;
            align-items: center;
        }

        &-operation .item {
            margin-right: 19px;
            font-weight: 400;
            color: #3595e1;
            cursor: pointer;
        }

        &-operation .item i {
            margin-right: 9px;
            font-size: 12px;
        }

        & > span {
            color: #606266;
        }
    }

    &-main {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow: hidden;
    }

    /deep/.el-table {
        .has-gutter th {
            font-size: 14px;
            font-weight: 500;
            color: #606266;
            background-color: #f7f9fc;
        }

        .expanded + tr:not(.el-table__row) > td {
            padding: 0;
            background: #f7f9fc;

            &:hover {
                background: #f7f9fc !important;
            }

            .el-pagination * {
                background: transparent;
            }
        }

        .el-table--fit {
            background-color: #f7f9fc;
        }
    }

    /deep/.el-tabs__nav-wrap {
        padding: 0 116px;

        .el-tabs__item {
            color: #909399;
        }

        .el-tabs__item.is-active {
            color: #3595e1;
        }
    }

    /deep/.el-table th.is-leaf {
        font-size: 14px;
        font-weight: 500;
        color: #606266;
        background-color: #f7f9fc;
    }

    /deep/.el-tabs__header {
        margin: 0;
    }

    /deep/.el-tabs__content .el-table tr {
        background-color: #f7f9fc;
    }
}
</style>
