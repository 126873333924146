<template>
    <div class="gb-actions-record-query">
        <search-query v-bind="$attrs" v-on="$listeners">
            <template v-slot:default="{query, handleSearch}">
                <el-select
                    v-model="query.channelIds"
                    class="w240 mr40"
                    popper-class="custom-popper"
                    placeholder="请选择渠道"
                    :filterable="true"
                    multiple
                    clearable
                    collapse-tags
                    @change="handleSearch"
                >
                    <el-option
                        v-for="item in channels"
                        :key="item.id"
                        :label="item.channelName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <el-select
                    v-model="query.spuIds"
                    class="w240 mr40 search-input"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请输入搜索商品"
                    :remote-method="keyword=>{querySearchAsync(keyword, query)}"
                    :loading="loading"
                    multiple
                    clearable
                    collapse-tags
                    @change="handleSearch"
                >
                    <el-option
                        v-for="item in searchList"
                        :key="item.id"
                        class="custom-select-item"
                        :label="item.spuAlias"
                        :value="item.id"
                    >
                        <span>{{ item.spuAlias }}</span>
                        <div>
                            <el-tag
                                v-if="item.platformSpuNo"
                                size="mini"
                            >
                                平台商品Id：{{ item.platformSpuNo }}
                            </el-tag>
                            <el-tag
                                v-if="item.spuAlias"
                                size="mini"
                            >
                                商品简称：{{ item.spuAlias }}
                            </el-tag>
                            <el-tag
                                v-if="item.channelName"
                                size="mini"
                            >
                                渠道：{{ item.channelName }}
                            </el-tag>
                        </div>
                    </el-option>
                </el-select>
                <el-select
                    v-model="query.actionIds"
                    class="w240 mr40"
                    placeholder="运营动作选择"
                    :filterable="true"
                    multiple
                    clearable
                    collapse-tags
                    @change="handleSearch"
                >
                    <el-option
                        v-for="item in actions"
                        :key="item.id"
                        :label="item.actionName + ' ' + item.actionSubject"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <gb-dictionary
                    ref="xssj"
                    v-model="query.actionSubjects"
                    class="w240 mr40"
                    group="销售运营动作主题"
                    :clearable="true"
                    :filterable="true"
                    placeholder="主题选择"
                    multiple
                    collapse-tags
                    @change="handleSearch"
                >
                </gb-dictionary>
                <gb-dictionary
                    ref="xssj"
                    v-model="query.resultEvaluations"
                    class="w240 mr40"
                    group="销售运营动作评价星级"
                    :clearable="true"
                    :filterable="true"
                    placeholder="评分"
                    multiple
                    collapse-tags
                    @change="handleSearch"
                >
                </gb-dictionary>
                <el-select
                    v-model="query.feedbackType	"
                    class="w240 mr40"
                    placeholder="反馈情况"
                    :filterable="true"
                    collapse-tags
                    clearable
                    @change="handleSearch"
                >
                    <el-option
                        v-for="item in feedback"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name"
                    >
                    </el-option>
                </el-select>
                <gb-employee
                    ref="employee"
                    v-model="query.employeeIds"
                    clearable
                    :multiple="true"
                    class="w240"
                    placeholder="请选择创建人"
                    api-name="getEmlpoyeesList"
                    :is-cascader="false"
                    collapse-tags
                    value-filed="employeeId"
                    label-filed="employeeName"
                    @change="handleSearch"
                ></gb-employee>
            </template>
        </search-query>
    </div>
</template>
<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import customerSvc from 'services/customer'
import sellSvc from 'services/sell'
import GbEmployee from 'components/Department'
import gbDictionary from '@/components/dictionary'

const SearchQuery = () => import('components/search-query')

export default {
    name: 'ActionsLibraryQuery',
    // inject: ['transfer'],
    components: {
        SearchQuery,
        GbEmployee,
        gbDictionary
    },
    data () {
        return {
            searchList: [],
            keyword: '',
            loading: false,
            feedback: [
                { id: 1, name: '超时未反馈' },
                { id: 2, name: '未反馈' },
                { id: 3, name: '已反馈' }
            ]
        }
    },
    computed: {
        employeeIds () {
            return this.$attrs.query.employeeIds
        },
        ...mapState({
            channels: state => state.customer.channels,
            actions: state => state.customer.actions
        })
    },
    watch: {
        employeeIds: {
            handler (newVal) {
                if (!newVal) {
                    this.$refs.employee.setCurrentItem()
                }
            }
        }
    },
    created () {
    },
    methods: {
        async querySearchAsync (queryString, form) {
            console.log(queryString, form)
            if (!queryString) {
                return
            }
            this.loading = true
            // 远程搜索
            const query = {
                current: 1, // 当前页
                size: 10, // 显示数量
                enabled: true, // 启用状态
                // foeFlag: true, // 竞品
                platformSpuNoOrSpuAliasLike: queryString // 渠道名称
            }
            const { data = [], total = 0 } = await sellSvc.getSpuList(
                query
            )
            this.loading = false
            this.searchList = data
        }
    }
}
</script>

<style lang="scss">
.custom-popper .el-select-dropdown__item > span {
    padding-right: 20px;
}
</style>
<style lang="scss" scoped>
.gb-actions-record-query {
    .w240 {
        width: 240px;

        ::v-deep.el-select {
            width: 240px !important;
        }
    }

    .w160 {
        width: 160px;
    }

    .w340 {
        width: 340px;
    }

    .mr40 {
        margin-right: 40px;
    }

    .mr20 {
        margin-right: 20px;
    }

    .w60 {
        width: 60px;
    }

    .monitoring-time {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;

        span {
            margin: 0 10px;
            color: #c0c4cc;

            &:first-of-type {
                margin-left: 0;
            }
        }
    }

    ::v-deep.el-select__tags-text {
        display: inline-block;
        max-width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    ::v-deep.el-tag__close.el-icon-close {
        margin-bottom: 14px;
    }
}

.custom-select-item {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 50px;
    line-height: 50px;

    & > div,
    & > span {
        line-height: 1;
    }

    & > div {
        font-size: 12px;
        color: #b4b4b4;

        .el-tag {
            &:not(:first-of-type) {
                margin-left: 10px;
            }
            color: #b4b4b4;
        }
    }
}
</style>
