<template>
    <div class="verify-step">
                    <!-- <span @click="handleAdd('chkProductList')">新增</span> -->
        <div class="verify-step-content" v-if="isDataPage">
            <div v-for="(item, name) in form" :key="name">
                <h4 v-if="item">
                    {{title[name]}}
                    <span @click="handleAdd(name)">新增</span>
                </h4>
                <el-table class="verify-step-table" :data="item" v-if="item && item.length">
                    <el-table-column align="center" prop="platformSpuNo" label="表内未对应">
                        <template v-if="name === 'chkVisitSourceList'" scope="prop">
                            {{ prop.row.sourceName }}
                        </template>
                        <template v-else scope="prop">
                            {{ prop.row.platformSpuNo }} {{ prop.row.spuAlias }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="id" label="对应选择">
                        <template v-if="name === 'chkVisitSourceList'" scope="prop">
                            <!-- <el-cascader
                                :ref="`cascaderHandle${prop.$index}`"
                                v-model="prop.row.id"
                                :options="source"
                                :props="{
                                    checkStrictly: true,
                                    value: 'id',
                                    label: 'sourceName',
                                    emitPath: false,
                                }"
                                clearable
                                :filterable="true"
                                @change="handleClose($event, prop.$index)"
                            ></el-cascader> -->
                            <!-- @change="handleClose" -->
                            <verify-cascade v-model="prop.row.id" :source="source"></verify-cascade>
                        </template>
                        <template v-else scope="prop">
                            <verify-select-remote v-model="prop.row.id"></verify-select-remote>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <dataDefault v-if="!isDataPage" text="完成数据验证"></dataDefault>
        <el-row class="verify-step-footer">
            <el-col>
                <el-button type="primary" @click="handlePrev">上一步</el-button>
                <el-button type="primary" @click="handleConfirm"
                    >完成</el-button
                >
                <el-button @click="handleCancel">取消</el-button>
            </el-col>
        </el-row>
        <verify-add-product ref="chkProductList"></verify-add-product>
        <verify-add-source ref="chkVisitSourceList"></verify-add-source>
    </div>
</template>
<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import mixin from './mixin'
import DataDefault from './DataDefault.vue'
import VerifyAddProduct from './VerifyAddProduct.vue'
import VerifyAddSource from './VerifyAddSource.vue'
import VerifySelectRemote from './VerifySelectRemote.vue'
import VerifyCascade from './VerifyCascade.vue'

export default {
    name: 'verify-step',
    mixins: [mixin],
    components: {
        DataDefault,
        VerifyAddProduct,
        VerifyAddSource,
        VerifySelectRemote,
        VerifyCascade
    },
    data() {
        return {
            title: {
                chkVisitSourceList: '流量来源',
                chkProductList: '商品'
            },
        }
    },
    computed: {
        isDataPage() {
            return Object.keys(this.form).some(item => this.form && !_.isEmpty(this.form[item]))
        },
        ...mapState({
            source: state => state.operation.source
        })
    },
    methods: {
        handleAdd(name) {
            this.$refs[name].handleDialog({}, true)
        },
        handlePrev() {
            this.$confirm('此操作将删除掉您刚才填写的内容, 是否继续返回上一步?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$emit('handle-prev', 1, 'fieldStep')
            }).catch(() => {

            });
        },
        handleNext() {
            this.$emit('handle-next', 3, _.cloneDeep(this.form))
        },
        handleCancel() {
            this.$emit('handle-cancel')
        },
        handleConfirm() {
            this.handleNext()
        },
        // handleClose(val, i) {
        //     console.log(val, i)
        //     const cascader = this.$refs[`cascaderHandle${i}`]
        //     console.log(this.$refs[`cascaderHandle${i}`])
        //     cascader.dropDownVisible = false
        //     if (!val) {
        //         // 清空已选择项
        //         cascader.$refs.panel.checkedValue = [] //也可以是指定的值
        //         cascader.$refs.panel.calculateCheckedNodePaths()
        //     }
        // },
    }
}
</script>
<style lang="scss" scoped>
.verify-step {
    width: 1000px;
    &-content {
        h4 {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            margin-bottom: 12px;
            font-size: 14px;
            font-weight: 500;
            color: #606266;
            line-height: 24px;
        }
        h4 > span {
            cursor: pointer;
            color: #3595e1;
            font-size: 14px;
            display: flex;
            align-items: center;
        }
        h4 > span i {
            font-size: 12px;
            margin-right: 9px;
        }
    }
    &-table {
        ::v-deep th.is-leaf {
            background: #f7f9fc;
            font-weight: 500;
            color: #606266;
            font-size: 14px;
        }
    }
    &-footer {
        padding-top: 20px;
        .el-button {
            border-radius: 8px;
        }
    }
}
</style>
