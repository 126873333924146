const files = require.context('./', false, /\w+\.vue/)
const target = {}

console.log(files)
files.keys().forEach(item => {
    const fileName = item.split('/').pop().replace(/\.\w+$/, '')
    const config = files(item)
    target[fileName] = config.default || config
})

export default target
