import { render, staticRenderFns } from "./addDialog.vue?vue&type=template&id=1880279e&scoped=true&"
import script from "./addDialog.vue?vue&type=script&lang=js&"
export * from "./addDialog.vue?vue&type=script&lang=js&"
import style0 from "./addDialog.vue?vue&type=style&index=0&id=1880279e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1880279e",
  null
  
)

export default component.exports