<template>
    <div class="form-item">
        <h4>
            <span>运营动作{{ index + 1 }}</span>
            <el-button v-if="index !== 0" type="text" @click="handleRemove(index, item)">
                删除
            </el-button>
        </h4>
        <el-row>
            <!-- 数据分组 -->
            <el-col class="item-desc" :span="12">
                <el-form-item
                    class="weight500"
                    label-width="100px"
                    label="运营动作"
                    :prop="`sellActionRecords[${index}].actionId`"
                    :rules="rules.actionId"
                >
                    <el-select
                        v-model="item.actionId"
                        class="w240"
                        :filterable="true"
                        clearable
                    >
                        <el-option
                            v-for="result in actions"
                            :key="result.id"
                            :label="result.actionName + ' ' + result.actionSubject"
                            :value="result.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col class="item-desc" :span="12">
                <el-form-item
                    class="weight500"
                    label-width="100px"
                    label="操作商品"
                    :prop="`sellActionRecords[${index}].spuIds`"
                    :rules="rules.spuIds"
                >
                    <el-select
                        v-model="item.spuIds"
                        class="w240 mr40 search-input"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="输入搜索商品（多选）"
                        :remote-method="querySearchAsync"
                        :loading="loading"
                        multiple
                        collapse-tags
                    >
                        <el-option
                            v-for="result in searchList"
                            :key="result.id"
                            class="custom-select-item"
                            :label="result.spuAlias"
                            :value="result.id"
                        >
                            <span>{{ result.spuAlias }}</span>
                            <div>
                                <el-tag
                                    v-if="result.platformSpuNo"
                                    size="mini"
                                >
                                    平台商品Id：{{ result.platformSpuNo }}
                                </el-tag>
                                <el-tag
                                    v-if="result.spuAlias"
                                    size="mini"
                                >
                                    商品简称：{{ result.spuAlias }}
                                </el-tag>
                                <el-tag
                                    v-if="result.channelName"
                                    size="mini"
                                >
                                    渠道：{{ result.channelName }}
                                </el-tag>
                            </div>
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col class="item-desc" :span="24">
                <el-form-item
                    class="weight500"
                    label-width="100px"
                    label="动作原因"
                    :prop="`sellActionRecords[${index}].actionReason`"
                    :rules="rules.actionReason"
                >
                    <el-input
                        v-model="item.actionReason"
                        type="textarea"
                        maxlength="200"
                        show-word-limit
                        :autosize="{ minRows: 3, maxRows: 3}"
                    ></el-input>
                </el-form-item>
            </el-col>
            <el-col class="item-desc" :span="24">
                <el-form-item
                    class="weight500"
                    label-width="100px"
                    label="调整方向"
                    :prop="`sellActionRecords[${index}].adjustmentDirection`"
                    :rules="rules.adjustmentDirection"
                >
                    <el-input
                        v-model="item.adjustmentDirection"
                        type="textarea"
                        maxlength="200"
                        :autosize="{ minRows: 3, maxRows: 3}"
                        show-word-limit
                    ></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-divider></el-divider>
    </div>
</template>
<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import sellSvc from 'services/sell'

export default {
    components: {
    },
    props: {
        item: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: 0
        },
        disabled: {
            type: Boolean,
            default: false
        },
        visible: {
            type: Boolean,
            default: false
        },
        channelId: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            itemForm: {},
            indicator: [],
            rules: {
                actionId: [
                    {
                        required: true,
                        message: '请选择运营动作',
                        trigger: 'change'
                    }
                ],
                spuIds: [
                    {
                        required: true,
                        message: '请选择操作商品',
                        trigger: 'change'
                    }
                ]
            },
            searchList: [],
            loading: false
        }
    },
    computed: {
        // watchObj () {
        //     return {
        //         visible: this.visible,
        //         categoryId: this.item.categoryId
        //     }
        // },
        ...mapState({
            actions: state => state.customer.actions
        })
    },
    watch: {
        // watchObj: {
        //     immediate: true,
        //     async handler (newVal) {
        //         if (newVal && newVal.categoryId && newVal.visible) {
        //             const { data } = await sellSvc.getValueIndicator({ categoryId: newVal.categoryId })
        //             this.indicator = data
        //         } else {
        //             this.indicator = []
        //         }
        //     }
        // }
        // 'item.dataFrom': {
        //     immediate: true,
        //     handler (newVal) {
        //         this.$refs.xssj && this.$refs.xssj.refreshOptions()
        //     }
        // }
    },
    created () {
        console.log(this)
        // gbDictionary.init(this)
    },
    methods: {
        handleRemove (i, item) {
            this.$emit('handle-remove', i, item)
        },
        getValueIndicator () {

        },
        handleValidateFiled (field) {
            this.$emit('handle-validate-filed', field)
        },
        async querySearchAsync (queryString) {
            if (!queryString) {
                return
            }
            this.loading = true
            // 远程搜索
            const query = {
                current: 1, // 当前页
                size: 10, // 显示数量
                enabled: true, // 启用状态
                // foeFlag: true, // 竞品
                platformSpuNoOrSpuAliasLike: queryString, // 渠道名称
                channelIds: [this.channelId]
            }
            const { data = [], total = 0 } = await sellSvc.getChannelSpuList(
                query
            )
            this.searchList = data
            this.loading = false
        }
    }
}
</script>
<style lang="scss" scoped>
.form-item {
    .w240 {
        width: 240px;
    }

    .weight500 {
        font-weight: 500;
    }

    .mr10 {
        margin-right: 10px;
    }

    h4 {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span {
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            color: #c0c4cc;
        }
    }

    .item-desc {
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #606266;
    }

    ::v-deep.el-textarea {
        width: 96%;
    }

    ::v-deep.el-select__tags-text {
        display: inline-block;
        max-width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    ::v-deep.el-tag__close.el-icon-close {
        margin-bottom: 14px;
    }
}

.custom-select-item {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 50px;
    line-height: 50px;

    & > div,
    & > span {
        line-height: 1;
    }

    & > div {
        font-size: 12px;
        color: #b4b4b4;

        .el-tag {
            &:not(:first-of-type) {
                margin-left: 10px;
            }
            color: #b4b4b4;
        }
    }
}
</style>
