<template>
    <el-dialog
        class="add-dialog"
        width="800px"
        :visible.sync="dialogVisible"
        :title="dialogTitle"
        :before-close="handleCancel"
        :destroy-on-close="true"
        :close-on-click-modal="false"
    >
        <el-form ref="form" :model="form" :rules="rules">
            <el-row>
                <el-col :span="12">
                    <el-form-item
                        label="渠道"
                        label-width="140px"
                        class="weight500"
                        prop="channelId"
                    >
                        <el-select
                            v-model="form.channelId"
                            filterable
                            clearable
                            class="w240"
                        >
                            <el-option
                                v-for="item in channels"
                                :key="item.id"
                                :label="item.channelName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <!-- todo: -->
                <!-- productSpuId -->
                <el-col :span="12">
                    <el-form-item
                        label="主关联货品SPU"
                        label-width="140px"
                        class="weight500"
                        prop="productSpuId"
                    >
                        <el-select
                            v-model="form.productSpuId"
                            filterable
                            clearable
                            remote
                            :remote-method="remoteLoadingMethod"
                            :loading="remoteLoading"
                            class="w240"
                        >
                            <el-option
                                v-for="item in spuListOptions"
                                :key="item.id"
                                class="custom-select-item"
                                :label="item.productTitle"
                                :value="item.id"
                                @click.native="handleProSpuChange(item)"
                            >
                                <span>{{ item.productTitle }}{{ item.id }}</span>
                                <div>
                                    <el-tag
                                        v-if="item.productAlias"
                                        size="mini"
                                    >
                                        简称：{{ item.productAlias }}
                                    </el-tag>
                                    <el-tag
                                        v-if="item.spuNo"
                                        size="mini"
                                    >
                                        编码：{{ item.spuNo }}
                                    </el-tag>
                                </div>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <!-- todo: -->
                <el-col :span="12">
                    <el-form-item
                        label="平台商品ID"
                        label-width="140px"
                        class="weight500"
                        prop="platformSpuNo"
                    >
                        <el-input
                            v-model="form.platformSpuNo"
                            class="w240"
                            clearable
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="商品简称"
                        label-width="140px"
                        class="weight500"
                        prop="spuAlias"
                    >
                        <el-input
                            v-model="form.spuAlias"
                            class="w240"
                            clearable
                        ></el-input>
                    </el-form-item>
                </el-col>
                <!-- todo: -->
                <el-col :span="12">
                    <el-form-item
                        label="平台品类"
                        label-width="140px"
                        class="weight500"
                        prop="categoryId"
                    >
                        <el-cascader
                            ref="cascaderHandle"
                            v-model="form.categoryId"
                            class="w240"
                            :options="parentCategory"
                            :props="{
                                checkStrictly: true,
                                value: 'id',
                                label: 'categoryName',
                                emitPath: false
                            }"
                            clearable
                            :filterable="true"
                            @change="handleClose"
                        ></el-cascader>
                        <verify-category-popover @handle-refresh="handleRefresh"></verify-category-popover>
                    </el-form-item>
                </el-col>
                <!-- todo: -->
                <el-col :span="12">
                    <el-form-item
                        label="定位"
                        label-width="140px"
                        class="weight500"
                        prop="spuPoint"
                    >
                        <gb-dictionary
                            v-model="form.spuPoint"
                            class="w240 mr40"
                            group="店铺商品定位"
                            :clearable="true"
                            :filterable="true"
                            placeholder="请选择商品定位"
                        ></gb-dictionary>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="品牌"
                        label-width="140px"
                        class="weight500"
                        prop="brandId"
                    >
                        <el-select
                            v-model="form.brandId"
                            class="w240"
                            :filterable="true"
                            clearable
                        >
                            <el-option
                                v-for="item in productBrands"
                                :key="item.id"
                                :label="item.brandName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                        <verify-brand-popover @handle-refresh="handleRefresh"></verify-brand-popover>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="商品链接"
                        label-width="140px"
                        class="weight500"
                        prop="spuUrl"
                    >
                        <el-input
                            v-model="form.spuUrl"
                            clearable
                            class="w240"
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-divider></el-divider>
            <div class="search-list">
                <div class="search">
                    <h3>商品SKU</h3>
                    <el-select
                        v-model="skuKeyword"
                        class="w240 search-input"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="输入搜索货品SKU快速增加"
                        :remote-method="querySearchSku"
                        :loading="skuLoading"
                    >
                        <el-option
                            v-for="item in skuList"
                            :key="item.id"
                            class="custom-select-item"
                            :label="item.skuTitle"
                            :value="item.id"
                            @click.native="handleOption($event, item)"
                        >
                            <span>{{ item.skuTitle }}</span>
                            <div>
                                <el-tag
                                    v-if="item.skuProperty"
                                    size="mini"
                                >
                                    规格：{{ item.skuProperty }}
                                </el-tag>
                                <el-tag
                                    v-if=" item.skuBarCode"
                                    size="mini"
                                >
                                    条码：{{ item.skuBarCode }}
                                </el-tag>
                            </div>
                        </el-option>
                    </el-select>
                </div>
                <div v-for="(item, i) in form.sellSkuList" :key="item.id">
                    <h4>
                        <span>{{ item.skuTitle }}</span>
                        <el-button type="text" @click="handleRemove(i, item)">
                            删除
                        </el-button>
                    </h4>
                    <el-row>
                        <el-col class="item-desc" :span="12">
                            商品编号：{{ item.skuNo }}
                        </el-col>
                        <el-col class="item-desc" :span="12">
                            规格：{{ item.skuProperty || '-' }}
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                class="custom-item"
                                label="简称"
                                :prop="`sellSkuList[${i}].skuAlias`"
                                :rules="arrayRule.skuAlias"
                            >
                                <el-input
                                    v-model="item.skuAlias"
                                    class="w240"
                                    clearable
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                class="custom-item"
                                label="平台SKU ID"
                                :prop="`sellSkuList[${i}].platformSkuNo`"
                                :rules="arrayRule.platformSkuNo"
                            >
                                <el-input
                                    v-model="item.platformSkuNo"
                                    class="w240"
                                    clearable
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-divider
                        v-if="form.sellSkuList.length - 1 !== i"
                    ></el-divider>
                </div>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleConfirm()">保存</el-button>
            <el-button v-if="isAdd" type="primary" @click="handleConfirm('continue')">
                保存并维护竞品
            </el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
import _ from 'lodash'
import customerSvc from 'services/customer'
import gbDictionary from '@/components/dictionary'
import VerifyCategoryPopover from 'modules/operation/upload/components/VerifyCategoryPopover.vue' // 后期拆分为一个
import VerifyBrandPopover from 'modules/operation/upload/components/VerifyBrandPopover.vue'

export default {
    name: 'AddDialog',
    components: {
        gbDictionary,
        VerifyCategoryPopover,
        VerifyBrandPopover
    },
    data () {
        return {
            dialogVisible: false,
            platformId: null,
            form: {
                productSpuId: null,
                channelId: null,
                categoryId: null,
                brandId: null,
                platformSpuNo: null,
                spuAlias: null,
                spuPoint: null, // 测试用
                spuUrl: null,
                sellSkuList: []
            },
            isAdd: true,
            channelList: [],
            loading: false,
            remoteLoading: false,
            skuKeyword: '',
            skuList: [],
            skuLoading: false,
            spuLoading: false,
            cloneList: [],
            spuListOptions: [],
            rules: {
                channelId: [
                    {
                        required: true,
                        message: '请选择渠道',
                        trigger: 'change'
                    }
                ],
                productSpuId: [
                    {
                        required: true,
                        message: '请选择主关联货品SPU',
                        trigger: 'change'
                    }
                ],
                brandId: [
                    {
                        required: true,
                        message: '请选择品牌',
                        trigger: 'change'
                    }
                ],
                platformSpuNo: [
                    {
                        required: false,
                        message: '请输入商品ID',
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        max: 50,
                        message: '长度在 2 到 50 个字符，且不能为空格',
                        trigger: 'blur'
                    }
                ],
                spuAlias: [
                    {
                        required: true,
                        message: '请输入商品简称',
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        max: 50,
                        message: '长度在 2 到 50 个字符，且不能为空格',
                        trigger: 'blur'
                    }
                ],
                categoryId: [
                    {
                        required: true,
                        message: '请选择平台品类',
                        trigger: 'change'
                    }
                ],
                spuPoint: [
                    {
                        required: true,
                        message: '请选择定位',
                        trigger: 'change'
                    }
                ],
                spuUrl: [
                    {
                        required: false,
                        message: '请输入商品链接',
                        trigger: 'blur'
                    },
                    {
                        min: 1,
                        max: 1000,
                        message: '长度在 1 到 1000 个字符，且不能为空格',
                        trigger: 'blur'
                    }
                ]
            },
            arrayRule: {
                platformSkuNo: [
                    {
                        required: false,
                        message: '请输入平台SKU ID',
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        max: 50,
                        message: '长度在 2 到 50 个字符，且不能为空格',
                        trigger: 'blur'
                    }
                ],
                skuAlias: [
                    {
                        required: true,
                        message: '请输入简称',
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        max: 50,
                        message: '长度在 2 到 50 个字符，且不能为空格',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    computed: {
        dialogTitle () {
            return `${this.isAdd ? '新增' : '编辑'}店铺商品`
        },
        ...mapState({
            productBrands: state => state.customer.productBrands,
            parentCategory: state => state.customer.parentCategory,
            channels: state => state.customer.channels,
            spuList: state => state.customer.spuList
        })
    },
    watch: {
        async dialogVisible (newVal) {
            console.log(this.form.productSpuId)
            if (newVal && this.form.productSpuId && !this.add) {
                const req = {
                    id: this.form.productSpuId,
                    current: 1,
                    size: 30
                }
                const { data = [] } = await customerSvc.getGoodsSpuPage(req)
                this.spuListOptions = data
            }
        }
    },
    methods: {
        handleRefresh (name) {
            this.$emit('handle-refresh-api', name)
        },
        handleProSpuChange (val) {
            this.form.spuAlias = val.productAlias
        },
        handleClose (val) {
            const cascader = this.$refs.cascaderHandle
            cascader.dropDownVisible = false
            if (!val) {
                // 清空已选择项
                cascader.$refs.panel.checkedValue = [] // 也可以是指定的值
                cascader.$refs.panel.calculateCheckedNodePaths()
            }
        },
        handleDialog (query = {}, isAdd) {
            this.isAdd = !!isAdd
            const target = _.cloneDeep(query)
            if (!this.isAdd) {
                Object.keys(query).forEach(item => {
                    this.form[item] = target[item]
                })
                this.cloneList = _.cloneDeep(!this.isAdd ? this.form.sellSkuList : [])
            }
            this.handleVisible(true)
        },
        handleVisible (boo) {
            this.dialogVisible = boo
            if (boo) {
                this.$nextTick(() => {
                    this.clearValidate()
                })
            }
        },
        handleCancel () {
            Object.keys(this.form).forEach(item => {
                if (item === 'sellSkuList') {
                    this.form[item] = []
                } else {
                    this.form[item] = null
                }
            })
            this.skuKeyword = ''
            this.cloneList = []
            this.spuListOptions = []
            this.clearValidate()
            this.handleVisible(false)
        },
        clearValidate () {
            this.$refs.form.clearValidate()
        },
        resetForm () {
            this.$refs.form.resetFields()
        },
        handleConfirm (step) {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.operationCategory(step)
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        async handleRemove (i, row) {
            // 删除添加项
            // if (row.id) {
            //     const res = await customerSvc.removeRelation({
            //         id: row.id,
            //         version: row.version
            //     })
            //     this.$emit('handle-refresh')
            // }
            this.form.sellSkuList.splice(i, 1)
            this.clearValidate()
            // this.$nextTick(() => {
            //     this.clearValidate()
            // })
        },
        handleSelect (item) {
            // 选择完后添加
            this.list.push(item)
        },
        async operationCategory (step) {
            // 新建or编辑
            const reqMethod = this.isAdd ? 'create' : 'update'
            // const loading = this.$loading()
            try {
                const form = _.cloneDeep(this.form)
                // form.parentId = form.parentId || 0
                // const { employeeName } = this.emlpoyeesList.find(item => item.employeeId === form.ownerId) || {}
                // form.ownerName = employeeName
                console.log(form.sellSkuList)
                form.sellSkuList = form.sellSkuList && form.sellSkuList.length ? form.sellSkuList.map(item => {
                    if (item.version || item.version == 0) {
                        const {
                            id,
                            spuId,
                            productSkuId,
                            skuAlias,
                            platformSkuNo
                        } = item
                        return {
                            id,
                            spuId,
                            productSkuId,
                            skuAlias,
                            platformSkuNo
                        }
                    }
                    const {
                        skuAlias,
                        platformSkuNo,
                        id: productSkuId
                    } = item
                    return {
                        skuAlias,
                        productSkuId,
                        platformSkuNo
                    }
                }) : []
                console.log(form.sellSkuList)
                !this.isAdd && this.cloneList.forEach(item => {
                    if (!form.sellSkuList.find(fItem => fItem.id === item.id)) {
                        const target = {
                            enabled: false,
                            id: item.id
                            //  productSkuId: item.productSkuId,
                        }
                        form.sellSkuList.push(target)
                    }
                })
                const { data } = await customerSvc[`${reqMethod}Spu`](form)
                this.$message({
                    message: `保存成功${
                        step === 'continue' ? ', 继续添加竞品' : ''
                    }`,
                    type: 'success'
                })
                this.$emit('handle-refresh', step, data)
                this.handleCancel()
                // loading.close()
            } catch (err) {
                console.log('err', err)
                // loading.close()
            }
        },
        async querySearchSku (queryString) {
            // 快速增加sku
            if (!queryString) {
                return
            }
            this.loading = true
            // 远程搜索
            const query = {
                enabled: true, // 启用状态
                noOrTitleLike: queryString, // 渠道名称
                current: 1,
                size: 10
            }
            const { data = [], total = 0 } = await customerSvc.getSkulistPage(query)
            this.skuloading = false
            this.skuList = data
        },
        handleOption (event, val) {
            // 渠道id
            const target = _.cloneDeep(val)
            const list = this.form.sellSkuList
            if (list.find(item => [item.productSkuId, item.id].includes(target.id))) {
                this.$message('已经添加过')
                return
            }
            // // target.relationName = target.channelName
            // target.gId = target.id
            // delete target.id // 修改不需要这个,这个其实是这条渠道的id --mark-1
            // delete target.version // 删除不需要这个 --mark-1
            target.skuAlias = target.skuTitle + (target.skuProperty === '默认规格' ? '' : target.skuProperty)
            target.version = null
            this.form.sellSkuList.push(target)
        },
        async remoteLoadingMethod (query, row) {
            if (query !== '') {
                this.remoteLoading = true
                const req = {
                    noOrTitleLike: query,
                    current: 1,
                    size: 30
                }
                const { data = [] } = await customerSvc.getGoodsSpuPage(req)
                this.spuListOptions = data
                this.remoteLoading = false
            } else {
                this.spuListOptions = []
            }
        }
    }
}
</script>
<style lang="less" scoped>
.add-dialog {
    .w240 {
        width: 240px;
    }

    .weight500 {
        font-weight: 500;
    }

    /deep/.add-tip {
        position: absolute;
        top: 0;
        width: 60px;
        margin-left: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #3595e1;
        cursor: pointer;

        i {
            margin-right: 8px;
            font-size: 12px;
        }
    }

    /deep/.el-dialog__body {
        max-height: 600px;
        padding: 33px 0 21px;
        overflow-y: auto;
    }

    /deep/.el-dialog__footer {
        padding: 10px;
        border-top: 2px solid #f0f0f0;

        .el-button {
            width: auto;
            height: auto;
            padding: 10px 18px;
            font-size: 14px;
            border-radius: 8px;
        }
    }

    .search-list {
        padding: 0 37px;

        .search {
            margin-bottom: 41px;

            h3 {
                margin-bottom: 20px;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                color: #909399;
            }

            .search-input {
                width: 480px;
            }
        }

        h4 {
            display: flex;
            justify-content: space-between;
            margin-bottom: 11px;

            span {
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                color: #606266;
            }
        }

        .item-desc {
            margin-bottom: 20px;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            color: #606266;
        }

        .custom-item {
            margin-bottom: 0;
        }
    }
}

.custom-select-item {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 50px;
    line-height: 50px;

    & > div,
    & > span {
        line-height: 1;
    }

    & > div {
        font-size: 12px;
        color: #b4b4b4;

        .el-tag {
            &:not(:first-of-type) {
                margin-left: 10px;
            }
            color: #b4b4b4;
        }
    }
}
</style>
