var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gb-goods-list"},[_c('div',{staticClass:"gb-goods-list-head"},[_c('div',{staticClass:"gb-goods-list-head-operation"},[_c('div',{staticClass:"item",on:{"click":_vm.handleAdd}},[_c('i',{staticClass:"iconfont icon-benzhouxinzengfenxiaoshang"}),_vm._v("新增 ")])])]),_c('div',{staticClass:"gb-goods-list-main"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"height":"500","data":_vm.listData},on:{"expand-change":_vm.handleExpand}},[_c('el-table-column',{attrs:{"type":"selection","width":"55","align":"center"}}),_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(props){return (
                        _vm.tabs &&
                            _vm.tabs[props.row.id] &&
                            _vm.tabs[props.row.id].activeName
                    )?[_c('el-tabs',{directives:[{name:"loading",rawName:"v-loading",value:(false),expression:"false"}],on:{"tab-click":function($event){return _vm.handleClickTab(
                                $event,
                                props.row,
                                _vm.tabs[props.row.id].activeName
                            )}},model:{value:(_vm.tabs[props.row.id].activeName),callback:function ($$v) {_vm.$set(_vm.tabs[props.row.id], "activeName", $$v)},expression:"tabs[props.row.id].activeName"}},[_c('el-tab-pane',{attrs:{"label":"商品SKU","name":"sku"}},[(
                                    _vm.tabs &&
                                        _vm.tabs[props.row.id] &&
                                        _vm.tabs[props.row.id].activeName ===
                                        'sku'
                                )?_c('el-table',{attrs:{"data":_vm.tabs[props.row.id].list}},[_c('el-table-column',{attrs:{"align":"center","label":"货品","prop":"skuTitle"}}),_c('el-table-column',{attrs:{"align":"center","label":"规格","prop":"skuProperty"}}),_c('el-table-column',{attrs:{"align":"center","label":"简称","prop":"skuAlias"}}),_c('el-table-column',{attrs:{"align":"center","label":"平台SKU ID","prop":"platformSkuNo"}}),_c('el-table-column',{attrs:{"align":"center","label":"操作","prop":"setup"},scopedSlots:_vm._u([{key:"default",fn:function(childScope){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleRemoveRelation(
                                                    props.row,
                                                    childScope.row,
                                                    'Sku'
                                                )}}},[_vm._v(" 删除 ")])]}}],null,true)})],1):_vm._e()],1),_c('el-tab-pane',{attrs:{"label":"竞争商品","name":"spu"}},[(
                                    _vm.tabs &&
                                        _vm.tabs[props.row.id] &&
                                        _vm.tabs[props.row.id].activeName ===
                                        'spu'
                                )?_c('el-table',{attrs:{"data":_vm.tabs[props.row.id].list}},[_c('el-table-column',{attrs:{"align":"center","label":"渠道","prop":"channelName"}}),_c('el-table-column',{attrs:{"align":"center","label":"商品简称","prop":"relationName"}}),_c('el-table-column',{attrs:{"align":"center","label":"平台商品ID","prop":"platformSpuNo"}}),_c('el-table-column',{attrs:{"align":"center","label":"品牌","prop":"brandName"}}),_c('el-table-column',{attrs:{"align":"center","label":"操作","prop":"setup"},scopedSlots:_vm._u([{key:"default",fn:function(childScope){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleRemoveRelation(
                                                    props.row,
                                                    childScope.row,
                                                    'Spu'
                                                )}}},[_vm._v(" 删除 ")])]}}],null,true)})],1):_vm._e()],1)],1)]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"align":"center","label":"序号","prop":"index","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.$index + 1))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"渠道","prop":"channelName"}}),_c('el-table-column',{attrs:{"align":"center","label":"平台商品ID","prop":"platformSpuNo"}}),_c('el-table-column',{attrs:{"align":"center","label":"简称","prop":"spuAlias"}}),_c('el-table-column',{attrs:{"align":"center","label":"定位","prop":"spuPoint","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(_vm._f("filterSpuPoint")(scope.row.spuPoint)))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"品牌","prop":"brandName"}}),_c('el-table-column',{attrs:{"align":"center","label":"主关联货品SPU简称","prop":"spuAlias","width":"160"}}),_c('el-table-column',{attrs:{"align":"center","label":"平台品类","prop":"categoryName"}}),_c('el-table-column',{attrs:{"align":"center","label":"商品链接","prop":"spuUrl","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                            scope.row.spuUrl &&
                                scope.row.spuUrl.length > 180
                        )?_c('el-tooltip',{attrs:{"effect":"dark","placement":"top-start"}},[_c('div',{staticStyle:{"width":"200px","white-space":"warp"},attrs:{"slot":"content"},slot:"content"},[_vm._v(_vm._s(scope.row.spuUrl))]),_c('a',{staticStyle:{"display":"inline-block","width":"180px","overflow":"hidden","color":"#66b1ff","text-overflow":"ellipsis","white-space":"nowrap"},attrs:{"target":"_blank","href":scope.row.spuUrl}},[_vm._v(" "+_vm._s(scope.row.spuUrl)+" ")])]):_vm._e(),(
                            !scope.row.spuUrl ||
                                !(scope.row.spuUrl.length > 180)
                        )?_c('a',{staticStyle:{"color":"#66b1ff"},attrs:{"target":"_blank","href":scope.row.spuUrl}},[_vm._v(" "+_vm._s(scope.row.spuUrl)+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"商品SKU","prop":"sellSkuNames"}}),_c('el-table-column',{attrs:{"align":"center","label":"竞争商品","prop":"sellSpuRelationNames"}}),_c('el-table-column',{attrs:{"align":"center","label":"创建时间","prop":"createTime","width":"180"}}),_c('el-table-column',{attrs:{"align":"center","label":"操作","fixed":"right","prop":"desc","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleAdd(false, scope.row)}}},[_vm._v(" 编辑 ")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleRelation(scope.row)}}},[_vm._v(" 编辑竞品 ")]),(!scope.row.favoriteId || scope.row.favoriteId === '0')?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleFavorite(scope.row)}}},[_vm._v(" 关注 ")]):_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleFavorite(scope.row)}}},[_vm._v(" 取消关注 ")])]}}])})],1)],1),_c('add-dialog',{ref:"addDialog",on:{"handle-refresh":_vm.handleRefresh,"handle-refresh-api":_vm.handleRefreshApi}}),_c('competing-dialog',{ref:"competingDialog",on:{"handle-refresh":_vm.handleComRefresh,"handle-refresh-api":_vm.handleRefreshApi}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }