<template>
    <div class="gb-operation-chart">
        <!-- 查询 -->
        <query @handle-search="handleSearch"></query>
        <div class="gb-operation-chart-content">
            <!-- 左侧图表 -->
            <div class="gb-operation-chart-content-left">
                <template v-if="form.spuId">
                    <!-- 竞品 -->
                    <analysis
                        :form="form"
                        :channelId="channelId"
                        ref="analysis"
                        v-show="['0', '1'].includes(form.targetType)"
                    ></analysis>
                </template>
                <template v-else>
                    <div class="empty-data">
                        请选择商品。如未关注商品，请点击右上角商品快捷入口跳转<span
                            class="link"
                            @click="handleRouter('customer-goods')"
                            >商品页</span
                        >，添加关注
                    </div>
                </template>
            </div>
            <!-- 右侧评论 -->
            <comments
                class="gb-operation-chart-content-right"
                :data="commentData"
                :associate="commentAssociateData"
                :form="form"
                v-loading="commentLoading"
                @handle-refresh="allComents"
            ></comments>
        </div>
    </div>
</template>
<script>
import { mapMutations } from 'vuex'
import components from './components/index'
import chartsComponents from './components/charts/index'
import customerSvc from 'services/customer'
import sellSvc from 'services/sell'
import BreadSlotMixin from 'mixins/breadSlot'
const { query, comments, breadSlot } = components
const { analysis } = chartsComponents

const breadSlotMixin = new BreadSlotMixin({ breadSlot })

export default {
    name: 'operation-chart',
    mixins: [breadSlotMixin],
    components: {
        query,
        comments,
        analysis
    },
    beforeRouteLeave(to, from, next) {
        this.setChannels(null)
        next()
    },
    data() {
        return {
            form: {
                spuId: null,
                startDay: null,
                endDay: null,
                targetType: '0'
            },
            commentData: '',
            commentLoading: false,
            commentAssociateData: []
        }
    },
    methods: {
        handleRouter(name) {
            this.$router.push({
                name
            })
        },
        getAllData() {
            try {
                // this.getChartData()
                // this.getComments()
                this.getChannels()
            } catch (e) {
                console.log(e)
            }
        },
        handleSearch(form, channelId) {
            Object.keys(form).forEach(item => {
                this.form[item] = form[item]
            })
            this.channelId = channelId
            if(!form.spuId) {
                return
            }
            this.getCompetingData()
            this.allComents()
        },
        async allComents() {
            this.commentLoading = true
            try {
                await this.getComments()
                await this.getComments('Associate')
                this.commentLoading = false
            } catch (e) {
                this.commentLoading = false
            }
        },
        async getComments(type = '') {
            // 获取评论相关数据 改天放到comments
            const { targetType, spuId: targetId, startDay, endDay } = this.form
            const query = {
                targetType,
                targetId,
                startDay,
                endDay
            }
            query.targetType = '1'

            const { data } = await sellSvc[`get${type}Comment`](query)
            if (!type) {
                this.commentData = data && data.comment ? data.comment : ''
            } else {
                this.commentAssociateData = data || []
            }
        },
        getCompetingData() {
            // 更新数据
            this.$nextTick(item => {
                this.$refs.analysis.getAllData()
            })
        },
        async getChannels() {
            // 获取渠道列表
            const query = {
                enabled: true,
                foeFlag: false
            }
            const { data = [] } = await customerSvc.getChannels(query)
            this.setChannels(data)
        },
        ...mapMutations('customer', {
            setChannels: 'UPDATE_CHANNELS'
            // setSpuList: 'UPDATE_SPULIST',
        })
    },
    mounted() {
        this.getAllData()
    },
    activated() {
        // 触发缓存
        this.getAllData()
        this.addSlot(breadSlot)
    }
}
</script>
<style lang="scss" scoped>
.gb-operation-chart {
    &-content {
        display: flex;
        flex: 1;
        overflow: hidden;
        &-left {
            flex: 1;
            overflow-y: auto;
            margin-right: 20px;
            min-height: 100%;
        }
        &-right {
            width: 360px;
            max-height: 100%;
            overflow: hidden;
            min-height: 100%;
        }
    }
}
.empty-data {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #606266;
    font-size: 14px;
    .link {
        color: #409eff;
        cursor: pointer;
    }
}
</style>
