<template>
    <el-form ref="comForm" :model="form" class="competing-dialog">
            <div
                class="list"
                v-for="(item, i) in form.sellSpuRelationDTOList"
                :key="item.id"
            >
                <h4>
                    <span>商品{{ i + 1 }}</span>
                    <!-- <el-button type="text" @click="handleRemove(i, item)">
                        删除
                    </el-button> -->
                </h4>
                <el-row>
                    <el-col :span="12">
                        <el-form-item
                            class="custom-item weight500"
                            label-width="100px"
                            label="渠道"
                            :prop="`sellSpuRelationDTOList[${i}].channelId`"
                            :rules="rules.channelId"
                        >
                            <el-select
                                v-model="item.channelId"
                                filterable
                                clearable
                                class="w240"
                                :disabled="!!item.goodsId"
                            >
                                <el-option
                                    v-for="item in channelsCompeting"
                                    :key="item.id"
                                    :label="item.channelName"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                            <verify-channel-popover @handle-refresh="handleRefresh" v-if="!!!item.goodsId"></verify-channel-popover>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            class="custom-item weight500"
                            label-width="100px"
                            label="平台商品ID"
                            :prop="`sellSpuRelationDTOList[${i}].platformSpuNo`"
                            :rules="rules.platformSpuNo"
                        >
                            <el-input
                                v-model="item.platformSpuNo"
                                clearable
                                class="w240"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            class="custom-item weight500"
                            label-width="100px"
                            label="商品简称"
                            :prop="`sellSpuRelationDTOList[${i}].spuAlias`"
                            :rules="rules.spuAlias"
                        >
                            <el-input
                                v-model="item.spuAlias"
                                clearable
                                class="w240"
                                :disabled="!!item.goodsId"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            class="custom-item weight500"
                            label-width="100px"
                            label="平台品类"
                            :prop="`sellSpuRelationDTOList[${i}].categoryId`"
                            :rules="rules.categoryId"
                        >
                            <el-cascader
                                class="w240"
                                ref="cascaderHandle"
                                v-model="item.categoryId"
                                :options="parentCategory"
                                :props="{
                                    checkStrictly: true,
                                    value: 'id',
                                    label: 'categoryName',
                                    emitPath: false
                                }"
                                clearable
                                :filterable="true"
                                @change="handleClose"
                            ></el-cascader>
                            <verify-category-popover @handle-refresh="handleRefresh" v-if="!!!item.goodsId"></verify-category-popover>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            class="custom-item weight500"
                            label-width="100px"
                            label="品牌"
                            :prop="`sellSpuRelationDTOList[${i}].brandId`"
                            :rules="rules.brandId"
                        >
                            <el-select
                                v-model="item.brandId"
                                class="w240"
                                :filterable="true"
                                clearable
                            >
                                <el-option
                                    v-for="item in productBrands"
                                    :key="item.id"
                                    :label="item.brandName"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                            <verify-brand-popover @handle-refresh="handleRefresh" v-if="!!!item.goodsId"></verify-brand-popover>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            class="custom-item weight500"
                            label-width="100px"
                            label="商品链接"
                            :prop="`sellSpuRelationDTOList[${i}].spuUrl`"
                            :rules="rules.spuUrl"
                        >
                            <el-input
                                clearable
                                class="w240"
                                v-model="item.spuUrl"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-divider></el-divider>
            </div>
        </el-form>
</template>
<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import customerSvc from 'services/customer'
import VerifyCategoryPopover from './VerifyCategoryPopover.vue' // 后期拆分为一个
import VerifyChannelPopover from './VerifyChannelPopover.vue'
import VerifyBrandPopover from './VerifyBrandPopover.vue'

export default {
    name: 'CompetingForm',
    data() {
        return {
            form: {
                spuId: '',
                sellSpuRelationDTOList: [
                    {
                        channelId: null,
                        platformSpuNo: null,
                        brandId: null,
                        spuUrl: null,
                        spuAlias: null,
                        categoryId: null
                    }
                ]
            },
            rules: {
                channelId: [
                    {
                        required: true,
                        message: "请选择渠道",
                        trigger: "change"
                    }
                ],
                platformSpuNo: [
                    {
                        required: true,
                        message: "请输入平台商品ID",
                        trigger: "blur"
                    },
                    {
                        min: 1,
                        max: 50,
                        message: "长度在 1 到 50 个字符，且不能为空格",
                        trigger: "blur"
                    }
                ],
                spuAlias: [
                    {
                        required: true,
                        message: "请输入商品简称",
                        trigger: "blur"
                    },
                    {
                        min: 1,
                        max: 50,
                        message: "长度在 1 到 50 个字符，且不能为空格",
                        trigger: "blur"
                    }
                ],
                categoryId: [
                    {
                        required: true,
                        message: "请选择平台品类",
                        trigger: "change"
                    }
                ],
                brandId: [
                    {
                        required: true,
                        message: "请选择品牌",
                        trigger: "change"
                    }
                ],
                spuUrl: [
                    {
                        required: false,
                        message: "请输入商品链接",
                        trigger: "blur"
                    },
                    {
                        min: 1,
                        max: 1000,
                        message: "长度在 1 到 1000 个字符，且不能为空格",
                        trigger: "blur"
                    }
                ],
            }
        }
    },
    components: {
        VerifyCategoryPopover,
        VerifyChannelPopover,
        VerifyBrandPopover
    },
    computed: {
        ...mapState({
            productBrands: state => state.customer.productBrands,
            parentCategory: state => state.customer.parentCategory,
            channelsCompeting: state => state.customer.channelsCompeting,
            spuList: state => state.customer.spuList,
            channelId: state => state.operation.channelId
        })
    },
    // watch: {
    //     channelId: {
    //         immediate: true,
    //         handler(newVal) {
    //             if(newVal) {
    //                 this.form.sellSpuRelationDTOList[0].channelId = newVal
    //             }
    //         }
    //     }
    // },
    methods: {
        handleRefresh(name) {
            this.$emit('handle-refresh', name)
        },
        handleClose(val) {
            const cascader = this.$refs.cascaderHandle
            cascader.dropDownVisible = false
            if (!val) {
                // 清空已选择项
                cascader.$refs.panel.checkedValue = [] //也可以是指定的值
                cascader.$refs.panel.calculateCheckedNodePaths()
            }
        },
        handleCancel() {
            this.resetForm()
            this.$emit('handle-visible', false)
        },
        clearValidate() {
            this.$refs.comForm.clearValidate()
        },
        resetForm() {
            this.$refs.comForm.resetFields()
        },
        handleConfirm() {
            this.$refs.comForm.validate(valid => {
                if (valid) {
                    if(!this.form.spuId) {
                        this.$message.error('未选择商品');
                        return
                    }
                    this.operationCategory()
                } else {
                    console.log("error submit!!")
                    return false
                }
            })
        },
        // async handleRemove(i, row) {
        //     this.form.sellSpuRelationDTOList.splice(i, 1)
        //     this.$nextTick(() => {
        //         this.clearValidate()
        //     })
        // },
        handleOption(event, val) {
            this.form.spuId = val.id
        },
        // handleOption(event, val) {
        //     // 渠道id
        //     const target = _.cloneDeep(val)
        //     const list = this.form.sellSpuRelationDTOList
        //     if (list.find(item => item.spuNo === target.spuNo)) {
        //         this.$message("已经添加过")
        //         return
        //     }
        //     // target.relationName = target.channelName
        //     target.goodsId = target.id
        //     delete target.id // 修改不需要这个,这个其实是这条渠道的id --mark-1
        //     delete target.version // 删除不需要这个 --mark-1
        //     this.form.sellSpuRelationDTOList.push(target)
        // },
        // handleAddOption() {
        //     // 非渠道添加
        //     this.form.sellSpuRelationDTOList.push({
        //         channelId: '',
        //         categoryId: '',
        //         brandId: '',
        //         relationName: '',
        //         platformSpuNo: '',
        //         spuUrl: ''
        //     })
        // },
        async operationCategory() {
            // 新建or编辑 预留
            const reqMethod = this.isAdd ? "save" : "save"
            // const loading = this.$loading()
            try {
                const form = _.cloneDeep(this.form)
                form.sellSpuRelationDTOList = form.sellSpuRelationDTOList.map(item => {
                    if (item.relationId || item.goodsId) {
                        const res = {
                            relationId: item.relationId || item.goodsId,
                        }
                        if(!this.isAdd && item.id) {
                            // 修改须传item.id，这里的id是这条数据的id --mark-1
                            res.id = item.id
                        }
                        return res
                    }
                    const {
                        channelId,
                        categoryId,
                        brandId,
                        spuAlias: relationName,
                        platformSpuNo,
                        spuUrl
                    } = item
                    return {
                        channelId,
                        categoryId,
                        brandId,
                        relationName,
                        platformSpuNo,
                        spuUrl
                    }
                })
                const res = await customerSvc[`${reqMethod}RelationSpu`](form)
                this.$emit('handle-cancel')
            } catch (err) {
                console.log("err", err)
                // loading.close()
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.competing-dialog {
    padding: 17px 0px 24px;
    .w240 {
        width: 240px;
    }
    .weight500 {
        font-weight: 500;
    }
    ::v-deep.el-divider--horizontal {
        margin: 0 0 24px;
    }
    .search {
        .title {
            font-size: 14px;
            font-weight: 400;
            color: #606266;
        }
        .search-input {
            width: 480px;
            margin: 27px 0;
        }
    }
    .list h4 {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        padding: 0 37px;
        span {
            font-size: 14px;
            font-weight: 500;
            color: #c0c4cc;
            line-height: 24px;
        }
    }
    .item-desc {
        font-size: 14px;
        font-weight: 400;
        color: #606266;
        line-height: 24px;
        margin-bottom: 20px;
    }
    ::v-deep .add-tip {
        position: absolute;
        margin-left: 8px;
        font-weight: 400;
        color: #3595E1;
        font-size: 14px;
        cursor: pointer;
        i {
            font-size: 12px;
            margin-right: 8px;
        }
    }
    .add-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        color: #3595e1;
        span {
            cursor: pointer;
        }
        i {
            margin-right: 7px;
        }
    }
}
.custom-select-item {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    height: 50px;
    line-height: 50px;
    & > div,
    & > span {
        line-height: 1;
    }
    & > div {
        font-size: 12px;
        color: #b4b4b4;
        .el-tag {
            &:not(:first-of-type) {
                margin-left: 10px;
            }
            color: #b4b4b4;
        }
    }
}
</style>
