import _ from 'lodash'

export default {
    props: {
        formData: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            form: {}
        }
    },
    watch: {
        formData: {
            immediate: true,
            deep: true,
            handler(newVal) {
                console.log('verify', newVal)
                if(newVal) {
                    console.log(newVal)
                    this.form = _.cloneDeep(newVal)
                }
            }
        }
    },
    methods: {
        handlePrev() {},
        handleCancel() {},
        handleConfirm() {}
    }
}
