<template>
    <div class="gb-channel-query">
        <div
            class="gb-channel-query-group"
            :class="{ 'group-unfold': foldState }"
        >
            <!-- <el-select v-model="value" class="w240 mr40" placeholder="快捷筛选">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select> -->
            <el-input
                v-model.trim="form.channelName"
                class="w240 mr40"
                placeholder="渠道名称"
                clearable
                @clear="handleSearch"
                @keyup.enter.native="handleSearch"
            ></el-input>
            <gb-dictionary
                ref="xssj"
                v-model="form.channelType"
                class="w240 mr40"
                :level="1"
                group="交易渠道下游用户类型"
                :clearable="true"
                :filterable="true"
                placeholder="下游用户类型"
                @change="handleSearch"
            >
            </gb-dictionary>
            <el-input
                v-model="form.platformShopNo"
                class="w240 mr40"
                placeholder="平台店铺ID"
                clearable
                @clear="handleSearch"
                @keyup.enter.native="handleSearch"
            ></el-input>
            <el-cascader
                ref="cascaderHandle"
                v-model="form.groupId"
                class="w240 mr40"
                :options="groupTree"
                :props="{
                    checkStrictly: true,
                    value: 'id',
                    label: 'groupName',
                    emitPath: false,
                }"
                clearable
                :filterable="true"
                placeholder="请选择组织"
                @change="handleClose"
            ></el-cascader>
            <el-select
                v-model="form.partnerInfoId"
                class="w240 mr40"
                placeholder="渠道主体"
                clearable
                remote
                reserve-keyword
                :filterable="true"
                :remote-method="
                    keyword => {
                        querySearchPartner(keyword)
                    }
                "
                :loading="pLoading"
                @clear="handleSearch"
                @change="handleSearch"
            >
                <el-option
                    v-for="item in partnerList"
                    :key="item.id"
                    :label="item.partnerName"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            <el-select
                v-model="form.platformId"
                class="w240 mr40"
                placeholder="平台"
                :filterable="true"
                clearable
                @clear="handleSearch"
                @change="handleSearch"
            >
                <el-option
                    v-for="item in platformCategory"
                    :key="item.id"
                    :label="item.platformName"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            <!-- <div class="textarea-group">
                <el-select
                    class="w160 mr20"
                    placeholder="平台"
                    v-model="form.platformId"
                    :filterable="true"
                    clearable
                    @clear="handleSearch"
                    @change="handleSearch"
                >
                    <el-option
                        v-for="item in platformCategory"
                        :key="item.id"
                        :label="item.platformName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <el-input
                    type="textarea"
                    v-model="form.platformShopNoList"
                    class="w340 mr40"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    placeholder="可输入多个，多个时可以用逗号、空格或换行分开"
                >
                </el-input>
            </div> -->
        </div>
        <div class="gb-channel-query-btns">
            <span
                class="flod"
                :class="{ unfold: foldState }"
                @click="handleFold"
            >{{ foldText }}<i class="iconfont icon-xiala"></i></span>
            <el-button
                type="primary"
                round
                class="custom-btn"
                @click="handleSearch"
            >
                查询
            </el-button>
            <el-button round class="custom-btn" @click="handleReset">重置</el-button>
        </div>
    </div>
</template>
<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import utils from 'utils'
import { getPartnerInfoPage } from 'services/distribution'
import gbDictionary from '@/components/dictionary'

export default {
    name: 'ChannelQuery',
    components: {
        gbDictionary
    },
    data () {
        return {
            foldState: false,
            form: {
                current: 1, // 当前页
                size: 10, // 显示数量
                enabled: null, // 启用状态
                channelName: '', // 渠道名称
                channelType: '', // 渠道类型id
                platformShopNo: '', // 平台店铺id
                groupId: '', // 部门id
                platformId: '', // 平台id
                platformShopNoList: '', // 平台店铺id集合
                partnerInfoId: '', // 渠道主体id
                foeFlag: false
            },
            pLoading: false,
            partnerList: []
        }
    },
    computed: {
        foldText () {
            return this.foldState ? '收起' : '展开'
        },
        ...mapState({
            gropus: state => state.customer.allDeps,
            platformCategory: state => state.customer.platformCategory
        }),
        groupTree () {
            if (_.isEmpty(this.gropus)) {
                return []
            }
            return utils.makeDataToTree(
                this.gropus,
                'parentId',
                'id',
                'groupName'
            )
        }
    },
    methods: {
        handleFold () {
            this.foldState = !this.foldState
        },
        handleReset () {
            // todo:查询条件置空
            Object.keys(this.form).forEach(item => {
                this.form[item] = null
            })
            this.form.current = 1
            this.form.size = 10
            this.form.foeFlag = false
            this.partnerList = []
            this.$emit('handle-search', this.form)
        },
        handleSearch () {
            // 请求接口
            const form = _.cloneDeep(this.form)
            // todo: 提取公共函数 textarea 空格换行逗号变数组
            form.platformShopNoList = form.platformShopNoList ? form.platformShopNoList.split(/[(\b\r\n,，)\b\r\n,，]+/g) : []
            this.$emit('handle-search', form)
        },
        handleClose (val) {
            this.$emit('change', val)
            const cascader = this.$refs['cascaderHandle']
            cascader.dropDownVisible = false
            if (!val) {
                // 清空已选择项
                cascader.$refs.panel.checkedValue = [] // 也可以是指定的值
                cascader.$refs.panel.calculateCheckedNodePaths()
            }
        },
        async querySearchPartner (queryString) {
            // 快速增加sku
            if (!queryString) {
                return
            }
            this.pLoading = true
            // 远程搜索
            const query = {
                partnerNameLike: queryString, // 渠道名称
                current: 1,
                size: 30
            }
            const { data = [], total = 0 } = await getPartnerInfoPage(
                query
            )
            this.pLoading = false
            this.partnerList = data
        }
    }
}
</script>
<style lang="less" scoped>
.gb-channel-query {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .w240 {
        width: 240px;
    }

    .w160 {
        width: 160px;
    }

    .w340 {
        width: 340px;
    }

    .mr40 {
        margin-right: 40px;
    }

    .mr20 {
        margin-right: 20px;
    }

    &-group {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        max-height: 52px;
        overflow: hidden;
        transition: all 0.3s;

        & > div {
            margin-bottom: 12px;
        }

        /deep/.el-checkbox {
            padding-top: 10px;
        }

        .textarea-group {
            display: flex;
        }
    }

    .group-unfold {
        max-height: 100%;
    }

    &-btns {
        min-width: 194px;

        .flod {
            display: inline-flex;
            margin-right: 8px;
            font-size: 14px;
            font-weight: 400;
            color: #3595e1;
            cursor: pointer;
        }

        .flod i {
            display: flex;
            align-items: center;
            transition: all 0.3s;
            transform: scale(0.6);
        }

        .unfold i {
            transform: scale(0.6) rotate(180deg);
        }

        .custom-btn {
            padding: 12px 18px;
            font-size: 14px;
            border: 1px solid #dcdfe6;
            border-radius: 8px;

            & + btn {
                background: #3595e1;
            }
        }
    }
}
</style>
