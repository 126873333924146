const vueFiles = require.context('../components', false, /\w+[A-Z]\w+\.vue/)
const target = {}
vueFiles.keys().forEach(item => {
    const fileName = item.split('/').pop().replace(/\.\w+$/, '')
    // 后期优化
    const config = vueFiles(item)
    target[fileName] = config.default || config
})

export default target
